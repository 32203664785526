import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadSingleVendorManagement } from '../../redux/Actions';
import { useParams } from 'react-router';


const ViewVendor = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    let { id } = useParams()
    const singleVendor = useSelector(state => state.vendorUsers.singleVendor.vendor)
    const [vendor_id, setVendor_id] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [account_type, setAccount_type] = useState("")
    const [gst_number, setGst_number] = useState("")
    const [contactperson, setContactperson] = useState("")
    const [address, setAddress] = useState("")
    const [status, setStatus] = useState("")
    const [numberError, setNumberError] = useState(false)


    useEffect(() => {
        dispatch(loadSingleVendorManagement(id))
    }, [])




    useEffect(() => {
        if (singleVendor) {
            const singleVendorId = singleVendor?.vendor_id
            const singleName = singleVendor?.name
            const singleEmail = singleVendor?.email
            const singlePhone = singleVendor?.phone
            const singleAccount = singleVendor?.account_type
            const singleContactPerson = singleVendor?.contactperson
            const singleAddress = singleVendor?.address
            const singleState = singleVendor?.state
            const singleCity = singleVendor?.city
            const singleGst = singleVendor?.gst_number
            const singleStatus = singleVendor?.status
            setVendor_id(singleVendorId)
            setName(singleName)
            setEmail(singleEmail)
            setPhone(singlePhone)
            setAccount_type(singleAccount)
            setContactperson(singleContactPerson)
            setAddress(singleAddress)
            setState(singleState)
            setCity(singleCity)
            setGst_number(singleGst)
            setStatus(singleStatus)
        }
    }, [singleVendor])
    return (
        <div className='addDc'>

            <form className="add-input">

                <div className='row'>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Name"  variant="standard" name="name" value={name} type="text" className='dc-textfield-width' />

                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Email"  variant="standard" name="email" value={email} type="text" className='dc-textfield-width' />

                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Mobile Number"  variant="standard" name="phone" error={numberError} value={phone} type="number"
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }} className='dc-textfield-width' />
                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Vendor number"  variant="standard" name="vendor_id" value={vendor_id} type="text" className='dc-textfield-width' />

                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Account Type"  variant="standard" name="account_type" value={account_type} type="text" className='dc-textfield-width' />

                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="GST Number"  variant="standard" name="gst_number" value={gst_number} type="text" className='dc-textfield-width' />

                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Contact Person"  variant="standard" name="contactperson" value={contactperson} type="text" className='dc-textfield-width' />

                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Enter your address"  variant="standard" name="address" value={address} type="text" className='dc-textfield-width' />

                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="City"  variant="standard" name="city" value={city} type="text" className='dc-textfield-width' />

                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="State"  variant="standard" name="state" value={state} type="text" className='dc-textfield-width' />

                    </div>
                    <div  className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Status"  variant="standard" name="state" value={status} type="text" className='dc-textfield-width' />

                    </div>


                </div>

                <div className='dc-flex'>
                    <Button variant="contained" color="primary" type="submit" onClick={() => history.push("/vendor")} className='button-dc-submit'>BACK</Button>
                </div>
            </form>


        </div>
    )
}

export default ViewVendor