import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addVendor, loadAllCities, loadAllStates } from '../../redux/Actions';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import './Vendor.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { blockInvalidChar } from '../../blockInvalidChar';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 190
        }
    }
};

const AddVendor = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const msg = useSelector(state => state.vendorUsers.msg)
    const loading = useSelector(state => state.vendorUsers.loading)
    const allStates = useSelector(state => state.dcUsers.allStates.states)
    const allCities = useSelector(state => state.dcUsers.allCities.cities)
    const [vendor_id, setVendor_id] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [account_type, setAccount_type] = useState("")
    const [gst_number, setGst_number] = useState("")
    const [contactperson, setContactperson] = useState("")
    const [address, setAddress] = useState("")
    const [buttonLoading, setButtonLoading] = useState(false)
    const [numberError, setNumberError] = useState(false)
    const [gstError, setGstError] = useState(false)
    const [emailError, setEmailError] = useState(false)

    const [status, setStatus] = useState("")


    const handleVendorNumber = (e) => {
        setVendor_id(e.target.value)

    }
    const handleState = (e) => {
        setState(e.target.value)

    }

    const handleCity = (e) => {
        setCity(e.target.value)
    }
    const handleVendorName = (e) => {
        setName(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    }
    const handleEmail = (e) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        setEmail(e.target.value)
        if (regex.test(e.target.value) === false) {
            setEmailError(true)
        }
        else {
            setEmailError(false)
        }
    }
    const handleAccountType = (e) => {
        setAccount_type(e.target.value)
    }
    const handleContactNumber = (e) => {
        setPhone(e.target.value)
        if (e.target.value.length !== 10) {
            setNumberError(true)
        }
        else {
            setNumberError(false)
        }
    }
    const handleStatus = (e) => {
        setStatus(e.target.value)
    }
    const handleGst = (e) => {
        setGst_number(e.target.value.replace(/[^\w\s]/gi, ""))
        if (e.target.value.length !== 16) {
            setGstError(true)
        }
        // else if(e.target.value.match(/[%<>\\$'"]/)) {
        //     setGstError(true)
        // }
        else {
            setGstError(false)
        }
    }

    const handleContactperson = (e) => {
        setContactperson(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    }
    const handleAddress = (e) => {
        setAddress(e.target.value)
    }


    useEffect(() => {
        dispatch(loadAllStates())
    }, [])
    useEffect(() => {
        if(state){
            dispatch(loadAllCities(state))
        }
    }, [state])


    const finaldata = {
        vendor_id,
        name,
        email,
        phone,
        city,
        state,
        address,
        account_type,
        gst_number,
        contactperson,
        status
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        toast.configure();
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!vendor_id || !name || !email || !phone || !city || !state || !account_type || !gst_number || !contactperson) {
            toast.error("Please enter all input fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        } else if (phone.length != 10) {
            toast.warning('Please add a valid contact number', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        } else if (!email || regex.test(email) === false) {
            toast.warning('Please add a valid Email address', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else if (gst_number.length != 16) {
            toast.warning('Please add a valid GST number', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else {
            dispatch(addVendor(finaldata))
            setTimeout(() => {
                setButtonLoading(true)
            }, 1000)
        }

    }

    useEffect(() => {
        if (!loading && buttonLoading && msg.success) {
            history.push("/vendor")
        }
    }, [loading, buttonLoading])



    return (
        <div className='addDc'>

            <form className="add-input" onSubmit={handleSubmit}>

                <div className='row'>
                    <div className='col-md-4 dc-margin'>
                        <TextField id="standard-basic" label="Name" variant="standard" name="name" value={name} type="text" onChange={handleVendorName} className='dc-textfield-width' />

                    </div>
                    <div className='col-md-4 dc-margin'>
                        <TextField id="standard-basic" label="Email" variant="standard" name="email" value={email} error={emailError} type="email" onChange={handleEmail} className='dc-textfield-width' />

                    </div>

                    <div className='col-md-4 dc-margin'>
                        <TextField id="standard-basic" label="Mobile Number" variant="standard" error={numberError} name="phone" value={phone} type="number" onKeyDown={blockInvalidChar} onChange={handleContactNumber}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }} className='dc-textfield-width' />
                    </div>
                    <div className='col-md-4 dc-margin'>
                        <TextField id="standard-basic" label="Vendor number" variant="standard" name="vendor_id" value={vendor_id} type="text" onChange={handleVendorNumber} className='dc-textfield-width' />

                    </div>
                    <div className='col-md-4 dc-margin vendor-margin-top'>
                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-standard-label" className='dc-textfield-width'>Select Account Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                // id="demo-simple-select-standard"
                                value={account_type}
                                onChange={handleAccountType}
                                label="account type"
                                className='dc-textfield-width'
                            >
                                <MenuItem value="Permanent">Permanent</MenuItem>
                                <MenuItem value="Temporary">Temporary</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className='col-md-4 dc-margin'>
                        <TextField id="standard-basic" label="Enter GST number" variant="standard"  type="text"  name="licensedate" error={gstError} value={gst_number} onChange={handleGst} inputProps={{ maxLength: 16 }}
                            // onInput={(e) => {
                            //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 16)
                            // }}
                            className='dc-textfield-width' />
                    </div>
                    <div className='col-md-4 dc-margin'>
                        <TextField id="standard-basic" label="Contact Person" variant="standard" name="contactperson" value={contactperson} type="text" onChange={handleContactperson} className='dc-textfield-width' />

                    </div>
                    <div className='col-md-4 dc-margin'>
                        <TextField id="standard-basic" label="Enter your address" variant="standard" name="address" value={address} type="text" onChange={handleAddress} className='dc-textfield-width' />

                    </div>
                    <div className='col-md-4 dc-margin vendor-margin-top'>

                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-standard-label" className='dc-textfield-width'>Select State</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                MenuProps={MenuProps}

                                value={state}
                                onChange={handleState}
                                label="state"
                                className='dc-textfield-width'
                            >
                                {allStates && allStates.length ? allStates.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>


                                )) : ""}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-4 dc-margin vendor-margin-top'>
                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-standard-label" className='dc-textfield-width'>Select City</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                MenuProps={MenuProps}
                                value={city}
                                onChange={handleCity}
                                label="city"
                                className='dc-textfield-width'
                            >
                                {allCities && allCities.length ? allCities.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                )) : ""}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-4 dc-margin vendor-margin-top'>

                        <FormControl variant="standard">
                            <InputLabel id="demo-simple-select-standard-label" className='dc-textfield-width'>Select Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={status}
                                name="status"
                                onChange={handleStatus}
                                label="status"
                                className='dc-textfield-width'
                            >

                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="In Active">In Active </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className='dc-flex'>
                    <Button variant="contained" color="primary" type="submit" className='button-dc-submit'>Submit</Button>
                </div>
            </form>
            <ToastContainer />

        </div>
    )
}

export default AddVendor
