import { Input, Button } from "antd";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import "./login.css";
import loginImage from "../../images/vahanLogin.png"
import logo from "../../.././src/images/Group.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadUnmountSingleDc } from '../../redux/Actions';
import { loadUnmountDcDate } from '../../redux/Actions';
import { Modal } from 'antd';
import { addRecoveryEmail } from "../../redux/Actions";
import { loadProfileDetails } from '../../redux/Actions'
import { getCookie, setCookie } from "../utils/Cookie";



const Login = () => {
    toast.configure();
    let dispatch = useDispatch()
    const history = useHistory()

    const recoveryMail = useSelector(state => state.data.recoveryMail)

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isforgetPassword, setIsForgetPassword] = useState(false)
    const [forgotEmail, setForgotEmail] = useState('')
    async function login() {
        let item = { email, password }
        let result = await fetch(`${process.env.REACT_APP_BASEURL}/api/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(item)
        })
        result = await result.json()
        console.log("result", result)
        // localStorage.setItem("user-info", JSON.stringify(result))
        let userDetails = { token: result?.user?.token, role: result?.user?.role, status: result?.user?.status, vendor_dc_id: result?.user?.vendor_dc_id, name: result?.user?.name }
        setCookie("user-info", JSON.stringify(userDetails))
        let user = JSON.parse(getCookie("user-info"))

        console.log("gsdfgsdfgsdf", user)

        if (result.success) {
            if (result.user.role === "vendor") {
                toast.success(`Welcome ${result?.user?.name}!`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                dispatch(loadProfileDetails())
                setTimeout(() => {
                    history.push("/orders")
                }, 1000)
            } else if (result.user.role === "dc") {
                toast.success(`welcome ${result?.user?.name}!`);
                dispatch(loadProfileDetails())
                setTimeout(() => {
                    history.push("/dashboard")
                }, 1000)
            }
            else {
                console.log("inside login dashboard", result)
                toast.success(`Login Successfull!`);
                dispatch(loadProfileDetails())
                setTimeout(() => {
                    history.push("/dashboard")
                }, 1000)
            }
        }
        else {
            toast.error(result?.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }

        // dispatch(loadUnmountSingleDc())
        // dispatch(loadUnmountDcDate())
    }

    const forgetPassword = () => {
        setIsForgetPassword(true)
    }


    const handleCancel = () => {
        setIsForgetPassword(false);
    };


    const forgotEmailHandler = (e) => {
        setForgotEmail(e.target.value)
    }

    const sendResetPassword = () => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!forgotEmail) {
            toast.warning('Please enter email address', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
            setIsForgetPassword(true)
        }
        else if (regex.test(forgotEmail) === false) {
            toast.warning('Please Add A Valid Email Address', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
            setIsForgetPassword(true)
        }
        else {
            dispatch(addRecoveryEmail({email: forgotEmail}))
            setIsForgetPassword(false)
            setForgotEmail('')
        }
    }

    return (
        <div className="login">
            <div className="loginImage">
                <img className="vahan-image" src={loginImage} alt="" />
            </div>
            <div className="logindetails">
                <div className="login-right">
                    <div className="brand-logo"><img src={logo} alt="apolloBrand" /></div>
                    <div className="loginName">
                        <h1>V<span>A</span>H<span>A</span>N</h1>
                        <p className="sign">Enter your email and password to login</p>
                        <h3>Login</h3>
                    </div>
                    <div>
                        <p className='email'>Email</p>
                        <Input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <p className="email">Password</p>
                        <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} /><br />
                        <p className="forget-password" onClick={forgetPassword}>Forget Password?</p>
                        <Button onClick={login} className="login-button" variant="contained">LOGIN</Button>
                    </div>
                </div>
            </div>
            <ToastContainer />
            {
                isforgetPassword &&
                <div className='modal'>
                    <Modal
                        visible={isforgetPassword}
                        onCancel={handleCancel}
                        width={700}
                        footer={[
                        ]}
                    >
                        <div className='enter-email-address-div'>
                            <h5>Enter Valid Email</h5>
                            <div className="input-btn-div">
                                <Input placeholder="Enter Email Address" type="email" className="valid-email-input" onChange={forgotEmailHandler} />
                                <Button onClick={sendResetPassword}>Send</Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            }
        </div>

    )
}
export default Login;