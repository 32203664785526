import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './AddDc.css'
import Button from '@mui/material/Button';

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadSingleDc } from '../../redux/Actions';
import { useParams } from 'react-router-dom'
import Text from 'antd/lib/typography/Text';
import mapIcon from '../../images/address.png'




const ViewDc = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    let { id } = useParams()

    const singleDc = useSelector(state => state.data.singleDc.DC)

    const [dc_number, setDc_number] = useState("")
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [dc_name, setDc_name] = useState("")
    const [total_berths, setTotal_berths] = useState("")
    const [dc_contact, setDc_contact] = useState("")
    const [contact_number, setContact_number] = useState("")
    const [working_hours_from, setWorking_hours_from] = useState("")
    const [working_hours_to, setWorking_hours_to] = useState("")
    const [status, setStatus] = useState("")
    const [pincode, setPincode] = useState("")
    const [numberError, setNumberError] = useState(false)
    const [email, setEmail] = useState("")
    const [address,setAddress] = useState("")
    const [map_link,setMapLink] = useState("")

    useEffect(() => {
        dispatch(loadSingleDc(id))
    }, [])


    useEffect(() => {
        if (singleDc) {
            const singleDcName = singleDc?.dc_name
            const singleDcNumber = singleDc?.dc_number
            const singleDcContactName = singleDc?.dc_contact
            const singlePhone = singleDc?.contact_number
            const singleBerths = singleDc?.total_berths
            const singleWorkingFrom = singleDc?.working_hours_from
            const singleWorkingTo = singleDc?.working_hours_to
            const singleState = singleDc?.state
            const singleCity = singleDc?.city
            const singleStatus = singleDc?.status
            const singlePincode = singleDc?.pincode
            const singleEmail = singleDc?.email
            const singleAddress = singleDc?.address
            const singlemap_link = singleDc?.map_link
            setDc_name(singleDcName)
            setDc_number(singleDcNumber)
            setDc_contact(singleDcContactName)
            setContact_number(singlePhone)
            setTotal_berths(singleBerths)
            setWorking_hours_to(singleWorkingTo)
            setWorking_hours_from(singleWorkingFrom)
            setState(singleState)
            setCity(singleCity)
            setStatus(singleStatus)
            setPincode(singlePincode)
            setEmail(singleEmail)
            setAddress(singleAddress)
            setMapLink(singlemap_link)
        }
    }, [singleDc])

    const getTime = (str) => {
        let a = str?.split(":")
        let r = ""
        const c = (k) => parseInt(k) < 10 ? "0" + k : k
        if (parseInt(a[0]) == 24 || parseInt(a[0]) == 0) {
            r = "12:" + a[1] + " AM"
        } else if (parseInt(a[0]) == 12) {
            r = "12:" + a[1] + " PM"
        } else if (parseInt(a[0]) > 12) {
            let b = parseInt(a[0]) - 12
            r = c(parseInt(a[0]) - 12) + ":" + a[1] + " PM"
        } else {
            r = a[0] + ":" + a[1] + " AM"
        }
        return r
    }

    return (
        <div>
            <div className='addDc'>
                <form className="add-input">
                    <div className='row'>
                        <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="DC name" variant="standard" name="dc_name" value={dc_name} type="text" className='dc-textfield-width' />
                        </div>
                        <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="DC number" variant="standard" name="dc_number" value={dc_number} type="text" className='dc-textfield-width' />
                        </div>
                        <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="Email" variant="standard" name="email" value={email} type="text" className='dc-textfield-width' />
                        </div>
                        <div className="col-md-4 dc-margin dc-address-map-icon-div">
                            <TextField id="standard-basic" label="DC address" variant="standard" name="address" value={address} type="text" className='dc-textfield-width' InputLabelProps={{ shrink: true }}
                                InputLabelProps={{ shrink: true }} />
                            <a href={map_link} target="_blank"><img src={mapIcon} alt="address" /></a>
                        </div>
                        <div className="col-md-4 dc-margin dc-address-map-icon-div">
                            <TextField id="standard-basic" label="DC map link" variant="standard" name="map_link" value={map_link} type="text" className='dc-textfield-width' InputLabelProps={{ shrink: true }}
                                InputLabelProps={{ shrink: true }} />
                        </div>
                        <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="DC contact Name" variant="standard" name="dc_contact" value={dc_contact} type="text" className='dc-textfield-width' />
                        </div>
                        <div className='col-md-4 dc-margin' >
                            <TextField id="standard-basic" label="DC contact number" variant="standard" error={numberError} name="contact_number" value={contact_number} type="text"
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                }} className='dc-textfield-width' />
                        </div>
                        <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="Total berths" variant="standard" name="total_berths" value={total_berths} type="number" className='dc-textfield-width' />

                        </div>
                        <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="Working hours from" variant="standard" name="working_hours_from" value={getTime(working_hours_from)} type="text" className='dc-textfield-width' />

                        </div>
                        <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="Working hours to" variant="standard" name="working_hours_to" value={getTime(working_hours_to)} type="text" className='dc-textfield-width' />

                        </div>
                        {/* <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="State" variant="standard" name="state" value={state} type="text" className='dc-textfield-width' />

                        </div>
                        <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="City" variant="standard" name="city" value={city} type="text" className='dc-textfield-width' />
                        </div>
                        <div className="col-md-4 dc-margin">
                            <TextField id="standard-basic" label="Pincode" variant="standard" name="pincode" value={pincode} type="text" className='dc-textfield-width' />

                        </div> */}
                        <div className='col-md-4 dc-margin'>
                            <TextField id="standard-basic" label="Status" variant="standard" name="status" value={status} type="text" className='dc-textfield-width' />
                        </div>
                    </div>
                    <div className='dc-flex'>
                        <Button className="button-dc-submit" variant="contained" color="primary" type="submit" onClick={() => history.push("/dc")}>BACK</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ViewDc