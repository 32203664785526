import Cookies from 'js-cookie'


export const setCookie = (name,value) => {
    console.log("namevalue",name,value)
    Cookies.set(name,value)
}

export const getCookie = (name) => {
    console.log("namesdsd",name)
   return Cookies.get(name)
}