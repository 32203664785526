import React, { useState, useEffect } from 'react'
import './DcManagement.css'
import Dropdown from 'react-bootstrap/Dropdown'
import { Input } from 'antd';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import search from '../../../src/images/search 1.png'
import downloadIcon from '../../images/Download.png'
import newSlot from '../../images/newSlot.png'
import TableContainer from "@mui/material/TableContainer";
import { useDispatch, useSelector } from 'react-redux'
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from '@mui/material/TableHead';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { loadWorkingDc, loadWorkingDcPdf, loadWorkingDcExcel } from '../../redux/Actions';
import { useHistory } from 'react-router';
import filter from '../../images/filter.png'
import viewIcon from '../../images/IconLeft.png'
import editIcon from '../../images/ci_edit.png'
import moment from 'moment';
import { getCookie } from '../utils/Cookie';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 190
        }
    }
};

const WorkingDc = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    let user = JSON.parse(getCookie("user-info"))


    const dcm = useSelector(state => state.dcUsers.dc.data)    
    const allStates = useSelector(state => state.dcUsers.allStates.states)
    const allCities = useSelector(state => state.dcUsers.allCities.cities)

    const [searchTerm, setSearchTerm] = useState("")
    const [searchResults, setSearchResults] = useState({})
    const [status, setStatus] = useState("")
    const [cityFilter, setCityFilter] = useState("")
    const [stateFilter, setStateFilter] = useState("")

    useEffect(() => {
        dispatch(loadWorkingDc())
    }, [])

    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };




        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dcm.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const currentPosts = dcm && dcm.length ? dcm.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : ""




    const downloadPdf = () => {
        dispatch(loadWorkingDcPdf())

    }
    const downloadExcel = () => {
        dispatch(loadWorkingDcExcel())

    }


    function add3Dots(string, limit) {
        var dots = "...";
        var limit = 10
        if (string ? string.length > limit : "") {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }
    return (
        <div>
            {/* <div className="filter-section">
                <div className="filter-top">
                    <h6><span><img src={filter} alt="filter" /></span>Filter By :-</h6>
                    {stateFilter || cityFilter || status ? <p onClick={removeFilter}>Clear</p> : <p></p>}
                </div>
                <hr className="horizontal" />
                <div className=" filter-dropdown">
                    <div className="state-dropdown">
                        <div className="each-dropdown-first">
                            <FormControl size="small" sx={{ mt: 1, minWidth: 250 }}>
                                <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                                <Select
                                    className="drop-font"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={stateFilter}
                                    label="State"
                                    MenuProps={MenuProps}
                                    onChange={handleStateFilter}
                                >

                                    {allStates && allStates ? allStates.map((item) => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>


                                    )) : ""}
                                </Select>

                            </FormControl>
                        </div>

                        {stateFilter && allCities && allCities.length ? <div className="each-dropdown">
                            <FormControl size="small" sx={{ mt: 1, minWidth: 250 }}>
                                <InputLabel id="demo-simple-select-helper-label">City</InputLabel>
                                <Select
                                    className="drop-font"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={cityFilter}
                                    MenuProps={MenuProps}
                                    label="City"
                                    onChange={handleCityFilter}
                                >
                                    {allCities.map((item, index) => (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>


                                    ))}
                                </Select>

                            </FormControl>
                        </div> : ""}


                        <div className="each-dropdown">
                            <FormControl size="small" sx={{ mt: 1, minWidth: 250 }}>
                                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                                <Select
                                    className="drop-font"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={status}
                                    MenuProps={MenuProps}
                                    label="Status"
                                    onChange={handleFilterChange}
                                >

                                    <MenuItem value={"Active"}>Active</MenuItem>
                                    <MenuItem value={"In Active"}>In Active</MenuItem>
                                </Select>

                            </FormControl>
                        </div>
                    </div>
                </div>
                <div>
                    <Input className="search" placeholder="Type State, City and Name..." value={searchTerm} onChange={(e) => searchItems(e.target.value)} />
                    <img src={search} className="icon-search" />

                </div>

            </div> */}
            <div className="main-section-dc-working">
                <div className="middle-section-working">

                    <div className="dc-download">
                        <Dropdown>
                            <Dropdown.Toggle className="download-drop">
                                <span><img src={downloadIcon} alt="download" /></span> Download
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={downloadPdf} >Download PDF</Dropdown.Item>
                                <Dropdown.Item onClick={downloadExcel} >Download Excel</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>
                </div>

                <div className="table-container">
                    <Paper sx={{
                        width: '100%', overflow: 'hidden', ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {

                            mt: 1.8,
                            mr: -1

                        },

                        ".MuiTablePagination-displayedRows": {
                            mt: 2
                        },
                    }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="custom pagination table" >
                                <TableHead >
                                    <TableRow>
                                        <StyledTableCell className="table-head">Dc Id</StyledTableCell>
                                        {/* <StyledTableCell className="table-head" align="centre">State</StyledTableCell>
                                        <StyledTableCell className="table-head" align="centre">City</StyledTableCell> */}
                                        <StyledTableCell className="table-head" align="centre">Dc Name</StyledTableCell>
                                        <StyledTableCell className="table-head" align="centre">Dc Contact</StyledTableCell>
                                        <StyledTableCell className="table-head" align="centre">Mobile Number</StyledTableCell>
                                        <StyledTableCell className="table-head" align="centre">Working Hours</StyledTableCell>
                                        <StyledTableCell className="table-head" align="centre">Last Updated</StyledTableCell>
                                        <StyledTableCell className="table-head" align="centre">Status</StyledTableCell>
                                        <StyledTableCell className="table-head" align="centre"></StyledTableCell>


                                    </TableRow>
                                </TableHead>
                                <TableBody>


                                    {searchTerm && searchTerm.length ? searchResults.map((item) => (
                                        <TableRow key={item?.id}>
                                            <TableCell align="centre" component="th" scope="row">
                                                {add3Dots(item?.dc_number)}
                                            </TableCell>
                                            {/* <TableCell align="centre"  >
                                                {add3Dots(item?.state)}
                                            </TableCell>
                                            <TableCell align="centre" >
                                                {add3Dots(item?.city)}
                                            </TableCell> */}
                                            <TableCell align="centre" >
                                                {add3Dots(item?.dc_name)}
                                            </TableCell>
                                            <TableCell align="centre" >
                                                {add3Dots(item?.dc_contact)}
                                            </TableCell>
                                            <TableCell align="centre" >
                                                {add3Dots(item?.contact_number)}
                                            </TableCell>
                                            <TableCell align="centre" >
                                                {add3Dots(item?.working_hours_from)}-{add3Dots(item?.working_hours_to)}
                                            </TableCell>
                                            <TableCell align="centre" >
                                                {moment(item?.modifiedAt).format('YYYY-MM-DD')}
                                            </TableCell>
                                            {item?.status === "Active" ? <TableCell align="centre" style={{ color: "#4CAF50" }} >
                                                {add3Dots(item?.status)}
                                            </TableCell> : <TableCell align="centre" style={{ color: "#FF3D00" }} >
                                                {add3Dots(item?.status)}
                                            </TableCell>}

                                            <TableCell align="centre" >
                                                <div style={{ cursor: "pointer" }}>
                                                    <img src={editIcon} style={{ width: "24px", height: "20px", marginRight: "7px" }} onClick={() => history.push(`/editDc/${item?.dc_number}`)} />

                                                    <img src={viewIcon} onClick={() => history.push(`/viewDc/${item?.dc_number}`)} />
                                                </div>
                                            </TableCell>
                                        </TableRow>)) : (currentPosts && currentPosts.length ? currentPosts.map((mang) => (
                                            <TableRow key={mang?.id}>
                                                <TableCell align="centre" component="th" scope="row">
                                                    {add3Dots(mang?.dc_number)}
                                                </TableCell>
                                                {/* <TableCell align="centre" >
                                                    {add3Dots(mang?.state)}
                                                </TableCell>
                                                <TableCell align="centre" >
                                                    {add3Dots(mang?.city)}
                                                </TableCell> */}
                                                <TableCell align="centre" >
                                                    {add3Dots(mang?.dc_name)}
                                                </TableCell>
                                                <TableCell align="centre" >
                                                    {add3Dots(mang?.dc_contact)}
                                                </TableCell>
                                                <TableCell align="centre" >
                                                    {add3Dots(mang?.contact_number)}
                                                </TableCell>
                                                <TableCell align="centre" >
                                                    {add3Dots(mang?.working_hours_from)}-{add3Dots(mang?.working_hours_to)}
                                                </TableCell>
                                                <TableCell align="centre" >
                                                    {moment(mang?.modifiedAt).format('YYYY-MM-DD')}
                                                </TableCell>
                                                {mang?.status === "Active" ? <TableCell align="centre" style={{ color: "#4CAF50" }} >
                                                    {add3Dots(mang?.status)}
                                                </TableCell> : <TableCell align="centre" style={{ color: "#FF3D00" }} >
                                                    {add3Dots(mang?.status)}
                                                </TableCell>}

                                                <TableCell align="centre">
                                                    <div style={{ cursor: "pointer" }}>
                                                        <img src={editIcon} style={{ width: "24px", height: "20px", marginRight: "7px" }} onClick={() => history.push(`/editDc/${mang?.dc_number}`)} />

                                                        <img src={viewIcon} onClick={() => history.push(`/viewDc/${mang?.dc_number}`)} />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )) : "")}

                                    {emptyRows >0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>

                        </TableContainer>
                        <TablePagination className="pagination"
                            rowsPerPageOptions={[5, 10, 25, { label: "All", value: dcm ? dcm.length : '' }]}
                            colSpan={3}
                            count={dcm ? dcm.length : null}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            // SelectProps={{
                            //     inputProps: {
                            //         "aria-label": "rows per page"
                            //     },
                            //     native: true
                            // }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Paper>
                </div>
            </div>
        </div>
    )
}

export default WorkingDc
