import { combineReducers } from "redux";
import {getDcReducer, getOrderReducer, getVendorReducer} from './Reducer'

const rootReducer=combineReducers(
    {
        dcUsers:getDcReducer,
        vendorUsers:getVendorReducer,
        data:getOrderReducer
      
    }
)
export default rootReducer