import React, { useState, useEffect } from 'react'
import './OrderListing.css'
import Dropdown from 'react-bootstrap/Dropdown'
import { Input } from 'antd';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DatePicker } from 'antd';
import TableContainer from "@mui/material/TableContainer";
import search from '../../images/search 1.png'
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from '@mui/material/TableHead';
import { useDispatch, useSelector } from 'react-redux'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import sort from '../../images/sort.png'
import downloadIcon from '../../images/Download.png'
import viewIcon from '../../images/IconLeft.png'
import calendarIcon from '../../images/schedule 1.png'
import { Checkbox } from 'antd';
import { loadOrderListingDownloadSelectedOrdersPdf, loadOrderListingDownloadSelectedOrdersExcel } from '../../redux/Actions';
import { getOrderNumbersForPdf } from '../../redux/Actions';
import { getCookie } from '../utils/Cookie';
import { resetOrderNumbersForPdf } from '../../redux/Actions';


import filter from '../../images/filter.png'
import { loadFilterStatusAndVendorAndStartDateAndEndDateOrder, loadFilterStatusAndWheelAndStartDateAndEndDateOrder, loadFilterVendorAndWheelAndStartDateAndEndDateOrder, loadFilterStatusAndDcAndStartDateAndEndDateOrder, loadFilterStatusOrder, loadFilterStatusAndStartDateAndEndDateOrder, loadFilterVendorAndStartDateAndEndDateOrder, loadFilterWheelAndStartDateAndEndDateOrder, loadFilterDcAndStartDateAndEndDateOrder, loadOrderListingPdfFilterStatusAndStartandEndDate, loadOrderListingPdfFilterVendorAndStartandEndDate, loadOrderListingPdfFilterWheelAndStartandEndDate, loadOrderListingPdfFilterDcAndStartandEndDate, loadFilterWheelerOrder, loadOrderListing, loadOrderListingExcel, loadOrderListingPdf, loadVehicles, loadVendorManagement, loadFilterVendorOrder, loadFilterDcOrder, loadDcManagement, loadFilterStartDateAndEndDateOrder, loadOrderListingPdfFilterWheel, loadOrderListingPdfFilterDc, loadOrderListingPdfFilterVendor, loadOrderListingPdfFilterStatus, loadOrderListingPdfFilterStartDate, loadOrderListingExcelFilterStatus, loadOrderListingExcelFilterWheel, loadOrderListingExcelFilterVendor, loadOrderListingExcelFilterDc, loadOrderListingExcelFilterStartDate, loadFilterStatusAndWheelerOrder, loadFilterStatusAndVendorOrder, loadFilterStatusAndDcOrder, loadFilterVendorAndWheelOrder, loadOrderListingPdfFilterStatusAndWheel, loadOrderListingPdfFilterStatusAndVendor, loadOrderListingPdfFilterWheelAndDc } from '../../redux/Actions';
import { loadFilterStatusAndVendorAndWheelAndDcAndStartDateAndEndDateOrder, loadFilterStatusAndVendorAndWheelAndStartDateAndEndDateOrder, loadFilterVendorAndWheelAndDcAndStartDateAndEndDateOrder, loadFilterStatusAndVendorAndDcAndStartDateAndEndDateOrder, loadFilterStatusAndWheelAndDcAndStartDateAndEndDateOrder, loadFilterVendorAndDcAndStartDateAndEndDateOrder, loadFilterWheelAndDcAndStartDateAndEndDateOrder, loadFilterVendorAndDcOrder, loadOrderListingExcelFilterStatusAndDc, loadOrderListingExcelFilterVendorAndWheel, loadOrderListingExcelFilterWheelAndDc, loadFilterDcAndWheelOrder, loadFilterStatusAndVendorAndWheelOrder, loadFilterStatusAndDcAndVendorOrder, loadFilterVendorAndDcAndWheelerOrder, loadFilterStatusAndVendorAndDcAndWheelerOrder, loadOrderListingPdfFilterStatusAndVendorAndWheelAndDc, loadOrderListingPdfFilterStatusAndVendorAndWheel, loadOrderListingPdfFilterStatusAndVendorAndDc, loadOrderListingPdfFilterVendorAndWheelAndDc, loadOrderListingPdfFilterStatusAndDc, loadOrderListingPdfFilterVendorAndWheel, loadOrderListingPdfFilterVendorAndDc, loadFilterStatusAndDcAndWheelerOrder, loadOrderListingPdfFilterStatusAndWheelAndDc, loadOrderListingExcelFilterStatusAndVendorAndWheelAndDc, loadOrderListingExcelFilterStatusAndVendorAndWheel, loadOrderListingExcelFilterStatusAndVendorAndDc, loadOrderListingExcelFilterVendorAndDc, loadOrderListingExcelFilterStatusAndWheelAndDc, loadOrderListingExcelFilterVendorAndWheelAndDc, loadOrderListingExcelFilterStatusAndWheel, loadOrderListingExcelFilterStatusAndVendor } from '../../redux/Actions';
import { loadOrderListingPdfFilterStatusAndVendorAndWheelAndDcAndStartandEndDate, loadOrderListingExcelFilterStatusAndVendorAndWheelAndStartandEndDate, loadOrderListingExcelFilterVendorAndStartandEndDate, loadOrderListingExcelFilterWheelAndStartandEndDate, loadOrderListingExcelFilterDcAndStartandEndDate, loadOrderListingExcelFilterStatusAndStartandEndDate, loadOrderListingExcelFilterWheelAndDcAndStartandEndDate, loadOrderListingExcelFilterVendorAndDcAndStartandEndDate, loadOrderListingExcelFilterVendorAndWheelAndStartandEndDate, loadOrderListingExcelFilterStatusAndDcAndStartandEndDate, loadOrderListingExcelFilterStatusAndWheelAndStartandEndDate, loadOrderListingExcelFilterStatusAndVendorAndStartandEndDate, loadOrderListingExcelFilterVendorAndWheelAndDcAndStartandEndDate, loadOrderListingExcelFilterStatusAndWheelAndDcAndStartandEndDate, loadOrderListingExcelFilterStatusAndVendorAndDcAndStartandEndDate, loadOrderListingExcelFilterStatusAndVendorAndWheelAndDcAndStartandEndDate, loadOrderListingPdfFilterStatusAndVendorAndWheelAndStartandEndDate, loadOrderListingPdfFilterVendorAndWheelAndDcAndStartandEndDate, loadOrderListingPdfFilterStatusAndWheelAndDcAndStartandEndDate, loadOrderListingPdfFilterStatusAndVendorAndDcAndStartandEndDate, loadOrderListingPdfFilterWheelAndDcAndStartandEndDate, loadOrderListingPdfFilterVendorAndDcAndStartandEndDate, loadOrderListingPdfFilterStatusAndVendorAndStartandEndDate, loadOrderListingPdfFilterStatusAndWheelAndStartandEndDate, loadOrderListingPdfFilterStatusAndDcAndStartandEndDate, loadOrderListingPdfFilterVendorAndWheelAndStartandEndDate } from '../../redux/Actions';


import moment from 'moment';
import { useHistory } from 'react-router';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 190
        }
    }
};


const OrderListing = () => {
    // const { Search } = Input;
    const history = useHistory()
    const dispatch = useDispatch()

    const order = useSelector(state => state.dcUsers.orders.data)
    const vehicles = useSelector(state => state.data.vehicles.vehicles)
    const vendor = useSelector(state => state.vendorUsers.vendor.data)
    const dcm = useSelector(state => state.dcUsers.dc.data)
    const getOrderNumbers = useSelector(state => state.dcUsers.getOrderNumbers)

    const [searchTerm, setSearchTerm] = useState("")
    const [searchResults, setSearchResults] = useState({})
    const [status, setStatus] = useState("")
    const [wheel, setWheel] = useState("")
    const [ven, setVen] = useState("")
    const [dcNumber, setDcNumber] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [sorting, setSorting] = useState(false)
    const [checkedOrderNum, setCheckedOrderNum] = useState([])
    const [checked, setChecked] = useState()


    useEffect(() => {
        // dispatch(loadOrderListing())
        dispatch(loadVehicles())
        dispatch(loadVendorManagement())
        dispatch(loadDcManagement())
        dispatch(resetOrderNumbersForPdf())
    }, [])

    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }
    const handleWheelChange = (e) => {
        setWheel(e.target.value)

    }
    const handleVenChange = (e) => {
        setVen(e.target.value)

    }
    const handleDcNumberChange = (e) => {
        setDcNumber(e.target.value)
    }

    useEffect(() => {
        if (status.length > 1 && ven.length > 1 && wheel && dcNumber && startDate && endDate) {
            dispatch(loadFilterStatusAndVendorAndWheelAndDcAndStartDateAndEndDateOrder(status, ven, wheel, dcNumber, startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1 && wheel && startDate && endDate) {
            dispatch(loadFilterStatusAndVendorAndWheelAndStartDateAndEndDateOrder(status, ven, wheel, startDate, endDate))

        }
        else if (status.length > 1 && wheel && dcNumber && startDate && endDate) {
            dispatch(loadFilterStatusAndWheelAndDcAndStartDateAndEndDateOrder(status, wheel, dcNumber, startDate, endDate))

        }
        else if (status.length > 1 && ven && dcNumber && startDate && endDate) {
            dispatch(loadFilterStatusAndVendorAndDcAndStartDateAndEndDateOrder(status, ven, dcNumber, startDate, endDate))

        }
        else if (ven.length > 1 && wheel && dcNumber && startDate && endDate) {
            dispatch(loadFilterVendorAndWheelAndDcAndStartDateAndEndDateOrder(status, wheel, dcNumber, startDate, endDate))

        }
        else if (status.length > 1 && ven.length > 1 && startDate && endDate) {
            dispatch(loadFilterStatusAndVendorAndStartDateAndEndDateOrder(status, ven, startDate, endDate))
        }
        else if (status.length > 1 && wheel && startDate && endDate) {
            dispatch(loadFilterStatusAndWheelAndStartDateAndEndDateOrder(status, wheel, startDate, endDate))
        }
        else if (status.length > 1 && dcNumber && startDate && endDate) {
            dispatch(loadFilterStatusAndDcAndStartDateAndEndDateOrder(status, dcNumber, startDate, endDate))
        }
        else if (ven.length > 1 && wheel && startDate && endDate) {
            dispatch(loadFilterVendorAndWheelAndStartDateAndEndDateOrder(ven, wheel, startDate, endDate))
        }
        else if (ven.length > 1 && dcNumber && startDate && endDate) {
            dispatch(loadFilterVendorAndDcAndStartDateAndEndDateOrder(ven, dcNumber, startDate, endDate))
        }
        else if (wheel && dcNumber && startDate && endDate) {
            dispatch(loadFilterWheelAndDcAndStartDateAndEndDateOrder(wheel, dcNumber, startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1 && wheel && dcNumber.length > 1) {
            dispatch(loadFilterStatusAndVendorAndDcAndWheelerOrder(status, ven, wheel, dcNumber))
        }
        else if (status.length > 1 && ven.length > 1 && wheel) {
            dispatch(loadFilterStatusAndVendorAndWheelOrder(status, ven, wheel))
        }
        else if (status.length > 1 && startDate && endDate) {
            dispatch(loadFilterStatusAndStartDateAndEndDateOrder(status, startDate, endDate))
        }
        else if (ven.length > 1 && startDate && endDate) {
            dispatch(loadFilterVendorAndStartDateAndEndDateOrder(ven, startDate, endDate))
        }
        else if (wheel && startDate && endDate) {
            dispatch(loadFilterWheelAndStartDateAndEndDateOrder(wheel, startDate, endDate))
        }
        else if (dcNumber.length > 1 && startDate && endDate) {
            dispatch(loadFilterDcAndStartDateAndEndDateOrder(dcNumber, startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1 && dcNumber.length > 1) {
            dispatch(loadFilterStatusAndDcAndVendorOrder(status, ven, dcNumber))
        }
        else if (ven.length > 1 && wheel && dcNumber.length > 1) {
            dispatch(loadFilterVendorAndDcAndWheelerOrder(ven, wheel, dcNumber))
        }
        else if (status.length > 1 && wheel && dcNumber.length > 1) {
            dispatch(loadFilterStatusAndDcAndWheelerOrder(status, wheel, dcNumber))
        }
        else if (status.length > 1 && wheel) {
            dispatch(loadFilterStatusAndWheelerOrder(status, wheel))
        }
        else if (startDate && endDate) {
            dispatch(loadFilterStartDateAndEndDateOrder(startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1) {
            dispatch(loadFilterStatusAndVendorOrder(status, ven))
        }
        else if (status.length > 1 && dcNumber.length > 1) {
            dispatch(loadFilterStatusAndDcOrder(status, dcNumber))
        }
        else if (ven.length > 1 && wheel) {
            dispatch(loadFilterVendorAndWheelOrder(ven, wheel))
        }
        else if (ven.length > 1 && dcNumber.length > 1) {
            dispatch(loadFilterVendorAndDcOrder(ven, dcNumber))
        }
        else if (dcNumber.length > 1 && wheel) {
            dispatch(loadFilterDcAndWheelOrder(dcNumber, wheel))
        }
        else if (wheel) {
            dispatch(loadFilterWheelerOrder(wheel))
        }
        else if (status.length > 1) {
            dispatch(loadFilterStatusOrder(status))

        }
        else if (ven.length > 1) {
            dispatch(loadFilterVendorOrder(ven))
        }
        else if (dcNumber.length > 1) {
            dispatch(loadFilterDcOrder(dcNumber))
        }
        else {
            dispatch(loadOrderListing())
        }
    }, [status, wheel, ven, dcNumber, startDate, endDate])



    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };


        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired
    };



    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - order.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const searchItems = (searchValue) => {
        setSearchTerm(searchValue)
        if (searchTerm !== "") {
            const newOrderList = order.filter((ord) => {
                return (Object.values(ord).join(" ")?.toLowerCase().includes(searchTerm?.toLowerCase()))
            })
            setSearchResults(newOrderList)

        } else {
            setSearchResults(order)
        }

    }

    const downloadPdf = () => {
        if (status.length > 1 && ven.length > 1 && wheel && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterStatusAndVendorAndWheelAndDcAndStartandEndDate(status, ven, wheel, dcNumber, startDate, endDate))

        }
        else if (status.length > 1 && ven.length > 1 && wheel && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterStatusAndVendorAndWheelAndStartandEndDate(status, ven, wheel, startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1 && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterStatusAndVendorAndDcAndStartandEndDate(status, ven, dcNumber, startDate, endDate))
        }
        else if (status.length > 1 && wheel && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterStatusAndWheelAndDcAndStartandEndDate(status, wheel, dcNumber, startDate, endDate))
        }
        else if (ven.length > 1 && wheel && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterVendorAndWheelAndDcAndStartandEndDate(ven, wheel, dcNumber, startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1 && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterStatusAndVendorAndStartandEndDate(status, ven, startDate, endDate))
        }
        else if (status.length > 1 && wheel && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterStatusAndWheelAndStartandEndDate(status, wheel, startDate, endDate))
        }
        else if (status.length > 1 && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterStatusAndDcAndStartandEndDate(status, dcNumber, startDate, endDate))
        }
        else if (ven.length > 1 && wheel && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterVendorAndWheelAndStartandEndDate(ven, wheel, startDate, endDate))
        }
        else if (ven.length > 1 && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterVendorAndDcAndStartandEndDate(ven, dcNumber, startDate, endDate))
        }
        else if (wheel && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterWheelAndDcAndStartandEndDate(status, wheel, startDate, endDate))
        }

        else if (status.length > 1 && ven.length > 1 && wheel && dcNumber.length > 1) {
            dispatch(loadOrderListingPdfFilterStatusAndVendorAndWheelAndDc(status, ven, wheel, dcNumber))
        }
        else if (status.length > 1 && ven.length > 1 && wheel) {
            dispatch(loadOrderListingPdfFilterStatusAndVendorAndWheel(status, ven, wheel))
        }
        else if (status.length > 1 && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterStatusAndStartandEndDate(status, startDate, endDate))
        }
        else if (ven.length > 1 && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterVendorAndStartandEndDate(ven, startDate, endDate))
        }
        else if (wheel && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterWheelAndStartandEndDate(wheel, startDate, endDate))
        }
        else if (dcNumber.length > 1 && startDate && endDate) {
            dispatch(loadOrderListingPdfFilterDcAndStartandEndDate(dcNumber, startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1 && dcNumber.length > 1) {
            dispatch(loadOrderListingPdfFilterStatusAndVendorAndDc(status, ven, dcNumber))
        }
        else if (status.length > 1 && wheel && dcNumber.length > 1) {
            dispatch(loadOrderListingPdfFilterStatusAndWheelAndDc(status, wheel, dcNumber))
        }
        else if (ven.length > 1 && wheel && dcNumber.length > 1) {
            dispatch(loadOrderListingPdfFilterVendorAndWheelAndDc(ven, wheel, dcNumber))
        }
        else if (status.length > 1 && wheel) {
            dispatch(loadOrderListingPdfFilterStatusAndWheel(status, wheel))

        }
        else if (status.length > 1 && ven.length > 1) {
            dispatch(loadOrderListingPdfFilterStatusAndVendor(status, ven))
        }
        else if (status.length > 1 && dcNumber.length > 1) {
            dispatch(loadOrderListingPdfFilterStatusAndDc(status, dcNumber))
        }
        else if (ven.length > 1 && wheel) {
            dispatch(loadOrderListingPdfFilterVendorAndWheel(ven, wheel))
        }
        else if (ven.length > 1 && dcNumber.length > 1) {
            dispatch(loadOrderListingPdfFilterVendorAndDc(ven, dcNumber))
        }
        else if (dcNumber.length > 1 && wheel) {
            dispatch(loadOrderListingPdfFilterWheelAndDc(wheel, dcNumber))
        }

        else if (status.length > 1) {
            dispatch(loadOrderListingPdfFilterStatus(status))
        }
        else if (wheel) {
            dispatch(loadOrderListingPdfFilterWheel(wheel))
        }
        else if (ven.length > 1) {
            dispatch(loadOrderListingPdfFilterVendor(ven))
        }
        else if (dcNumber.length > 1) {
            dispatch(loadOrderListingPdfFilterDc(dcNumber))
        }
        else if (startDate.length > 1 && endDate.length > 1) {
            dispatch(loadOrderListingPdfFilterStartDate(startDate, endDate))
        }
        else if (getOrderNumbers) {
            dispatch(loadOrderListingDownloadSelectedOrdersPdf(getOrderNumbers))
        }
        else {
            dispatch(loadOrderListingPdf())
        }
    }


    const downloadExcel = () => {
        if (status.length > 1 && ven.length > 1 && wheel && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterStatusAndVendorAndWheelAndDcAndStartandEndDate(status, ven, wheel, dcNumber, startDate, endDate))

        }
        else if (status.length > 1 && ven.length > 1 && wheel && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterStatusAndVendorAndWheelAndStartandEndDate(status, ven, wheel, startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1 && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterStatusAndVendorAndDcAndStartandEndDate(status, ven, dcNumber, startDate, endDate))
        }
        else if (status.length > 1 && wheel && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterStatusAndWheelAndDcAndStartandEndDate(status, wheel, dcNumber, startDate, endDate))
        }
        else if (ven.length > 1 && wheel && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterVendorAndWheelAndDcAndStartandEndDate(ven, wheel, dcNumber, startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1 && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterStatusAndVendorAndStartandEndDate(status, ven, startDate, endDate))
        }
        else if (status.length > 1 && wheel && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterStatusAndWheelAndStartandEndDate(status, wheel, startDate, endDate))
        }
        else if (status.length > 1 && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterStatusAndDcAndStartandEndDate(status, dcNumber, startDate, endDate))
        }
        else if (ven.length > 1 && wheel && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterVendorAndWheelAndStartandEndDate(ven, wheel, startDate, endDate))
        }
        else if (ven.length > 1 && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterVendorAndDcAndStartandEndDate(ven, dcNumber, startDate, endDate))
        }
        else if (wheel && dcNumber && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterWheelAndDcAndStartandEndDate(status, wheel, startDate, endDate))
        }

        else if (status.length > 1 && ven.length > 1 && wheel && dcNumber.length > 1) {
            dispatch(loadOrderListingExcelFilterStatusAndVendorAndWheelAndDc(status, ven, wheel, dcNumber))
        }

        else if (status.length > 1 && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterStatusAndStartandEndDate(status, startDate, endDate))
        }
        else if (ven.length > 1 && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterVendorAndStartandEndDate(ven, startDate, endDate))
        }
        else if (wheel && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterWheelAndStartandEndDate(wheel, startDate, endDate))
        }
        else if (dcNumber.length > 1 && startDate && endDate) {
            dispatch(loadOrderListingExcelFilterDcAndStartandEndDate(dcNumber, startDate, endDate))
        }
        else if (status.length > 1 && ven.length > 1 && wheel) {
            dispatch(loadOrderListingExcelFilterStatusAndVendorAndWheel(status, ven, wheel))
        }
        else if (status.length > 1 && ven.length > 1 && dcNumber.length > 1) {
            dispatch(loadOrderListingExcelFilterStatusAndVendorAndDc(status, ven, dcNumber))
        }
        else if (status.length > 1 && wheel && dcNumber.length > 1) {
            dispatch(loadOrderListingExcelFilterStatusAndWheelAndDc(status, wheel, dcNumber))
        }
        else if (ven.length > 1 && wheel && dcNumber.length > 1) {
            dispatch(loadOrderListingExcelFilterVendorAndWheelAndDc(ven, wheel, dcNumber))
        }
        else if (status.length > 1 && wheel) {
            dispatch(loadOrderListingExcelFilterStatusAndWheel(status, wheel))

        }
        else if (status.length > 1 && ven.length > 1) {
            dispatch(loadOrderListingExcelFilterStatusAndVendor(status, ven))
        }
        else if (status.length > 1 && dcNumber.length > 1) {
            dispatch(loadOrderListingExcelFilterStatusAndDc(status, dcNumber))
        }
        else if (ven.length > 1 && wheel) {
            dispatch(loadOrderListingExcelFilterVendorAndWheel(ven, wheel))
        }
        else if (ven.length > 1 && dcNumber.length > 1) {
            dispatch(loadOrderListingExcelFilterVendorAndDc(ven, dcNumber))
        }
        else if (dcNumber.length > 1 && wheel) {
            dispatch(loadOrderListingExcelFilterWheelAndDc(wheel, dcNumber))
        }
        else if (status.length > 1) {
            dispatch(loadOrderListingExcelFilterStatus(status))
        }
        else if (wheel.length > 1) {
            dispatch(loadOrderListingExcelFilterWheel(wheel))
        }
        else if (ven.length > 1) {
            dispatch(loadOrderListingExcelFilterVendor(ven))
        }
        else if (dcNumber.length > 1) {
            dispatch(loadOrderListingExcelFilterDc(dcNumber))
        }
        else if (startDate.length > 1 && endDate.length > 1) {
            dispatch(loadOrderListingExcelFilterStartDate(startDate, endDate))
        }
        else if (getOrderNumbers) {
            dispatch(loadOrderListingDownloadSelectedOrdersExcel(getOrderNumbers))
        }
        else {
            dispatch(loadOrderListingExcel())
        }
    }

    function onChangeStartDate(date, dateString) {
        setStartDate(dateString)
    }

    function onChangeEndDate(date, dateString) {
        setEndDate(dateString)
    }

    const removeFilter = () => {
        setStatus("")
        setVen("")
        setWheel("")
        setDcNumber("")
        setStartDate("")
        setEndDate("")
    }

    function add3Dots(string, limit) {
        var dots = "...";
        var limit = 25
        if (string ? string.length > limit : "") {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }
    let user = JSON.parse(getCookie("user-info"))
    function disabledDate(current) {
        const start = moment(startDate, 'YYYY-MM-DD');
        return current < start;
    }
    // function disabledDatesss(current) {
    //     // Can not select Sundays and predefined days
    //     return (
    //         moment(current).day() === 0
    //     );
    // }

    function parseValue(v) {
        // extract number (defaults to 0 if not present)
        if (!v) v = "";
        v = v?.toLowerCase()
        var n = +(v.match(/\d+$/) || [0])[0];
        var str = v.replace(n, '');  // extract string part
        return [str, n];
    }



    const onSort = (columnName) => {
        if (!sorting) {
            order.sort((a, b) => {
                return (parseValue(a[columnName]) > parseValue(b[columnName])) ? 1 : ((parseValue(b[columnName]) > parseValue(a[columnName])) ? -1 : 0)
            });
        }
        else {
            order.sort((a, b) => {
                return (parseValue(a[columnName]) > parseValue(b[columnName])) ? -1 : ((parseValue(b[columnName]) > parseValue(a[columnName])) ? 1 : 0)
            })
        }
        setSorting(prev => !prev)
    }

    const onSortDate = (columnName) => {
        if (!sorting) {
            order.sort((a, b) => {
                return ((a[columnName]) > (b[columnName])) ? 1 : (((b[columnName]) > (a[columnName])) ? -1 : 0)
            });
        }
        else {
            order.sort((a, b) => {
                return ((a[columnName]) > (b[columnName])) ? -1 : (((b[columnName]) > (a[columnName])) ? 1 : 0)
            })
        }
        setSorting(prev => !prev)
    }


    const currentPosts = order && order ? order.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : ''

    const checkboxHandler = (value, e) => {
        setChecked(e?.target.checked);
        if (e.target.checked) {
            dispatch(getOrderNumbersForPdf([...getOrderNumbers, value]))
        } else {
            let arr = [...getOrderNumbers].filter((item) => item !== value);
            dispatch(getOrderNumbersForPdf(arr))
            console.log("5675434567", getOrderNumbers, arr)
        }
    }
    console.log("getOrderNumbers", getOrderNumbers)


    return (
        <div>
            <div className="filter-section-listing">
                <div className="filter-top">
                    <h6><span><img src={filter} alt="filter" className="filter-icon" /></span>Filter By</h6>
                    {status || ven || wheel || dcNumber || startDate || endDate ? <p onClick={removeFilter}>Clear</p> : <p></p>}

                </div>
                <hr className="horizontal" />

                <div>
                    <div className="order-status-dropdown">
                        <div className="col-sm-3 d-flex justify-content-start">
                            <FormControl size="small" sx={{ mt: 1, mb: 2 }} className="order-listing-drop">
                                <InputLabel id="demo-simple-select-helper-label" >Select Order Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={status}
                                    MenuProps={MenuProps}
                                    label="Select Order Status"
                                    onChange={handleStatusChange}
                                >
                                    <MenuItem value="Order Departed">Order placed</MenuItem>
                                    <MenuItem value='Unloading'>Order reached</MenuItem>
                                    <MenuItem value="Delivered">Order Delivered</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        {user.role === "admin" || user.role === "dc" ?

                            <div className="col-sm-3 d-flex justify-content-start">
                                <FormControl size="small" sx={{ mt: 1, mb: 2 }} className="order-listing-drop">
                                    <InputLabel id="demo-simple-select-helper-label">Select Vendor</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={ven}
                                        MenuProps={MenuProps}
                                        label="Select Vendor"
                                        onChange={handleVenChange}
                                    >
                                        {
                                            vendor && vendor.length ? vendor.map((vendor) => (
                                                <MenuItem value={vendor?.vendor_id}>{vendor?.name}</MenuItem>
                                            )) : null}
                                    </Select>
                                </FormControl>
                            </div> : ""}
                        <div className="col-sm-3 d-flex justify-content-start">
                            <FormControl size="small" sx={{ mt: 1, mb: 2 }} className="order-listing-drop" >
                                <InputLabel id="demo-simple-select-helper-label" >Choose Vehicle Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={wheel}
                                    MenuProps={MenuProps}
                                    label="Choose Vehicle Type"
                                    onChange={handleWheelChange}
                                >
                                    {
                                        vehicles && vehicles.length ? vehicles.map((veh) => (
                                            <MenuItem value={veh?.id}>{veh?.wheeler}</MenuItem>
                                        )) : null}
                                </Select>

                            </FormControl>
                        </div>



                        {user.role === "admin" || user.role === "vendor" ?

                            <div className="col-sm-3 d-flex justify-content-start">
                                <FormControl size="small" sx={{ mt: 1, mb: 2 }} className="order-listing-drop">
                                    <InputLabel id="demo-simple-select-helper-label" >Select DC</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={dcNumber}
                                        MenuProps={MenuProps}
                                        label="Select DC"
                                        onChange={handleDcNumberChange}
                                    >
                                        {
                                            dcm && dcm.length ? dcm.map((dc) => (
                                                <MenuItem value={dc?.dc_number}>{dc?.dc_number}</MenuItem>
                                            )) : null
                                        }

                                    </Select>

                                </FormControl>
                            </div> : ""}
                    </div>
                    <div className="d-flex">
                        <div className="col-sm-3 input-container">
                            <div className="wrapper input-wrapper">
                                <DatePicker placeholder="Start Date" onChange={onChangeStartDate} value={startDate !== "" ? moment(startDate) : ""} className="date"
                                // disabledDate={disabledDatesss}
                                />
                                <img src={calendarIcon} className="calendar-icon" alt="calendaricon" />
                            </div>
                        </div>
                        <div className="col-sm-3 input-container">
                            <div className="input-wrapper">
                                <DatePicker placeholder="End Date" onChange={onChangeEndDate} disabledDate={disabledDate} value={endDate !== "" ? moment(endDate) : ""} className="dateOne" />
                                <img src={calendarIcon} className="calendar-icon-enddate" alt="calendaricon" />
                            </div>

                        </div>
                        <div className="col-sm-6 input-container">
                            <div className="input-wrapper wrapper-long">
                                <Input className="search-input" placeholder="Type Order no, Invoice no, Vendor name..." value={searchTerm} onChange={(e) => searchItems(e.target.value)} />
                                <img src={search} className="icon" alt="Searchicon" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="main-section-orderlisting">
                <div className="order-download">
                    <Dropdown>
                        <Dropdown.Toggle className="download-drop">
                            <span><img src={downloadIcon} alt="download" /></span> Download
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={downloadPdf} >Download PDF</Dropdown.Item>
                            <Dropdown.Item onClick={downloadExcel} >Download Excel</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </div>

                <div className="table-container">
                    <Paper sx={{
                        width: '100%', overflow: 'hidden', ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {

                            mt: 1.8,
                            mr: -1

                        },

                        ".MuiTablePagination-displayedRows": {
                            mt: 2
                        },
                        ".css-194a1fa-MuiSelect-select-MuiInputBase-input.MuiSelect-select": {
                            paddingTop: 1,
                            fontFamily: "Poppins"
                        }
                    }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="custom pagination table">
                                <TableHead >
                                    <TableRow >
                                        {user.role === "dc" || user.role === "admin" ? <StyledTableCell id="table-head-font" className="table-head" align="centre"></StyledTableCell> : ''}
                                        <StyledTableCell id="table-head-font" className="table-head">Orders <img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSort('ordernum')} /></StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Vendor Name <img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSort('vendor_name')} /></StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">DC<img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSort('dcnumber')} /></StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Vehicle Type<img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSort('wheeler')} /></StyledTableCell>
                                        {/* <StyledTableCell id="table-head-font" className="table-head" align="centre">Recent Toll<img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSort('recent_toll')} /></StyledTableCell> */}
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Logistics<img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSort('logistics')} /></StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Driver Name<img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSort('last_driver_name')} /></StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Mobile Number<img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSort('last_driver_contact')} /></StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Delivery Date<img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSortDate('delivery_date')} /></StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Status<img style={{ cursor: "pointer" }} src={sort} alt="sort" onClick={() => onSort('vahan_status')} /></StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {searchTerm && searchTerm.length ? searchResults.map((item) => (
                                        <TableRow key={item.ordernum}>
                                            <TableCell id="table-head-font" align="centre" component="th" scope="row">
                                                {add3Dots(item?.ordernum)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre"  >
                                                {add3Dots(item?.vendor_name)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.dcnumber)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.wheeler)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots((item?.recent_toll ? item?.recent_toll : "-"))}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.logistics)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.last_driver_name)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.last_driver_contact)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {item?.delivery_date}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.vahan_status)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >

                                                <img src={viewIcon} style={{ marginRight: "5px", marginTop: "0.7rem", cursor: "pointer" }}
                                                    onClick={() => {
                                                        const orderData = {
                                                            dcnumber: item?.dcnumber,
                                                            delivery_date: item?.delivery_date,
                                                            door_no: item?.door_no,
                                                            ordernum: item?.ordernum
                                                        }
                                                        const searchParams = new URLSearchParams(orderData);
                                                        history.push(`/orderview/order?${searchParams}`);
                                                    }
                                                    } />


                                            </TableCell>
                                        </TableRow>)) : (currentPosts && currentPosts.length ? currentPosts.map((ord) => (
                                            <TableRow key={ord.ordernum}>
                                                {user.role === "dc" || user.role === "admin" ?
                                                    <TableCell id="table-head-font" align="centre" component="th" scope="row">
                                                        <Checkbox onChange={(e) => checkboxHandler(ord?.ordernum, e)}></Checkbox>
                                                    </TableCell>
                                                    : ''}
                                                <TableCell id="table-head-font" align="centre" component="th" scope="row">
                                                    {add3Dots(ord?.ordernum)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre"  >
                                                    {add3Dots(ord?.vendor_name)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(ord?.dcnumber)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(ord?.wheeler)}
                                                </TableCell>
                                                {/* <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(ord.recent_toll ? ord.recent_toll : "-")}
                                                </TableCell> */}
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(ord?.logistics)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(ord?.last_driver_name)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(ord?.last_driver_contact)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {ord?.delivery_date}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(ord?.vahan_status)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >

                                                    <img src={viewIcon} style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            const orderData = {
                                                                dcnumber: ord?.dcnumber,
                                                                delivery_date: ord?.delivery_date,
                                                                door_no: ord?.door_no,
                                                                ordernum: ord?.ordernum
                                                            }
                                                            const searchParams = new URLSearchParams(orderData);
                                                            history.push(`/orderview/order?${searchParams}`);
                                                        }
                                                        } />
                                                </TableCell>
                                            </TableRow>
                                        )) : "")}

                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell id="table-head-font" colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>

                        </TableContainer>
                        <TablePagination id="table-head-font" className="pagination"
                            rowsPerPageOptions={[5, 10, 25, { label: "All", value: order ? order.length : '' }]}
                            colSpan={3}
                            count={order ? order.length : null}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            // SelectProps={{
                            //     inputProps: {
                            //         "aria-label": "rows per page"
                            //     },
                            //     native: true
                            // }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Paper>
                </div>
            </div>
        </div >
    )
}

export default OrderListing
