import { type } from '@testing-library/user-event/dist/type'
import * as types from './ActionTypes'
const initialDcState = {
    dc: [],
    orders: [],
    cards: {},
    ddco: [],
    error: {},
    allStates: [],
    allCities: [],
    today: [],
    getDCHoliday: [],
    getOrderNumbers: [],
    loading: false
}
const initialVendorState = {
    vendor: [],
    dvo: [],
    msg: {},
    singleVendor: {},
    loading: false
}

const initialState = {
    dcData: [],
    vehicles: [],
    logisticsDetails: [],
    singleDc: {},
    slots: [],
    vendor: [],
    orderDetails: [],
    orderVendor: {},
    updated: [],
    upload: [],
    vahanUpdate: [],
    availableSlots: [],
    errorMessage: {},
    deliveryCenterSlots: [],
    orderStatus: [],
    updateStatus: {},
    vendorStatus: {},
    newLogs: {},
    dc_inputs: {},
    comment: [],
    profile: {},
    recoveryMail: {},
    password: {},
    driverAdd: {},
    driverEdit: {},
    driverDelete: {},
    getUpdatedOrderNum: {},
    getVehicleDetails: {},
    getSingleOrderDetails: {},
    getNewOrderDetails: {},
    loading: false
}

export const getDcReducer = (state = initialDcState, action) => {
    switch (action.type) {
        case types.GET_DC:
            return {
                ...state,
                dc: action.payload,
                loading: false
            }
        case types.ADD_DC_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.ADD_DC:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case types.GET_ALL_STATES:
            return {
                ...state,
                allStates: action.payload,
                loading: false
            }
        case types.GET_ALL_CITIES:
            return {
                ...state,
                allCities: action.payload,
                loading: false
            }
        case types.GET_ALL_ORDERS:
            return {
                ...state,
                orders: action.payload,
                loading: false
            }

        case types.GET_DASHBOARD_CARDS:
            return {
                ...state,
                cards: action.payload,
                loading: false
            }
        case types.GET_DASHBOARD_DC_ORDER:
            return {
                ...state,
                ddco: action.payload,
                loading: false
            }
        case types.RESET_ORDER_LISTING:
            return {
                ...state,
                orders: []
            }
        case types.GET_TODAY_ORDERS:
            return {
                ...state,
                today: action.payload,
                loading: false
            }
        case types.GET_HOLIDAY:
            return {
                ...state,
                getDCHoliday: action.payload,
                loading: false
            }
        case types.GET_ORDER_NUMBERS_FOR_PDF:
            return {
                ...state,
                getOrderNumbers: action.payload,
                loading: false
            }
        case types.RESET_ORDER_NUMBERS_FOR_PDF:
            return {
                ...state,
                getOrderNumbers: [],
                loading: false
            }
        default:
            return state;
    }
}
export const getVendorReducer = (state = initialVendorState, action) => {
    switch (action.type) {
        case types.GET_VENDOR:
            return {
                ...state,
                vendor: action.payload,
                loading: false
            }
        case types.ADD_VENDOR_LOADING:

            return {
                ...state,
                loading: true
            }
        case types.ADD_VENDOR:

            return {
                ...state,
                msg: action.payload,
                loading: false
            }

        case types.GET_SINGLE_VENDOR:
            return {
                ...state,
                singleVendor: action.payload,
                loading: false
            }
        case types.UPDATE_VENDOR:
            return {
                ...state,
                loading: false
            }
        case types.GET_DASHBOARD_VENDOR_ORDER:
            return {
                ...state,
                dvo: action.payload,
                loading: false
            }

        default:
            return state;
    }
}


export const getOrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CREATE_ORDER:
            return {
                ...state,
                errorMessage: action.payload,
                loading: false
            }
        case types.ADD_COMMENTS:
            return {
                ...state,
                comment: action.payload,
                loading: false
            }
        case types.GET_ALL_VEHICLES:
            return {
                ...state,
                vehicles: action.payload,
                loading: false
            }
        case types.GET_ALL_LOGISTICS:
            return {
                ...state,
                logisticsDetails: action.payload,
                loading: false
            }
        case types.GET_SINGLE_DC:
            return {
                ...state,
                singleDc: action.payload,
                loading: false
            }
        case types.UPDATE_DC:
            return {
                ...state,
                loading: false
            }
        case types.ADD_DC_DATE:
            return {
                ...state,
                slots: action.payload,
                loading: false
            }
        case types.ADD_DC_DATE_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_VENDOR_BY_LOGIN:
            return {
                ...state,
                vendor: action.payload,
                loading: false
            }
        case types.GET_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: action.payload,
                loading: false
            }
        case types.GET_ORDER_DETAILS_LOADING:
            return {
                ...state,
                loading: true
            }
        case types.GET_ORDER_VENDOR_ID:
            return {
                ...state,
                orderVendor: action.payload,
                loading: false
            }
        case types.UPDATE_ORDER:
            return {
                ...state,
                updated: action.payload,
                updateStatus: action.payload.status,
                loading: false
            }
        case types.UPLOAD_VAHAN_UPDATE:
            return {
                ...state,
                upload: action.payload,
                loading: false
            }
        case types.GET_VAHAN_UPDATE:
            return {
                ...state,
                vahanUpdate: action.payload,
                loading: false
            }
        case types.GET_DOOR_SLOTS_AVAILABLE:
            return {
                ...state,
                availableSlots: action.payload,
                loading: false,
            }
        case types.GET_DC_SLOTS_AVAILABLE:
            return {
                ...state,
                deliveryCenterSlots: action.payload,
                loading: false,
            }
        case types.GET_ORDER_STATUS:
            return {
                ...state,
                orderStatus: action.payload,
                loading: false
            }
        case types.RESET_SINGLE_DC:
            return {
                ...state,
                singleDc: {},
            }
        case types.RESET_DC_DATE:
            return {
                ...state,
                slots: []
            }
        case types.RESET_GET_ORDER_DETAILS:
            return {
                ...state,
                orderDetails: []
            }
        case types.VAHAN_UPLOAD_UPDATE:
            return {
                ...state,
                loading: false
            }
        case types.GET_VENDOR_STATUS:
            return {
                ...state,
                vendorStatus: action.payload,
                loading: false
            }
        case types.ADD_INVOICE_UPLOAD:
            return {
                ...state,
                loading: false
            }
        case types.ADD_LOGISTICS:
            return {
                ...state,
                newLogs: action.payload,
                loading: false
            }

        case types.CHANGE_DC_INPUTS:
            return {
                ...state,
                dc_inputs: action.payload,
                loading: false
            }
        case types.ADD_PROFILE_PHOTO:
            return {
                ...state,
                loading: false
            }
        case types.GET_PROFILE_DETAILS:
            return {
                ...state,
                profile: action.payload,
                loading: false
            }
        case types.RECOVERY_EMAIL:
            return {
                ...state,
                recoveryMail: action.payload,
                loading: false
            }
        case types.GET_RESET_PASSWORD_LINK:
            return {
                ...state,
                password: action.payload,
                loading: false
            }
        case types.ADD_NEW_PASSWORD:
            return {
                ...state,
                loading: false
            }
        case types.EDIT_DRIVER_DETAILS:
            return {
                ...state,
                driverEdit: action.payload,
                loading: false
            }
        case types.DELETE_DRIVER:
            return {
                ...state,
                driverDelete: action.payload,
                loading: false
            }
        case types.ADD_DRIVER:
            return {
                ...state,
                driverAdd: action.payload,
                loading: false
            }
        case types.TRACKING_LINK:
            return {
                ...state,
                loading: false
            }
        case types.UPDATE_ORDER_NUM:
            return {
                ...state,
                getUpdatedOrderNum: action.payload,
                loading: false
            }
        case types.GET_VEHICLE_DETAILS:
            return {
                ...state,
                getVehicleDetails: action.payload,
                loading: false
            }
        case types.GET_SINGLE_ORDER_DETAILS:
            return {
                ...state,
                getSingleOrderDetails: action.payload,
                loading: false
            }
        case types.GET_NEW_ORDER_DETAILS:
            return {
                ...state,
                getNewOrderDetails: action.payload,
                loading: false
            }
        default:
            return state
    }
}