import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadSingleVendorManagement, upadteVendor, loadAllCities, loadAllStates } from '../../redux/Actions';
import { useParams } from 'react-router';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { blockInvalidChar } from '../../blockInvalidChar';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 190
        }
    }
};


const EditVendor = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    let { id } = useParams()

    const msg = useSelector(state => state.vendorUsers.msg)
    const singleVendor = useSelector(state => state.vendorUsers.singleVendor.vendor)
    const allStates = useSelector(state => state.dcUsers.allStates.states)
    const allCities = useSelector(state => state.dcUsers.allCities.cities)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [account_type, setAccount_type] = useState("")
    const [gst_number, setGst_number] = useState("")
    const [contactperson, setContactperson] = useState("")
    const [address, setAddress] = useState("")
    const [status, setStatus] = useState("")
    const [numberError, setNumberError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [gstError, setGstError] = useState(false)





    const handleVendorName = (e) => {
        setName(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    }
    const handleEmail = (e) => {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        setEmail(e.target.value)
        if (regex.test(e.target.value) === false) {
            setEmailError(true)
        }
        else {
            setEmailError(false)
        }

    }
    const handleAccountType = (e) => {
        setAccount_type(e.target.value)
    }
    const handleContactNumber = (e) => {
        setPhone(e.target.value)
        if (e.target.value.length !== 10) {
            setNumberError(true)
        }
        else {
            setNumberError(false)
        }

    }
    const handleGst = (e) => {
        setGst_number(e.target.value.replace(/[^\w\s]/gi, ""))
        if (e.target.value.length !== 16) {
            setGstError(true)
        }
        else {
            setGstError(false)
        }
    }

    const handleContactperson = (e) => {
        setContactperson(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    }
    const handleAddress = (e) => {
        setAddress(e.target.value)
    }
    const handleState = (e) => {
        setState(e.target.value)

    }
    const handleStatus = (e) => {
        setStatus(e.target.value)
    }

    const handleCity = (e) => {
        setCity(e.target.value)
    }

    useEffect(() => {
        dispatch(loadSingleVendorManagement(id))
    }, [])
    useEffect(() => {
        if (singleVendor) {
            const singleName = singleVendor?.name
            const singleEmail = singleVendor?.email
            const singlePhone = singleVendor?.phone
            const singleAccount = singleVendor?.account_type
            const singleContactPerson = singleVendor?.contactperson
            const singleAddress = singleVendor?.address
            const singleState = singleVendor?.state
            const singleCity = singleVendor?.city
            const singleGst = singleVendor?.gst_number
            const singleStatus = singleVendor?.status
            setName(singleName)
            setEmail(singleEmail)
            setPhone(singlePhone)
            setAccount_type(singleAccount)
            setContactperson(singleContactPerson)
            setAddress(singleAddress)
            setState(singleState)
            setCity(singleCity)
            setGst_number(singleGst)
            setStatus(singleStatus)
        }
    }, [singleVendor])


    useEffect(() => {
        dispatch(loadAllStates())
    }, [])
    useEffect(() => {
        if(state){
            dispatch(loadAllCities(state))
        }
    }, [state])





    const finaldata = {
        name,
        email,
        phone,
        city,
        state,
        address,
        account_type,
        gst_number,
        contactperson,
        status
    }


    const handleUpdate = (e) => {
        toast.configure();
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        e.preventDefault()
        if (!name || !email || !phone || !city || !state || !account_type || !gst_number || !contactperson || !address) {
            toast.error("You can not update if any one of the field is empty", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else if (phone.length != 10) {
            toast.error('Please add a valid contact number', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        } else if (!email || regex.test(email) === false) {
            toast.warning('Please add a valid Email address', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else if (gst_number.length != 16) {
            toast.warning('Please add a valid GST number', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else {
            dispatch(upadteVendor(finaldata, id))
            setTimeout(() => {
                history.push("/vendor")
            }, 2000)
        }
    }

    return (
        <div className='addDc'>

            <form className="add-input" onSubmit={handleUpdate}>

                <div className='row'>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Name" variant="standard" name="name" value={name} type="text" onChange={handleVendorName} className='dc-textfield-width' />

                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Email" variant="standard" name="email" value={email} type="email" error={emailError} onChange={handleEmail} className='dc-textfield-width' />

                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Mobile Number" variant="standard" name="phone" value={phone} type="number" error={numberError} onChange={handleContactNumber}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }} className='dc-textfield-width' onKeyDown={blockInvalidChar} />
                    </div>
                    <div className="col-md-4 dc-margin vendor-margin-top">
                        <FormControl variant="standard" >
                            <InputLabel id="demo-simple-select-standard-label" className='dc-textfield-width' >Select Account Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                // id="demo-simple-select-standard"
                                value={account_type}
                                onChange={handleAccountType}
                                label="account type"
                                className='dc-textfield-width'
                            >
                                <MenuItem value="Permanent">Permanent</MenuItem>
                                <MenuItem value="Temporary">Temporary</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="GST Number" variant="standard" name="gst_number" value={gst_number} type="text" error={gstError} onChange={handleGst} inputProps={{ maxLength: 16 }} className='dc-textfield-width' />

                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Contact Person" variant="standard" name="contactperson" value={contactperson} type="text" onChange={handleContactperson} className='dc-textfield-width' />

                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Enter your address" variant="standard" name="address" value={address} type="text" onChange={handleAddress} className='dc-textfield-width' />
                    </div>
                    <div className="col-md-4 dc-margin vendor-margin-top">
                        <FormControl variant="standard" >
                            <InputLabel id="demo-simple-select-standard-label" className='dc-textfield-width'>Select State</InputLabel>
                            {allStates && Array.isArray(allStates) && allStates.length ? <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={state || ""}
                                onChange={handleState}
                                MenuProps={MenuProps}
                                className='dc-textfield-width'
                                label="state"
                            >
                                {allCities && allCities.length && allStates.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item}</MenuItem>


                                ))}
                            </Select> : ""}
                        </FormControl>
                    </div>
                    <div className="col-md-4 dc-margin vendor-margin-top">
                        <FormControl variant="standard" >
                            <InputLabel id="demo-simple-select-standard-label" >Select City</InputLabel>
                            {allCities && Array.isArray(allCities) && allCities.length ? <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={city || ""}
                                onChange={handleCity}
                                MenuProps={MenuProps}
                                className='dc-textfield-width'
                                label="city"
                            >
                                {allCities && allCities.length && allCities.map((item, index) => (
                                    <MenuItem key={index} value={item}>{item}</MenuItem>
                                ))}
                            </Select> : ""}
                        </FormControl>
                    </div>
                    <div className="col-md-4 dc-margin vendor-margin-top">
                        <FormControl variant="standard" >
                            <InputLabel id="demo-simple-select-standard-label" className='dc-textfield-width'>Select Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={status}
                                name="status"
                                onChange={handleStatus}
                                label="status"
                                className='dc-textfield-width'
                            >

                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="In Active">In Active </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className='dc-flex'>
                    <Button variant="contained" color="primary" type="submit" className='button-dc-submit'> Submit</Button>
                </div>
            </form>

            <ToastContainer />

        </div>
    )
}

export default EditVendor