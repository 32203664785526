import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { inputLabelClasses } from "@mui/material/InputLabel";
import { TextField } from '@mui/material';
import './Holiday.css'
import { loadGetDcHoliday } from '../../redux/Actions';
import { useDispatch, useSelector } from 'react-redux'
import addHolidayIcon from '../../images/add-driver-outline.svg'
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "react-datepicker";
import { loadAddDcHoliday } from '../../redux/Actions';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCookie } from '../utils/Cookie';
import getDay from 'react-datepicker';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function BasicTable() {
    let user = JSON.parse(getCookie("user-info"))
    const dispatch = useDispatch()
    let dcNumber = useParams()


    const getDCHoliday = useSelector(state => state.dcUsers.getDCHoliday)
    const singleDc = useSelector(state => state.data.singleDc.DC)


    const [addholiday, setAddHoliday] = useState(false)
    const [shrink3, setShrink3] = useState(true)
    const [holidays, setHolidays] = useState()
    const [from_date, setFromDate] = useState()
    const [to_date, setToDate] = useState()
    const [reason, setReason] = useState()



    // const [newHoliday, setNewHoliday] = useState(
    //     {
    //         dc: "",
    //         from_date: "",
    //         to_date: "",
    //         reason: ""
    //     }
    // )


    // const { dc, from_date, to_date, reason } = newHoliday

    const addHolidayHandler = () => {
        setAddHoliday(true)
    }

    const handleInputAddNewHolidayName = (e) => {
        setHolidays(e.target.value)
    }

    const handleInputAddNewHolidayDateFrom = (dateString) => {
        const date = new Date(dateString).toISOString()
        let formattingFromDate = moment(date).format('yyyy-MM-DD')
        setFromDate(formattingFromDate)
    }

    const handleInputAddNewHolidayDateTo = (dateString) => {
        let formattingToDate = moment(dateString).format('yyyy-MM-DD')
        setToDate(formattingToDate)
    }

    const handleInputAddNewHolidayReason = (e) => {
        setReason(e.target.value)
    }


    const holiday = {
        holiday: holidays,
        dc: singleDc?.dc_number,
        from_date: from_date,
        to_date: to_date,
        reason: reason
    }


    const addHolidayOnClick = () => {
        toast.configure()
        if (!from_date || !to_date || !reason) {
            toast.error('Please add all input fields', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else {
            {
                user.role === "dc" ?
                    dispatch(loadAddDcHoliday(holiday, user?.vendor_dc_id))
                    : dispatch(loadAddDcHoliday(holiday, dcNumber?.id))
            }
            setHolidays('')
            setFromDate('')
            setToDate('')
            setReason('')
            setAddHoliday(false)
        }
    }

    useEffect(() => {
        {
            user.role === "dc" ?
                dispatch(loadGetDcHoliday(user?.vendor_dc_id))
                : dispatch(loadGetDcHoliday(dcNumber?.id))
        }
    }, [])

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0;
    };


    return (
        <div>
            <div>
                <h3>Holidays - {singleDc?.dc_name}</h3>
            </div>
            <div>
                <TableContainer component={Paper} className='holiday-list-max-height'>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className="table-head-holidays">
                            <TableRow>
                                <TableCell className='holiday-table-data-width'>Date</TableCell>
                                <TableCell className='holiday-table-data-width'>Day</TableCell>
                                <TableCell className='holiday-table-data-width'>Holiday</TableCell>
                                <TableCell>Reason</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='table-body-holidays'>
                            {getDCHoliday?.data && getDCHoliday?.data.length && getDCHoliday?.data?.map((holiday) => (
                                <TableRow key={holiday?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="holiday">{holiday?.from_date === holiday?.to_date ? moment(holiday?.from_date).format("MMM Do") : `${moment(holiday?.from_date).format("MMM Do")} - ${moment(holiday?.to_date).format("MMM Do")}`}</TableCell>
                                    <TableCell>{holiday?.from_date === holiday?.to_date ? moment(holiday?.from_date).format("ddd") : `${moment(holiday?.from_date).format("ddd")} - ${moment(holiday?.to_date).format("ddd")}`}</TableCell>
                                    <TableCell>{holiday?.holiday}</TableCell>
                                    <TableCell>{holiday?.reason}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            {addholiday ?
                <div className='add-holiday-main-container'>
                    <div className='inputs-mgTop'>
                        <TextField id="standard-basic" label="Holiday" variant="standard" name="holidays" value={holidays} className="text-input-mgRight" onChange={(e) => handleInputAddNewHolidayName(e)} InputLabelProps={{
                            sx: {
                                color: "#FCB713",
                                [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#FCB713"
                                }
                            }
                        }} InputLabelProps={{ shrink: shrink3 }} />
                    </div>
                    <div className="select-from-calendar">
                        <label className="select-from-date-label">Select From Date</label>
                        <DatePicker
                            placeholderText=""
                            value={from_date}
                            // minDate={moment().toDate()}
                            onChange={date => handleInputAddNewHolidayDateFrom(date)}
                            className="dateVendors given_date text-input-mgRight"
                            // dateFormat="DD/MM/YYYY HH:mm:ss"
                            // dateFormat="MMMM d, yyyy h:mm aa"
                            filterDate={isWeekday}
                        />
                    </div>
                    <div className="select-from-calendar">
                        <label className="select-from-date-label">Select To Date</label>
                        <DatePicker
                            placeholderText=""
                            value={to_date}
                            onChange={date => handleInputAddNewHolidayDateTo(date)}
                            className="dateVendors given_date text-input-mgRight"
                            minDate={from_date ? moment(from_date).toDate() : ''}
                            disabled={from_date ? false : true}
                            filterDate={isWeekday}
                        />
                    </div>
                    <div className='inputs-mgTop'>
                        <TextField id="standard-basic" label="Reason" variant="standard" name="reason" value={reason} onChange={handleInputAddNewHolidayReason} className="text-input-mgRight" InputLabelProps={{
                            sx: {
                                color: "#FCB713",
                                [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#FCB713"
                                }
                            }
                        }} InputLabelProps={{ shrink: shrink3 }} />
                    </div>
                    <div className="adding-all-holiday-container">
                        <img src={addHolidayIcon} alt="addHolidayIcon" onClick={addHolidayOnClick} />
                    </div>
                </div>
                :
                <div className='add-holiday-div'>
                    <p onClick={addHolidayHandler}>+ Add Holiday</p>
                </div>
            }
            <div className='edit-dc-submit-btn-div'>
                <button>Submit</button>
            </div>
            <ToastContainer />
        </div>
    );
}
