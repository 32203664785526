import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './AddDc.css'
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loadSingleDc, upadteDc, loadAllCities, loadAllStates } from '../../redux/Actions';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Holiday from '../DC/Holiday'
import { loadGetDcHoliday } from '../../redux/Actions';
import { loadDcManagement } from '../../redux/Actions'
import mapIcon from '../../images/address.png'
import { getCookie } from '../utils/Cookie';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 190
        }
    }
};

const EditDc = () => {
    let user = JSON.parse(getCookie("user-info"))
    const history = useHistory()
    const dispatch = useDispatch()
    let { id } = useParams()


    const singleDc = useSelector(state => state.data.singleDc.DC)
    const allStates = useSelector(state => state.dcUsers.allStates.states)
    const allCities = useSelector(state => state.dcUsers.allCities.cities)
    const dcm = useSelector(state => state.dcUsers.dc.data)


    const [dc_number, setDc_number] = useState("")
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [dc_name, setDc_name] = useState("")
    const [total_berths, setTotal_berths] = useState("")
    const [dc_contact, setDc_contact] = useState("")
    const [contact_number, setContact_number] = useState("")
    const [working_hours_from, setWorking_hours_from] = useState("")
    const [working_hours_to, setWorking_hours_to] = useState("")
    const [pincode, setPincode] = useState("")
    const [status, setStatus] = useState("")
    const [email, setEmail] = useState('')
    const [show, setShow] = useState(false)
    const [numberError, setNumberError] = useState(false)
    const [address, setAddress] = useState()
    const [map_link, setMapLink] = useState()
    const [shrink, setShrink] = useState(true)
    const [pinError, setPinError] = useState(false)


    // const handleState = (e) => {
    //     setState(e.target.value)

    // }

    // const handleCity = (e) => {
    //     setCity(e.target.value)
    // }
    // const handleDcName = (e) => {
    //     setDc_name(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    // }
    // const handleTotalBirths = (e) => {
    //     setTotal_berths(e.target.value)
    // }
    // const handleDcContact = (e) => {
    //     setDc_contact(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    // }
    // const handleContactNumber = (e) => {
    //     setContact_number(e.target.value)
    //     if (e.target.value.length !== 10) {
    //         setNumberError(true)
    //     }
    //     else {
    //         setNumberError(false)
    //     }
    // }
    // const handleWorkingFrom = (e) => {
    //     setWorking_hours_from(e.target.value)
    // }

    useEffect(() => {
        {
            user?.role === "dc" ?
                dispatch(loadSingleDc(user?.vendor_dc_id))
                : dispatch(loadSingleDc(id))
        }
    }, [])
    // useEffect(() => {
    //     dispatch(loadDcManagement())
    //     dispatch(loadAllStates())
    // }, [])
    useEffect(() => {
        if(state){
            dispatch(loadAllCities(state))
        }
    }, [state])

    useEffect(() => {
        if (singleDc) {
            const singleDcName = singleDc?.dc_name
            const singleDcContactName = singleDc?.dc_contact
            const singlePhone = singleDc?.contact_number
            const singleBerths = singleDc?.total_berths
            const singleWorkingFrom = singleDc?.working_hours_from
            const singleWorkingTo = singleDc?.working_hours_to
            const singleState = singleDc?.state
            const singleCity = singleDc?.city
            const singleStatus = singleDc?.status
            const singlePincode = singleDc?.pincode
            const singleEmail = singleDc?.email
            const singleMapLink = singleDc?.map_link
            const singleDcNumber = singleDc?.dc_number
            const singleDcAddress = singleDc?.address

            setDc_name(singleDcName)
            setDc_number(singleDcNumber)
            setDc_contact(singleDcContactName)
            setContact_number(singlePhone)
            setTotal_berths(singleBerths)
            setWorking_hours_to(singleWorkingTo)
            setWorking_hours_from(singleWorkingFrom)
            setState(singleState)
            setCity(singleCity)
            setStatus(singleStatus)
            setPincode(singlePincode)
            setEmail(singleEmail)
            setMapLink(singleMapLink)
            setAddress(singleDcAddress)
        }
    }, [singleDc])
    
    const handlePincode = (e) => {
        setPincode(e.target.value)
        if (e.target.value.length != 6) {
            setPinError(true)
        }
        else {
            setPinError(false)
        }
    }
    // const handleWorkingTO = (e) => {
    //     setWorking_hours_to(e.target.value)
    // }
    const handleStatus = (e) => {
        setStatus(e.target.value)
    }


    const finalData = {
        status,
        // email,
        // pincode,
        // working_hours_to,
        // working_hours_from,
        // state,
        // city,
        address,
        total_berths,
        dc_contact,
        dc_name,
        contact_number
    }

    const handleUpdate = (e) => {
        toast.configure()
        e.preventDefault()
        if (!dc_name || !total_berths || !dc_contact || !contact_number) {
            toast.error("You can not update if any one of the field is empty", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        } else if (contact_number.length != 10) {
            toast.warning('Please add a valid contact number', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        // else if (pincode.length != 6) {
        //     toast.warning('Please add a valid pincode number', {
        //         position: "top-right",
        //         autoClose: 2000,
        //         hideProgressBar: true,
        //     });
        // }
        else {
            dispatch(upadteDc(finalData, singleDc?.dc_number))
            setTimeout(() => {
                // history.push("/dc")
            }, 2000)
        }
    }

    const getTime = (str) => {
        let a = str?.split(":")
        let r = ""
        const c = (k) => parseInt(k) < 10 ? "0" + k : k
        if (parseInt(a[0]) == 24 || parseInt(a[0]) == 0) {
            r = "12:" + a[1] + " AM"
        } else if (parseInt(a[0]) == 12) {
            r = "12:" + a[1] + " PM"
        } else if (parseInt(a[0]) > 12) {
            let b = parseInt(a[0]) - 12
            r = c(parseInt(a[0]) - 12) + ":" + a[1] + " PM"
        } else {
            r = a[0] + ":" + a[1] + " AM"
        }
        return r
    }



    return (
        <div className='addDc'>
            <form className="add-input" onSubmit={handleUpdate}>
                <div className='edit-dc-name-div'>
                    <h2>Edit - {dc_name}</h2>
                </div>

                <div className='row'>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="DC name" variant="standard" name="dc_name" value={dc_name} type="text" className='dc-textfield-width' />
                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="DC number" variant="standard" name="dc_number" value={dc_number} type="text" className='dc-textfield-width' />
                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Email" variant="standard" name="email" value={email} type="email" className='dc-textfield-width' />
                    </div>
                    <div className="col-md-4 dc-margin dc-address-map-icon-div">
                        <TextField id="standard-basic" label="DC address" variant="standard" name="address" value={address} type="text" className='dc-textfield-width' InputLabelProps={{ shrink: true }}
                            InputLabelProps={{ shrink: true }} />
                        <a href={map_link} target="_blank"><img src={mapIcon} alt="address" /></a>
                    </div>
                    <div className="col-md-4 dc-margin dc-address-map-icon-div">
                        <TextField id="standard-basic" label="DC map link" variant="standard" name="map_link" value={map_link} type="text" className='dc-textfield-width' InputLabelProps={{ shrink: true }}
                            InputLabelProps={{ shrink: true }} />
                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="DC contact Name" variant="standard" name="dc_contact" value={dc_contact} type="text" className='dc-textfield-width' />
                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="DC contact number" variant="standard" name="contact_number" value={contact_number} type="number"
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }} className='dc-textfield-width' />
                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Total berths" variant="standard" name="total_berths" value={total_berths} type="number" className='dc-textfield-width' />
                    </div>

                    {/* <FormControl variant="standard">
                        <InputLabel id="demo-simple-select-standard-label" className='dc-textfield-width'>Select Working hours from</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={working_hours_from}
                            name="status"
                            label="working hours from"
                            className='dc-textfield-width'
                        >
                            <MenuItem value="04:00:00">04:00am</MenuItem>
                            <MenuItem value="05:00:00">05:00am</MenuItem>
                            <MenuItem value="06:00:00">06:00am</MenuItem>
                            <MenuItem value="07:00:00">07:00am</MenuItem>
                            <MenuItem value="08:00:00">08:00am</MenuItem>
                            <MenuItem value="09:00:00">09:00am</MenuItem>
                            <MenuItem value="10:00:00">10:00am</MenuItem>
                            <MenuItem value="11:00:00">11:00am</MenuItem>
                            <MenuItem value="12:00:00">12:00pm</MenuItem>
                            <MenuItem value="13:00:00">01:00pm</MenuItem>
                            <MenuItem value="14:00:00">02:00pm</MenuItem>

                        </Select>
                    </FormControl>  */}
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Select working hours from" variant="standard" name="working_hours_from" value={getTime(working_hours_from)} type="text" className='dc-textfield-width' />
                    </div>

                    {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Select Working hours to</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={working_hours_to}
                            name="status"
                            label="working hours to"
                            className='dc-textfield-width'
                        >
                            <MenuItem value="09:00:00">09:00am</MenuItem>
                            <MenuItem value="10:00:00">10:00am</MenuItem>
                            <MenuItem value="11:00:00">11:00am</MenuItem>
                            <MenuItem value="12:00:00">12:00pm</MenuItem>
                            <MenuItem value="13:00:00">01:00pm</MenuItem>
                            <MenuItem value="14:00:00">02:00pm</MenuItem>
                            <MenuItem value="15:00:00">03:00pm</MenuItem>
                            <MenuItem value="16:00:00">04:00pm</MenuItem>
                            <MenuItem value="17:00:00">05:00pm</MenuItem>
                            <MenuItem value="18:00:00">06:00pm</MenuItem>
                            <MenuItem value="19:00:00">07:00pm</MenuItem>
                            <MenuItem value="20:00:00">08:00pm</MenuItem>
                            <MenuItem value="21:00:00">09:00pm</MenuItem>
                            <MenuItem value="22:00:00">10:00pm</MenuItem>
                            <MenuItem value="23:00:00">11:00pm</MenuItem>


                        </Select>
                        </FormControl> */}

                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Select working hours to" variant="standard" name="working_hours_to" value={getTime(working_hours_to)} type="text" className='dc-textfield-width' />

                    </div>
                    {/* <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Select State" variant="standard" name="state" value={state} type="text" className='dc-textfield-width' />
                    </div> */}


                    {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Select State</InputLabel>
                        {allStates && allStates.length ? <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={state}
                            MenuProps={MenuProps}
                            className='dc-textfield-width'
                            label="state"
                        >
                            {allStates.map((item) => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>


                            )) }
                        </Select>: ""}
                    </FormControl> */}
                    {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">Select City</InputLabel>
                        {allCities && allCities.length ? <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={city}
                            MenuProps={MenuProps}
                            className='dc-textfield-width'
                            label="city"
                        >
                            {allCities.map((item) => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>


                            )) }
                        </Select>: ""}
                    </FormControl>  */}

                    {/* <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Select City" variant="standard" name="city" value={city} type="text" className='dc-textfield-width' />

                    </div>
                    <div className="col-md-4 dc-margin">
                        <TextField id="standard-basic" label="Pincode" variant="standard" name="pincode" value={pincode} error={pinError} type="number" className='dc-textfield-width' onChange={handlePincode} onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                        }} />
                    </div> */}

                    <div className="col-md-4 dc-margin dc-margin-top">
                        <FormControl variant="standard" >
                            <InputLabel id="demo-simple-select-standard-label" className='dc-textfield-width'>Select Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                // id="demo-simple-select-standard"
                                value={status}
                                name="status"
                                onChange={handleStatus}
                                label="status"
                                className='dc-textfield-width'
                            >

                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="In Active">In Active </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>



                <div className='holidays-main-container'>
                    <Holiday />
                </div>



                {/* <div className='dc-flex'>
                    <Button className="button-dc-submit" variant="contained" color="primary" type="submit">Submit</Button>
                </div> */}
            </form>


            <ToastContainer />

        </div>
    )
}

export default EditDc