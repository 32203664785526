import axios from 'axios'
import * as types from './ActionTypes'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { Password } from '@mui/icons-material';
import { getCookie } from '../utils/Cookie';


export const getOrderNumbersForPdf = (payload) => ({
    type:types.GET_ORDER_NUMBERS_FOR_PDF,
    payload
})

export const resetOrderNumbersForPdf = (payload) => ({
    type:types.RESET_ORDER_NUMBERS_FOR_PDF,
    payload
})


export const getSingleOrderDetails = (payload) => ({
    type:types.GET_SINGLE_ORDER_DETAILS,
    payload
})

export const getNewOrderDetails = (payload) => ({
    type:types.GET_NEW_ORDER_DETAILS,
    payload
})

export const updateOrderNum = (data) => ({
    type: types.UPDATE_ORDER_NUM,
    payload: data
})

const getDc = (dc) => ({
    type: types.GET_DC,
    payload: dc,
})


const dcAdded = (error) => ({
    type: types.ADD_DC,
    payload: error

})
const addDcLoading = () => ({
    type: types.ADD_DC_LOADING,
})
const dcUpdated = () => ({
    type: types.UPDATE_DC
})

const getVendors = (vendor) => ({
    type: types.GET_VENDOR,
    payload: vendor,
})
const vendorAdded = (msg) => ({
    type: types.ADD_VENDOR,
    payload: msg,

})
const addVendorLoading = () => ({
    type: types.ADD_VENDOR_LOADING,

})
const getSingleVendor = (singleVendor) => ({
    type: types.GET_SINGLE_VENDOR,
    payload: singleVendor,

})
const VendorUpdated = () => ({
    type: types.UPDATE_VENDOR
})




const getComments = (comment) => ({
    type: types.ADD_COMMENTS,
    payload: comment
})



const createOrder = (error) => ({
    type: types.CREATE_ORDER,
    payload: error
})

const getAllVehicles = (vehicle) => ({
    type: types.GET_ALL_VEHICLES,
    payload: vehicle
})

const getAllLogistics = (logistics) => ({
    type: types.GET_ALL_LOGISTICS,
    payload: logistics
})

const getSingleDc = (user) => ({
    type: types.GET_SINGLE_DC,
    payload: user
})

const getOrderDetails = (user) => ({
    type: types.GET_ORDER_DETAILS,
    payload: user
})

const getOrderDetailsLoading = () => ({
    type: types.GET_ORDER_DETAILS_LOADING
})

const addDcDate = (slot) => ({
    type: types.ADD_DC_DATE,
    payload: slot
})

const addDcDateLoading = () => ({
    type: types.ADD_DC_DATE_LOADING
})

const getVendor = (vendor) => ({
    type: types.GET_VENDOR_BY_LOGIN,
    payload: vendor
})

const getOrderVendorId = (orderVendor) => ({
    type: types.GET_ORDER_VENDOR_ID,
    payload: orderVendor,
})



const getAllOrders = (orders) => ({
    type: types.GET_ALL_ORDERS,
    payload: orders
})
const getTodayOrders = (today) => ({
    type: types.GET_TODAY_ORDERS,
    payload: today
})

const getDashboardCards = (cards) => ({
    type: types.GET_DASHBOARD_CARDS,
    payload: cards
})
const getDashboardVendorOrders = (dvo) => ({
    type: types.GET_DASHBOARD_VENDOR_ORDER,
    payload: dvo
})
const getDashboardDcorOrders = (ddco) => ({
    type: types.GET_DASHBOARD_DC_ORDER,
    payload: ddco
})


const orderUpdated = (update) => ({
    type: types.UPDATE_ORDER,
    payload: update
})

const uploadVahanUpdate = (upload) => ({
    type: types.UPLOAD_VAHAN_UPDATE,
    payload: upload
})



const getVahanUpdate = (vahanUpdate) => ({
    type: types.GET_VAHAN_UPDATE,
    payload: vahanUpdate
})

const getDoorSlotsAvailable = (slotAvailable) => ({
    type: types.GET_DOOR_SLOTS_AVAILABLE,
    payload: slotAvailable
})
const getAllStates = (allStates) => ({
    type: types.GET_ALL_STATES,
    payload: allStates
})
const getAllCities = (allCities) => ({
    type: types.GET_ALL_CITIES,
    payload: allCities
})

const getDcSlotsAvailable = (dcSlots) => ({
    type: types.GET_DC_SLOTS_AVAILABLE,
    payload: dcSlots
})

const getOrderStatus = (status) => ({
    type: types.GET_ORDER_STATUS,
    payload: status
})


const unmountSingleDc = (dc) => ({
    type: types.RESET_SINGLE_DC,
    payload: dc
})

const unmountDcDate = (dcDate) => ({
    type: types.RESET_DC_DATE,
    payload: dcDate
})

const unmountGetOrderDetails = (order) => ({
    type: types.RESET_GET_ORDER_DETAILS,
    payload: order
})

const unmountGetAllOrders = (orders) => ({
    type: types.RESET_ORDER_LISTING,
    payload: orders
})


const vahanUploadUpdate = () => ({
    type: types.VAHAN_UPLOAD_UPDATE
})

const addLogistics = (newLogs) => ({
    type: types.ADD_LOGISTICS,
    payload: newLogs
})

const getVendorStatus = (status) => ({
    type: types.GET_VENDOR_STATUS,
    payload: status
})

const addInvoiceDetails = () => ({
    type: types.ADD_INVOICE_UPLOAD
})

const unmountaddLogistics = (newLogs) => ({
    type: types.ADD_LOGISTICS,
    payload: newLogs
})

const viewInvoice = (invoice) => ({
    type: types.VIEW_INVOICE,
    payload: invoice
})

const chage_dc_input_action = (dc_inputs) => ({
    type: types.CHANGE_DC_INPUTS,
    payload: dc_inputs
})

const addProfilePhoto = () => ({
    type: types.ADD_PROFILE_PHOTO,
})

const getProfileDetails = (profile) => ({
    type: types.GET_PROFILE_DETAILS,
    payload: profile
})


const recoveryEmail = (recoveryMail) => ({
    type: types.RECOVERY_EMAIL,
    payload: recoveryMail
})


const getResetPasswordLink = (password) => ({
    type: types.GET_RESET_PASSWORD_LINK,
    payload: password
})

const addNewPassword = () => ({
    type: types.ADD_NEW_PASSWORD
})


const editDriverDetails = (edit) => ({
    type: types.EDIT_DRIVER_DETAILS,
    payload: edit
})

const deleteDriver = (deleted) => ({
    type: types.DELETE_DRIVER,
    payload: deleted
})

const addDriver = (add) => ({
    type: types.ADD_DRIVER,
    payload: add
})

const getHoliday = (get) => ({
    type: types.GET_HOLIDAY,
    payload: get
})

const addHoliday = (add) => ({
    type: types.ADD_HOLIDAY
})

const trackingLink = (link) => ({
    type: types.TRACKING_LINK,
    payload: link
})

const getVehicleDetails = (data) => ({
    type: types.GET_VEHICLE_DETAILS,
    payload: data
})



export const addVahanDetails = (data) => {
    const format = {
        "dc_number": data.dc_selected,
        "on_date": data.date_of_dispatch
    }
    toast.configure();
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios.post(process.env.REACT_APP_BASEURL + '/api/vendor/vahanOder/new', data, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(createOrder(resp.data))
                // dispatch(loadInvoiceUpload(data.order_no,data.selectedFile))
                dispatch(loadDcDate(format))
                dispatch(loadOrderDetails(data.dc_selected, data.date_of_dispatch, data.door_no))
                toast.success('Order Created Successfully.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                if (!data.orderComment.user_message) {
                    return
                }
                else {
                    dispatch(addComments(data.orderComment))
                }
            })
            .catch(error => {
                if (error.response) {
                    dispatch(createOrder(error.response.data))
                    toast.error(`${error.response.data.message}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
            })
    }
}


export const addComments = (comment, dc, date, door) => {
    let user = JSON.parse(getCookie("user-info"))
    const format = {
        "dc_number": dc,
        "on_date": date
    }
    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/deliveryComment/new`, comment, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getComments(resp.data))
                // dispatch(loadDcDate(format))
                // dispatch(loadOrderDetails(dc, date, door))
                // dispatch(loadOrderDetails(dc,date,door))
                toast.success('Comment Added Successfully.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });

            })
            .catch(error => console.log(error))
    }
}




export const loadDcManagement = () => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        // toast.loading('Loading', {
        //     position: "top-right",
        //     autoClose: 1000,
        //     hideProgressBar: true,
        // });

        axios.get(`${process.env.REACT_APP_BASEURL}/api/All_dc`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDc(response.data))
            })
            .catch((error) => console.log(error))
    }
}


export const addDc = (dc) => {
    toast.configure();
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        dispatch(addDcLoading())

        axios.post(`${process.env.REACT_APP_BASEURL}/api/admin/dc/new`, dc, { headers: { "Authorization": `Bearer ${user.token}` } })

            .then((response) => {
                dispatch(dcAdded(response.data))
                dispatch(loadDcManagement())
                toast.success('DC Added Successfully.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });


            })
            .catch((error) => {
                if (error.response) {
                    console.log("inside catch if block", error.response)
                    dispatch(dcAdded(error.response))

                    toast.error(`${error.response.data.message}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
            })
    }
}
export const loadAllStates = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/get-states`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllStates(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadAllCities = (state) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/get-cities-by-state?state=${state}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllCities(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const upadteDc = (data, id) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.put(`${process.env.REACT_APP_BASEURL}/api/updateDC/${id}`, data, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('DC Updated Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                dispatch(dcUpdated())
            })
            .catch((error) => console.log(error))
    }
}

export const loadFilterDcManagement = (status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/All_dc?status=${status}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDc(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadFilterStateAndCityAndStatusDcManagement = (state, city, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/All_dc?state=${state}&city=${city}&status=${status}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDc(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStateAndCityDcManagement = (state, city) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/All_dc?state=${state}&city=${city}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDc(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStateAndStatusDcManagement = (state, status) => {
    let user = JSON.parse(getCookie("user-info"))


    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/All_dc?state=${state}&status=${status}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDc(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadFilterStateDcManagement = (state) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/All_dc?state=${state}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDc(response.data))
            })
            .catch((error) => console.log(error))
    }
}





export const loadVendorManagement = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vendors`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getVendors(response.data))
            })
            .catch((error) => console.log(error))
    }
}



export const addVendor = (vendor) => {
    let user = JSON.parse(getCookie("user-info"))
    toast.configure()
    return function (dispatch) {
        dispatch(addVendorLoading())

        axios.post(`${process.env.REACT_APP_BASEURL}/api/admin/vendor/new`, vendor, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {

                dispatch(vendorAdded(response.data))
                dispatch(loadVendorManagement())
                toast.success('Vendor Added Successfully.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });


            })
            .catch((error) => {
                if (error.response) {
                    console.log("inside catch if block", error.response)
                    dispatch(vendorAdded(error.response))
                    toast.error(`${error.response.data.message}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
            })
    }
}

export const loadSingleVendorManagement = (id) => {
    let user = JSON.parse(getCookie("user-info"))


    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/getVendor/${id}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getSingleVendor(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const upadteVendor = (data, id) => {
    let user = JSON.parse(getCookie("user-info"))
    toast.configure()
    return function (dispatch) {
        axios.put(`${process.env.REACT_APP_BASEURL}/api/updateVendor/${id}`, data, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('Vendor Updated Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                dispatch(VendorUpdated())

            })
            .catch((error) => {
                console.log(error)
            })
    }
}



export const loadFilterStateAndCityVendorManagement = (state, city) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vendors?state=${state}&city=${city}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getVendors(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadFilterVendorManagement = (status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vendors?status=${status}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getVendors(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStateVendorManagement = (state) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vendors?state=${state}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getVendors(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStateAndStatusVendorManagement = (state, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vendors?state=${state}&status=${status}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getVendors(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStateAndCityAndStatusVendorManagement = (state, city, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vendors?state=${state}&city=${city}&status=${status}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getVendors(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadVendorManagementPdf = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadPDF`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}

export const loadVendorManagementPdfFilterState = (state) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadPDF?state=${state}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementPdfFilterStateAndCity = (state, city) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadPDF?state=${state}&city=${city}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementPdfFilterStateAndStatus = (state, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadPDF?state=${state}&status=${status}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementPdfFilterStateAndCityAndStatus = (state, city, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadPDF?state=${state}&city=${city}&status=${status}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementPdfFilterStatus = (status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadPDF?status=${status}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementExcel = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadExcel`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`)
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementExcelFilterState = (state) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadExcel?state=${state}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);
            //link.setAttribute('download', 'file.exe')
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementExcelFilterStateAndCity = (state, city) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadExcel?state=${state}&city=${city}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementExcelFilterStateAndStatus = (state, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadExcel?state=${state}&status=${status}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementExcelFilterStateAndCityAndStatus = (state, city, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadExcel?state=${state}&city=${city}&status=${status}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadVendorManagementExcelFilterStatus = (status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vendor/downloadExcel?status=${status}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);
            //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}

export const loadDcManagementPdf = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadPDF`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementPdfFilterState = (state) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadPDF?state=${state}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementPdfFilterStateAndCity = (state, city) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadPDF?state=${state}&city=${city}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementPdfFilterStateAndStatus = (state, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadPDF?state=${state}&status=${status}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementPdfFilterStateAndCityAndStatus = (state, city, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadPDF?state=${state}&city=${city}&status=${status}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementPdfFilterStatus = (status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadPDF?status=${status}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadWorkingDcPdf = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadPDF?status=Active`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementExcel = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadExcel`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` } // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`)

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementExcelFilterState = (state) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadExcel?state=${state}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementExcelFilterStateAndCity = (state, city) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadExcel?state=${state}&city=${city}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementExcelFilterStateAndStatus = (state, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadExcel?state=${state}&status=${status}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementExcelFilterStateAndCityAndStatus = (state, city, status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadExcel?state=${state}&city=${city}&status=${status}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadDcManagementExcelFilterStatus = (status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadExcel?status=${status}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadWorkingDcExcel = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadExcel?status=Active`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}








export const loadVehicles = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vehicles`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getAllVehicles(resp.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadLogistics = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/logistics`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getAllLogistics(resp.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadSingleDc = (dc_number) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/getDC/${dc_number}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getSingleDc(resp.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadOrderDetails = (dc_number, date, door_no) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        dispatch(getOrderDetailsLoading())
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vendor/vahanOder?dc=${dc_number}&date=${date}&door_no=${door_no}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getOrderDetails(resp.data))
                // dispatch(loadProfileDetails())
            })
            .catch((error) => {
                dispatch(getOrderDetails({ "success": false, "message": "No order found" }))
            })
    }
}

export const loadDcDate = (slot) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        dispatch(addDcDateLoading())
        axios.post(`${process.env.REACT_APP_BASEURL}/api/dcDoors`, slot, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(addDcDate(resp.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadVendor = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vendor/me`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getVendor(resp.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadOrderVendorId = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/getorderandinvoice`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getOrderVendorId(resp.data))
            })
            .catch((error) => console.log(error))
    }
}


export const loadOrderListing = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadOrderListingPdf = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatus = (status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterWheel = (wheel) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vehicle_type=${wheel}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterVendor = (ven) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vendor_id=${ven}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterDc = (dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStartDate = (startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndVendor = (status, ven) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vendor_id=${ven}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndWheel = (status, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vehicle_type=${wheel}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndDc = (status, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndStartandEndDate = (status, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterVendorAndStartandEndDate = (ven, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vendor_id=${ven}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterWheelAndStartandEndDate = (wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterDcAndStartandEndDate = (dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndVendorAndStartandEndDate = (status, ven, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vendor_id=${ven}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndWheelAndStartandEndDate = (status, wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndDcAndStartandEndDate = (status, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterVendorAndWheelAndStartandEndDate = (ven, wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vendor_id=${ven}&vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterVendorAndDcAndStartandEndDate = (ven, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vendor_id=${ven}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterWheelAndDcAndStartandEndDate = (wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndVendorAndWheelAndStartandEndDate = (status, ven, wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndVendorAndDcAndStartandEndDate = (status, ven, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vendor_id=${ven}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndWheelAndDcAndStartandEndDate = (status, wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterVendorAndWheelAndDcAndStartandEndDate = (ven, wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndVendorAndWheelAndDcAndStartandEndDate = (status, ven, wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}


export const loadOrderListingDownloadSelectedOrdersPdf = (orderNumbers) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?order_num_array=${orderNumbers}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);
            document.body.appendChild(link);
            link.click();
            // dispatch(getOrderNumbersForPdf([]))
        }).catch(e => console.log(e))
    }
}


export const loadOrderListingDownloadSelectedOrdersExcel = (orderNumbers) => {
    let user = JSON.parse(getCookie("user-info"))


    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?order_num_array=${orderNumbers}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
            // dispatch(getOrderNumbersForPdf([]))
        }).catch(e => console.log(e))
    }
}





// export const loadDcManagementDownloadSelectedOrdersPdf = (orderNumbers) => {
//     let user = JSON.parse(getCookie("user-info"))
//     console.log("orderNumbersSSSSSSSSSS",orderNumbers)

//     return function (dispatch) {
//         axios({
//             url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadPDF?order_num_array=${orderNumbers}`, //your url
//             method: 'GET',
//             responseType: 'blob', // important
//             headers: { "Authorization": `Bearer ${user.token}` }
//         }).then((response) => {
//             const url = window.URL.createObjectURL(new Blob([response.data]));
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);
//             document.body.appendChild(link);
//             link.click();
//             dispatch(getOrderNumbersForPdf([]))
//         }).catch(e => console.log(e))
//     }
// }


// export const loadDcManagementDownloadSelectedOrdersExcel = (orderNumbers) => {
//     let user = JSON.parse(getCookie("user-info"))
//     console.log("orderNumbersssssssssss",orderNumbers)


//     return function (dispatch) {
//         axios({
//             url: `${process.env.REACT_APP_BASEURL}/api/dc/downloadExcel?order_num_array=${orderNumbers}`, //your url
//             method: 'GET',
//             responseType: 'blob', // important
//             headers: { "Authorization": `Bearer ${user.token}` }
//         }).then((response) => {
//             const url = window.URL.createObjectURL(new Blob([response.data]));
//             const link = document.createElement('a');
//             link.href = url;
//             link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

//             document.body.appendChild(link);
//             link.click();
//             dispatch(getOrderNumbersForPdf([]))
//         }).catch(e => console.log(e))
//     }
// }


















export const loadOrderListingExcelFilterStatusAndStartandEndDate = (status, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}

export const loadOrderListingExcelFilterVendorAndStartandEndDate = (ven, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vendor_id=${ven}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterWheelAndStartandEndDate = (wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterDcAndStartandEndDate = (dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndVendorAndStartandEndDate = (status, ven, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vendor_id=${ven}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndWheelAndStartandEndDate = (status, wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndDcAndStartandEndDate = (status, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterVendorAndWheelAndStartandEndDate = (ven, wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vendor_id=${ven}&vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterVendorAndDcAndStartandEndDate = (ven, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vendor_id=${ven}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterWheelAndDcAndStartandEndDate = (wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndVendorAndWheelAndStartandEndDate = (status, ven, wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndVendorAndDcAndStartandEndDate = (status, ven, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vendor_id=${ven}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndWheelAndDcAndStartandEndDate = (status, wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterVendorAndWheelAndDcAndStartandEndDate = (ven, wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndVendorAndWheelAndDcAndStartandEndDate = (status, ven, wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}















export const loadOrderListingPdfFilterVendorAndWheel = (ven, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vendor_id=${ven}&vehicle_type=${wheel}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterVendorAndDc = (ven, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vendor_id=${ven}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterWheelAndDc = (wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vehicle_type=${wheel}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndVendorAndWheel = (status, ven, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndVendorAndDc = (status, ven, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vendor_id=${ven}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterVendorAndWheelAndDc = (ven, wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndWheelAndDc = (status, wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vehicle_type=${wheel}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingPdfFilterStatusAndVendorAndWheelAndDc = (status, ven, wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadPDF?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}

export const loadOrderListingExcel = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`)
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatus = (status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterWheel = (wheel) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vehicle_type=${wheel}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterVendor = (ven) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vendor_id=${ven}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterDc = (dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStartDate = (startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?start_date=${startDate}&end_date=${endDate}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndVendor = (status, ven) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vendor_id=${ven}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndWheel = (status, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vehicle_type=${wheel}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndDc = (status, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterVendorAndWheel = (ven, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vendor_id=${ven}&vehicle_type=${wheel}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterVendorAndDc = (ven, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vendor_id=${ven}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterWheelAndDc = (wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vehicle_type=${wheel}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndVendorAndWheel = (status, ven, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndVendorAndDc = (status, ven, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vendor_id=${ven}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterVendorAndWheelAndDc = (ven, wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndWheelAndDc = (status, wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vehicle_type=${wheel}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}
export const loadOrderListingExcelFilterStatusAndVendorAndWheelAndDc = (status, ven, wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadExcel?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}`, //your url
            method: 'GET',
            responseType: 'blob', // important
            headers: { "Authorization": `Bearer ${user.token}` }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.xlsx`);

            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}


export const loadFilterStatusOrder = (status) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterWheelerOrder = (wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vehicle_type=${wheel}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadFilterVendorOrder = (ven) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vendor_id=${ven}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterDcOrder = (dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?dc_selected=${dcNumber}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}



export const loadFilterStartDateAndEndDateOrder = (startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}


export const loadFilterStatusAndStartDateAndEndDateOrder = (status, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterVendorAndStartDateAndEndDateOrder = (ven, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vendor_id=${ven}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterWheelAndStartDateAndEndDateOrder = (wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterDcAndStartDateAndEndDateOrder = (dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndVendorAndStartDateAndEndDateOrder = (status, ven, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vendor_id=${ven}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndWheelAndStartDateAndEndDateOrder = (status, wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndDcAndStartDateAndEndDateOrder = (status, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterVendorAndWheelAndStartDateAndEndDateOrder = (ven, wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vendor_id=${ven}&vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterVendorAndDcAndStartDateAndEndDateOrder = (ven, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vendor_id=${ven}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterWheelAndDcAndStartDateAndEndDateOrder = (wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndVendorAndWheelAndStartDateAndEndDateOrder = (status, ven, wheel, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndVendorAndDcAndStartDateAndEndDateOrder = (status, ven, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vendor_id=${ven}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterVendorAndWheelAndDcAndStartDateAndEndDateOrder = (ven, wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndWheelAndDcAndStartDateAndEndDateOrder = (status, wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndVendorAndWheelAndDcAndStartDateAndEndDateOrder = (status, ven, wheel, dcNumber, startDate, endDate) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}&start_date=${startDate}&end_date=${endDate}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadFilterStatusAndWheelerOrder = (status, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vehicle_type=${wheel}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndVendorOrder = (status, ven) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vendor_id=${ven}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndDcOrder = (status, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&dc_selected=${dcNumber}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterVendorAndDcOrder = (ven, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vendor_id=${ven}&dc_selected=${dcNumber}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterVendorAndWheelOrder = (ven, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vendor_id=${ven}&vehicle_type=${wheel}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterDcAndWheelOrder = (dcNumber, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vehicle_type=${wheel}&dc_selected=${dcNumber}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndDcAndVendorOrder = (status, ven, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vendor_id=${ven}&dc_selected=${dcNumber}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndVendorAndWheelOrder = (status, ven, wheel) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterVendorAndDcAndWheelerOrder = (ven, wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndDcAndWheelerOrder = (status, wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vehicle_type=${wheel}&dc_selected=${dcNumber}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadFilterStatusAndVendorAndDcAndWheelerOrder = (status, ven, wheel, dcNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?vahan_status=${status}&vendor_id=${ven}&vehicle_type=${wheel}&dc_selected=${dcNumber}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadTodayOrders = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?orders_filter=today`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getTodayOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadWeeklyOrders = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders?orders_filter=tommorow`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getTodayOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadWorkingDc = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/All_dc?status=Active`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDc(response.data))
            })
            .catch((error) => console.log(error))
    }
}



export const loadDashboardCards = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/dashboard/cards`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDashboardCards(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadDashboardVendorOrders = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/dashboard/order-by-vendors`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDashboardVendorOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}
export const loadDashboardDcOrders = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/dashboard/order-by-dc`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getDashboardDcorOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const updateOrder = (data, order_no, dc, date, door) => {
    const format = {
        "dc_number": dc,
        "on_date": date
    }
    toast.configure();
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch, getState) {
        axios.put(process.env.REACT_APP_BASEURL + `/api/vendor/vahanOder/${order_no}`, data, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                toast.success('Updated Successfully.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                dispatch(orderUpdated(resp.data))
                dispatch(loadDcDate(format))
                // dispatch(loadOrderDetails(data.newdc, data.delivery_date, data.door_no))
                dispatch(loadOrderDetails(dc, date, door))
                dispatch(change_dc_inputs({ dcNumber: data.newdc, date: data.delivery_date, door_no: data.door_no }))
                dispatch(loadOrderStatus(order_no))
                setTimeout(() => {
                    document.getElementById(getState().data.getUpdatedOrderNum)?.click()
                }, 1000);
            })
            .catch((error) => {
                // dispatch(orderUpdated({
                //     "success": false,
                //     "message": "No slot available for selected date here"
                // }))
                if (error.response) {
                    toast.error(`${error.response.data.message}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }

            })
    }
}


export const addVahanUpdate = (upload) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/vahanUpdate`, upload, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(uploadVahanUpdate(resp.data))
            })
            .catch(error => console.log(error))
    }
}


export const loadVahanUpdate = (orderNo) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanUpdate/${orderNo}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getVahanUpdate(resp.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadDoorSlots = (dc_number, date, door_no, order_no) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        const a = order_no ? `order_no=${order_no}` : ''
        axios.get(`${process.env.REACT_APP_BASEURL}/api/dcDoor?delivery_date=${date}&door=${door_no}&dc=${dc_number}&${a}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getDoorSlotsAvailable(resp.data))
            })
            .catch((error) => {
                dispatch(getDoorSlotsAvailable({ "success": false, "message": "No orders booked Be the first to order" }))
            })
    }
}

export const loadDcSlotsAvailable = (date, dc_number) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/dcMultiDoor?delivery_date=${date}&dc=${dc_number}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getDcSlotsAvailable(resp.data))
            })
            .catch((error) => {
                dispatch(getDcSlotsAvailable({ "success": false, "message": "No order found" }))
            })
    }
}

export const loadOrderStatus = (order_no) => {
    console.log("order_no",order_no)
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrder/getStatusForUpdate/${order_no}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getOrderStatus(resp.data))
            })
            .catch((error) => console.log("error"))
    }
}


export const loadUnmountSingleDc = (dc_number) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/getDC/${dc_number}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(unmountSingleDc(resp.data))
            })
            .catch((error) => console.log(error))
    }
}


export const loadUnmountDcDate = (slot) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/dcDoors`, slot, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(unmountDcDate(resp.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadUnmountOrderDetails = (dc_number, date, door_no) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vendor/vahanOder?dc=${dc_number}&date=${date}&door_no=${door_no}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(unmountGetOrderDetails(resp.data))
            })
            .catch((error) => {
                dispatch(unmountGetOrderDetails({ "success": false, "message": "No order found" }))
            })
    }
}


export const loadUnmountOrderListing = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/vahanOrders`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(unmountGetAllOrders(response.data))
            })
            .catch((error) => console.log(error))
    }
}



export const loadVahanUploadArrivalStatusUpdate = (data, order_no, dcNumber, deliveryDate, doorNumber) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.put(`${process.env.REACT_APP_BASEURL}/api//vahanOrder/update-route-track/${order_no}`, data, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                if (response) {
                    toast.success('Updated Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                dispatch(vahanUploadUpdate())
                dispatch(loadVahanUpdate(order_no))
                // dispatch(loadOrderDetails(dcNumber,deliveryDate,doorNumber))
            })
            .catch((error) => {
                toast.warning(`${error.response.data.message}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
            })
    }
}


export const loadLogisticsAdding = (logistics) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/logistics/new`, logistics, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                if (resp) {
                    toast.success('Logistics Added Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                dispatch(addLogistics(resp.data))
                dispatch(loadLogistics())
            })
            .catch(error => {
                if (error.response) {
                    dispatch(addLogistics(error.response))
                    toast.error("Given Logistics already exists", {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                    })
                }
            })
    }
}


export const loadVendorStatus = () => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/auth/get-my-status`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getVendorStatus(resp.data))
            })
            .catch(error => console.log(error))
    }
}

export const loadInvoiceUpload = (order_no, upload, data) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/vahanOrderInvoice/${order_no}`, upload, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(addInvoiceDetails(resp.data))
                dispatch(loadOrderDetails(data.dc_selected, data.date_of_dispatch, data.door_no))
            })
            .catch(error => console.log(error))
    }
}


export const loadDownloadInvoiceUpload = (order_no) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadInvoice/${order_no}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}


export const loadUnmountLogistics = (logistics) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/logistics/new`, logistics, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                if (resp) {
                    toast.success('Logistics Added Successfully.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                dispatch(unmountaddLogistics(resp.data))
            })
            .catch(error => console.log(error))
    }
}


export const loadViewInvoice = (order_no) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios({
            url: `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadInvoice/${order_no}`, //your url
            method: 'GET',
            responseType: 'blob',
            headers: { "Authorization": `Bearer ${user.token}` }  // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${user.name}_${moment().format("DD MM YYYY")}.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(e => console.log(e))
    }
}


export const change_dc_inputs = (dc_inputs) => {
    return function (dispatch) {
        dispatch(chage_dc_input_action(dc_inputs))
    }
}


export const profilePhotoAdded = (profilePhoto) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/user/change-profile-pic`, profilePhoto, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then(resp => {
                dispatch(addProfilePhoto(resp.data))
                dispatch(loadProfileDetails())
            })
            .catch(error => console.log("checking photo", error))
    }
}


export const loadProfileDetails = () => {
    let user = JSON.parse(getCookie("user-info"))
    console.log("getCookie",user)
    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/user/myprofile`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then(resp => {
                dispatch(getProfileDetails(resp.data))
            })
            .catch(error => console.log("checking errors in load profile details", error))
    }
}



export const addRecoveryEmail = (email) => {
    // let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/auth/reset-password`, email)
            .then(resp => {
                dispatch(recoveryEmail(resp.data))
                toast.success('Password Recovery Email Sent', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                })
            })
            .catch(error => {
                if (error.response) {
                    dispatch(recoveryEmail(error.response))
                    toast.error("Invalid email address!", {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                    })
                }
            }
            )
    }
}

export const loadResetPasswordLink = (email, email_token) => {
    // const user=JSON.parse(localStorage.getItem('user-info'))

    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/auth/newpassword/${email}/${email_token}`)
            .then(resp => {
                dispatch(getResetPasswordLink(resp.data))
            })
            .catch(error => console.log(error))
    }
}



export const createNewPassword = (newpassword) => {
    // let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/auth/add-new-password`, newpassword)
            .then(resp => {
                dispatch(addNewPassword(resp.data))
            })
            .catch(error => console.log(error))
    }
}


export const updateDriverDetails = (driver_Details, orderNum, driver_id, dcNumber, date, door) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch, getState) {
        axios.put(`${process.env.REACT_APP_BASEURL}/api/update-driver/${orderNum}/${driver_id}`, driver_Details, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(editDriverDetails(response.data))
                // dispatch(loadOrderDetails(dcNumber, date, door))
                toast.success('Driver edited Successfully.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                setTimeout(() => {
                    document.getElementById(getState().data.getUpdatedOrderNum)?.click()
                    console.log("document.getElementById(getState().data.getUpdatedOrderNum)", document.getElementById(getState().data.getUpdatedOrderNum))
                }, 1000);
            })
            .catch((error) => console.log(error))
    }
}


export const deleteDriverDetails = (orderNum, driver_id, dcNumber, date, door) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch, getState) {
        const items = getState().data.getUpdatedOrderNum

        axios.delete(`${process.env.REACT_APP_BASEURL}/api/delete-driver/${orderNum}/${driver_id}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(deleteDriver(response.data))
                // dispatch(loadOrderDetails(dcNumber, date, door))
                toast.success('Driver deleted successfully.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                setTimeout(() => {
                    document.getElementById(getState().data.getUpdatedOrderNum)?.click()
                }, 1000);
            })
            .catch((error) => console.log(error))
    }
}

export const addDriverDetails = (orderNum, driver_Details, dcNumber, date, door) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch, getState) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/add-driver/${orderNum}`, driver_Details, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then(resp => {
                dispatch(addDriver(resp.data))
                toast.success('Driver added successfully', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                setTimeout(() => {
                    document.getElementById(getState().data.getUpdatedOrderNum)?.click()
                }, 1000);
            })
            .catch(error => console.log(error))
    }
}


export const loadGetDcHoliday = (dc_number) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/holiday/all?dc=${dc_number}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((response) => {
                dispatch(getHoliday(response.data))
            })
            .catch((error) => console.log(error))
    }
}

export const loadAddDcHoliday = (newHoliday, number) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.post(`${process.env.REACT_APP_BASEURL}/api/holiday/add`, newHoliday, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(addHoliday(resp.data))
                dispatch(loadGetDcHoliday(number))
            })
            .catch((error) => console.log(error))
    }
}


export const addTrackingLink = (orderNo, link, dcNumber, date, door) => {
    let user = JSON.parse(getCookie("user-info"))

    return function (dispatch) {
        axios.put(`${process.env.REACT_APP_BASEURL}/api/vahanOrder/linkupdate/${orderNo}`, link, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(trackingLink(resp.data))
                dispatch(loadOrderDetails(dcNumber, date, door))
            })
            .catch((error) => console.log(error))
    }

}


export const loadVehicelDetails = (logisticNumber) => {
    let user = JSON.parse(getCookie("user-info"))
    return function (dispatch) {
        axios.get(`${process.env.REACT_APP_BASEURL}/api/logistics-vehicles/${logisticNumber}`, { headers: { "Authorization": `Bearer ${user.token}` } })
            .then((resp) => {
                dispatch(getVehicleDetails(resp.data))
            })
            .catch((error) => console.log(error))
    }

}


<ToastContainer />
