import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import './NewPassword.css'
import { useParams } from 'react-router-dom'
import { loadResetPasswordLink } from '../../redux/Actions'
import { useDispatch, useSelector } from 'react-redux'
import { createNewPassword } from '../../redux/Actions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom'
import logo from "../../.././src/images/Group.png"
import { EyeOutlined } from '@ant-design/icons'



const NewPassword = () => {
    let dispatch = useDispatch()
    let history = useHistory()
    const password = useSelector(state => state.data.password)

    let { email, token } = useParams()
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setNewConfirmPassword] = useState('')


    useEffect(() => {
        dispatch(loadResetPasswordLink(email, token))
    }, [])



    const settingPasswords = {
        email: email,
        reset_token: password?.reset_session_link,
        new_password: confirmPassword
    }


    const submitPassword = () => {
        toast.configure()
        if (!newPassword || !confirmPassword) {
            toast.error('Please enter all the input fields', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
            })
        }
        else if (confirmPassword !== newPassword) {
            toast.error('Please enter correct password', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
            })
        }
        else {
            dispatch(createNewPassword(settingPasswords))
            toast.success('Password reset successfull!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
            })
            history.push("/")
        }
    }

    const newPasswordHandler = (e) => {
        setNewPassword(e.target.value)
    }

    const confirmPasswordHandler = (e) => {
        setNewConfirmPassword(e.target.value)
    }

    const eyeOutlinedClick = () => {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    const eyeOutlinedClick1 = () => {
        var x = document.getElementById("myInput1");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    return (
        <div className='all-fields-password-container'>
            <div className='apollo-logos'>
                <div className="brand-logo-new-password">
                    <img src={logo} alt="apolloBrand" />
                </div>
                <div className="loginName-new-password">
                    <h1>V<span>A</span>H<span>A</span>N</h1>
                </div>
            </div>
            <div className='email-reset-password-main-div'>
                <div className='reset-password'>
                    <h3>RESET PASSWORD</h3>
                </div>
                <div className='name-inputs-div'>
                    <p>New Password</p>
                    <Input placeholder="Enter New Password" type="password" id="myInput" className='new-passowrd-inputs' onChange={newPasswordHandler} />
                    <span className='eye-icon'><EyeOutlined onClick={eyeOutlinedClick} /></span>
                </div>
                <div className='name-inputs-div'>
                    <p>Confirm Password</p>
                    <Input  type="password" placeholder="Enter Confirm Password" id="myInput1" className='new-passowrd-inputs' onChange={confirmPasswordHandler} />
                    <span className='eye-icon'><EyeOutlined onClick={eyeOutlinedClick1} /></span>
                </div>
                <div className='name-inputs-div'>
                    <button onClick={submitPassword}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default NewPassword