import React, { useState, useEffect } from 'react'
import './Vendor.css'
import { Input } from 'antd';
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import search from '../../images/search 1.png'
import TableContainer from "@mui/material/TableContainer";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Dropdown from 'react-bootstrap/Dropdown'

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from '@mui/material/TableHead';
import { useSelector, useDispatch } from 'react-redux';
import { loadVendorManagement, loadVendorManagementPdf, loadFilterVendorManagement, loadFilterStateAndCityVendorManagement, loadVendorManagementExcel, loadVendorManagementPdfFilterState, loadVendorManagementPdfFilterCity, loadVendorManagementPdfFilterStatus, loadVendorManagementExcelFilterState, loadVendorManagementExcelFilterStatus, loadAllCities, loadAllStates, loadFilterStateAndCityAndStatusVendorManagement, loadFilterStateVendorManagement, loadVendorManagementExcelFilterStateAndCity, loadVendorManagementPdfFilterStateAndCity, loadVendorManagementPdfFilterStateAndCityAndStatus, loadVendorManagementExcelFilterStateAndCityAndStatus, loadFilterStateAndStatusVendorManagement, loadVendorManagementPdfFilterStateAndStatus, loadVendorManagementExcelFilterStateAndStatus } from '../../redux/Actions';
import { useHistory } from 'react-router';
import moment from 'moment';
import filter from '../../images/filter.png'
import downloadIcon from '../../images/Download.png'
import newSlot from '../../images/newSlot.png'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import viewIcon from '../../images/IconLeft.png'
import editIcon from '../../images/ci_edit.png'
import { getCookie } from '../utils/Cookie';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 190
        }
    }
};


const Vendor = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const vendor = useSelector(state => state.vendorUsers.vendor.data)

    const allStates = useSelector(state => state.dcUsers.allStates.states)
    const allCities = useSelector(state => state.dcUsers.allCities.cities)
    const pdfVendor = useSelector(state => state.vendorUsers.pdf)
    const [searchTerm, setSearchTerm] = useState("")
    const [searchResults, setSearchResults] = useState({})
    const [status, setStatus] = useState("")
    const [cityFilter, setCityFilter] = useState("")
    const [stateFilter, setStateFilter] = useState("")



    useEffect(() => {
        dispatch(loadAllStates())
        // dispatch(loadVendorManagement())
    }, [])
    useEffect(() => {
        if(stateFilter){
            dispatch(loadAllCities(stateFilter))
        }
    }, [stateFilter])




    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired
    };


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vendor.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchItems = (searchValue) => {
        setSearchTerm(searchValue)
        if (searchTerm !== "") {
            const newVendorList = vendor.filter((vendor) => {
                return (Object.values(vendor)?.join(" ")?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
            })
            setSearchResults(newVendorList)

        } else {
            setSearchResults(vendor)
        }

    }

    const handleFilterChange = (e) => {
        setStatus(e.target.value)
    }
    const handleCityFilter = (e) => {
        setCityFilter(e.target.value)

    }
    const handleStateFilter = (e) => {
        setStateFilter(e.target.value)
        setCityFilter("")

    }

    useEffect(() => {
        if (stateFilter.length > 1 && cityFilter.length > 1 & status.length > 1) {
            dispatch(loadFilterStateAndCityAndStatusVendorManagement(stateFilter, cityFilter, status))
        }
        else if (stateFilter.length > 1 && cityFilter.length > 1) {
            dispatch(loadFilterStateAndCityVendorManagement(stateFilter, cityFilter))

        }
        else if (stateFilter.length > 1 && status.length > 1) {
            dispatch(loadFilterStateAndStatusVendorManagement(stateFilter, status))
        }
        else if (status.length > 1) {
            dispatch(loadFilterVendorManagement(status))
        }
        else if (stateFilter.length > 1) {
            dispatch(loadFilterStateVendorManagement(stateFilter))
        }
        else {
            dispatch(loadVendorManagement())
        }
    }, [stateFilter, cityFilter, status])


    const currentPosts = vendor && vendor ? vendor.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : ""
    const downloadPdf = () => {
        if (stateFilter.length > 1 & cityFilter.length > 1 & status.length > 1) {
            dispatch(loadVendorManagementPdfFilterStateAndCityAndStatus(stateFilter, cityFilter, status))
        }
        else if (stateFilter.length > 1 & cityFilter.length > 1) {
            dispatch(loadVendorManagementPdfFilterStateAndCity(stateFilter, cityFilter))
        }
        else if (stateFilter.length > 1 && status.length > 1) {
            dispatch(loadVendorManagementPdfFilterStateAndStatus(stateFilter, status))
        }
        else if (stateFilter.length > 1) {
            dispatch(loadVendorManagementPdfFilterState(stateFilter))

        }
        else if (status.length > 1) {
            dispatch(loadVendorManagementPdfFilterStatus(status))
        }
        else {
            dispatch(loadVendorManagementPdf())
        }

    }
    const downloadExcel = () => {
        if (stateFilter.length > 1 & cityFilter.length > 1 & status.length > 1) {
            dispatch(loadVendorManagementExcelFilterStateAndCityAndStatus(stateFilter, cityFilter, status))
        }
        else if (stateFilter.length > 1 & cityFilter.length > 1) {
            dispatch(loadVendorManagementExcelFilterStateAndCity(stateFilter, cityFilter))
        }
        else if (stateFilter.length > 1 && status.length > 1) {
            dispatch(loadVendorManagementExcelFilterStateAndStatus(stateFilter, status))
        }
        else if (stateFilter.length > 1) {
            dispatch(loadVendorManagementExcelFilterState(stateFilter))

        }
        else if (status.length > 1) {
            dispatch(loadVendorManagementExcelFilterStatus(status))
        } else {
            dispatch(loadVendorManagementExcel())

        }
    }
    const removeFilter = () => {
        setStateFilter("")
        setStatus("")
        setCityFilter("")



    }

    let user = JSON.parse(getCookie("user-info"))
    function add3Dots(string, limit) {
        var dots = "...";
        var limit = 10
        if (string ? string.length > limit : "") {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }
    return (
        <div className="dc-container">
            <div className="filter-section">
                <div className="filter-top">
                    <h6><span><img src={filter} alt="filter" className="filter-icon" /></span>Filter By</h6>
                    {stateFilter || cityFilter || status ? <p onClick={removeFilter}>Clear</p> : <p></p>}

                </div>
                <hr className="horizontal" />
                <div className="row filter-dropdown">
                    <div className="align-items-center state-dropdown">

                        <div className="col-sm-3 d-flex justify-content-start">
                            <FormControl size="small" sx={{ mt: 1 }} className="dc-drop">
                                <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                                <Select
                                    
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={stateFilter}
                                    MenuProps={MenuProps}
                                    label="State"
                                    onChange={handleStateFilter}
                                >
                                    {allStates && allStates.length ? allStates.map((item) => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>


                                    )) : ""}

                                </Select>

                            </FormControl>
                        </div>



                        {stateFilter && allCities && Array.isArray(allCities) && allCities.length ? <div className="col-sm-3 d-flex justify-content-start">
                            <FormControl size="small" sx={{ mt: 1 }} className="dc-drop">
                                <InputLabel id="demo-simple-select-helper-label">City</InputLabel>
                                <Select
                                    
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={cityFilter}
                                    MenuProps={MenuProps}
                                    label="City"
                                    onChange={handleCityFilter}
                                >
                                    {allCities && allCities.length && allCities.map((item, index) => (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>


                                    ))}
                                </Select>

                            </FormControl>
                        </div> : ""}



                        <div className="col-sm-3 d-flex justify-content-start">
                            <FormControl size="small" sx={{ mt: 1 }} className="dc-drop">
                                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                                <Select
                                    
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={status}
                                    MenuProps={MenuProps}
                                    label="Status"
                                    onChange={handleFilterChange}
                                >

                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="In Active">In Active</MenuItem>
                                </Select>

                            </FormControl>
                        </div>
                        
                        <div className="col-sm-3 d-flex justify-content-start">
                            <div className="input-wrapper wrapper-long">
                                <Input className="search" placeholder="Type State, City, Name..." value={searchTerm} onChange={(e) => searchItems(e.target.value)} />
                                <img src={search} className="icon-search" />
                            </div>
                        </div>
                        


                    </div>
                </div>
                <div className="d-flex">


                </div>


            </div>
            <div className="main-section-dc">
                <div className="middle-section">
                    <div className="add-dc-section">
                        {user.role === "admin" ? <img onClick={() => history.push("/addVendor")} src={newSlot} alt="new" /> : ""}
                        {user.role === "admin" ?
                            <p onClick={() => history.push("/addVendor")}>+Add New Vendor</p> : <p></p>}
                    </div>
                    <div className="dc-download">
                        <Dropdown>
                            <Dropdown.Toggle className="download-drop">
                                <span><img src={downloadIcon} alt="download" /></span> Download
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={downloadPdf} >Download PDF</Dropdown.Item>
                                <Dropdown.Item onClick={downloadExcel} >Download Excel</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>
                </div>

                <div className="table-container">
                    <Paper sx={{
                        width: '100%', overflow: 'hidden', ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {

                            mt: 1.8,
                            mr: -1

                        },

                        ".MuiTablePagination-displayedRows": {
                            mt: 2
                        }
                    }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="custom pagination table" >
                                <TableHead >
                                    <TableRow >
                                        <StyledTableCell id="table-head-font" className="table-head">Vendor ID</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Name</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Account Type</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">State</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">City</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">GST Number</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Active Orders</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Contact Person</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Last Updated</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre">Status</StyledTableCell>
                                        <StyledTableCell id="table-head-font" className="table-head" align="centre"></StyledTableCell>


                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {searchTerm && searchTerm.length ? searchResults.map((item) => (
                                        <TableRow key={item?.id}>
                                            <TableCell id="table-head-font" align="centre" component="th" scope="row">
                                                {add3Dots(item?.vendor_id)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre"  >
                                                {add3Dots(item?.name)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.account_type)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.state)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.city)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.gst_number)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.active_orders)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {add3Dots(item?.contactperson)}
                                            </TableCell>
                                            <TableCell id="table-head-font" align="centre" >
                                                {moment(item?.modifiedAt).format('YYYY-MM-DD')}
                                            </TableCell>
                                            {item?.status === "Active" ? <TableCell id="table-head-font" align="centre" style={{ color: "#4CAF50", fontWeight: "600" }}  >
                                                {add3Dots(item?.status)}
                                            </TableCell> : <TableCell id="table-head-font" align="centre" style={{ color: "#FF3D00", fontWeight: "600" }} >
                                                {add3Dots(item?.status)}
                                            </TableCell>}
                                            <TableCell id="table-head-font" align="centre" >
                                                <div style={{ cursor: "pointer" }}>
                                                    {user.role === "admin" ? <img style={{ width: "24px", height: "20px", marginRight: "7px" }} src={editIcon} onClick={() => history.push(`/editVendor/${encodeURIComponent(vendor?.vendor_id)}`)} /> : ""}

                                                    <img src={viewIcon} onClick={() => history.push(`/viewVendor/${encodeURIComponent(vendor?.vendor_id)}`)} />
                                                </div>
                                            </TableCell>
                                        </TableRow>)) :

                                        (currentPosts && currentPosts.length ? currentPosts.map((vendor) => (
                                            <TableRow key={vendor?.id}>
                                                <TableCell id="table-head-font" align="centre" component="th" scope="row">
                                                    {add3Dots(vendor?.vendor_id)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre"  >
                                                    {add3Dots(vendor?.name)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(vendor?.account_type)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(vendor?.state)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(vendor?.city)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(vendor?.gst_number)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(vendor?.active_orders)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {add3Dots(vendor?.contactperson)}
                                                </TableCell>
                                                <TableCell id="table-head-font" align="centre" >
                                                    {moment(vendor?.modifiedAt).format('YYYY-MM-DD')}

                                                </TableCell>
                                                {vendor?.status === "Active" ? <TableCell id="table-head-font" align="centre" style={{ color: "#4CAF50", fontWeight: "600" }}  >
                                                    {add3Dots(vendor?.status)}
                                                </TableCell> : <TableCell id="table-head-font" align="centre" style={{ color: "#FF3D00", fontWeight: "600" }} >
                                                    {add3Dots(vendor?.status)}
                                                </TableCell>}

                                                <TableCell id="table-head-font" align="centre">
                                                    <div style={{ cursor: "pointer" }}>
                                                        {user.role === "admin" ? <img style={{ width: "24px", height: "20px", marginRight: "7px" }} src={editIcon} onClick={() => history.push(`/editVendor/${encodeURIComponent(vendor?.vendor_id)}`)} /> : ""}

                                                        <img src={viewIcon} onClick={() => history.push(`/viewVendor/${encodeURIComponent(vendor?.vendor_id)}`)} />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )) : "")}

                                    {emptyRows>0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell id="table-head-font" colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>

                        </TableContainer>
                        <TablePagination id="table-head-font" className="pagination"
                            rowsPerPageOptions={[5, 10, 25, { label: "All", value: vendor ? vendor.length : null }]}
                            colSpan={3}
                            count={vendor ? vendor.length : null}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            // SelectProps={{
                            //     inputProps: {
                            //         "aria-label": "rows per page"
                            //     },
                            //     native: true
                            // }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Paper>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Vendor
