import { TextField } from '@mui/material';
import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import addDriverIcon from '../../images/add-icon.svg';
import deleteIcon from '../../images/delete.svg';
import editIcon from '../../images/edit-icon.svg';
import { addDriverDetails, deleteDriverDetails, updateDriverDetails } from '../../redux/Actions';
import './Drivers.css';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function Drivers(props) {
    let dispatch = useDispatch()

    const getSingleOrder = useSelector(state => state.data.getSingleOrderDetails)
    console.log("getSingleOrder",getSingleOrder,props)
    const getUpdatedOrderNum = useSelector(state => state.data.getUpdatedOrderNum)
    const { orderDetails } = useSelector(state => state.data)


    const [addDriver, setAddDriver] = useState(false)
    const [editDriver, setEditDriver] = useState(false)
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [shrinkEdit, setShrinkEdit] = useState(true)
    const [getEditId, setGetEditId] = useState()
    const [driver_name, setDriver_Name] = useState("")
    const [driver_contact, setDriver_Contact] = useState("")
    const [location_driver, setLocation_Driver] = useState("")
    const [deleted, setDeleted] = useState(false)
    const [getDeleteId, setGetDeleteId] = useState()
    const [getDeleteOrderNo, setGetDeleteOrderNo] = useState()


    const selectedDriver = getSingleOrder?.driverInfo?.find(driver => driver.id === getEditId)
    useEffect(() => {
        if (selectedDriver) {
            setDriver_Name(selectedDriver?.driver_name)
            setDriver_Contact(selectedDriver?.driver_contact)
            setLocation_Driver(selectedDriver?.location_driver)
        }
    }, [selectedDriver])


    const addAnotherDriver = () => {
        setAddDriver(true)
        setDriver_Name('')
        setDriver_Contact('')
        setLocation_Driver('')
    }

    const subtractAnotherDriver = () => {
        setAddDriver(false)
    }

    const showEditDriverModal = (driver_id) => {
        setEditDriver(true)
        setGetEditId(driver_id)
    }
    const handleCancel = () => {
        setEditDriver(false);
    };

    const driverNameHandler = (e) => {
        setDriver_Name(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    }
    const driverContactHandler = (e) => {
        setDriver_Contact(e.target.value)
    }
    const driverLocationHandler = (e) => {
        setLocation_Driver(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    }

    const allDriverDetails = {
        driver_name,
        driver_contact,
        location_driver
    }
    const saveHandler = (e) => {
        toast.configure()
        e.preventDefault()
        if (!driver_name || !driver_contact || !location_driver) {
            toast.error("Please Enter All Input Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else if (driver_contact.length !== 10) {
            toast.warning("Please check the driver number", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else {
            dispatch(updateDriverDetails(allDriverDetails, selectedDriver?.order_no, selectedDriver?.id, getSingleOrder?.dc_doors?.dc_number, getSingleOrder?.date_of_dispatch, getSingleOrder?.door_no))
            setEditDriver(false);
        }
    }

    const deleteDriverHandler = (order_no, driverId) => {
        setDeleted(true)
        setGetDeleteId(driverId)
        setGetDeleteOrderNo(order_no)
    }

    const handleOkDriver = () => {
        dispatch(deleteDriverDetails(getDeleteOrderNo, getDeleteId, getSingleOrder?.dc_doors?.dc_number, getSingleOrder?.date_of_dispatch, getSingleOrder?.door_no))
        setDeleted(false)
    }

    const handleCancelDriver = () => {
        setDeleted(false)
    }

    const addDriverHandler = () => {
        toast.configure()
        if (!driver_name, !driver_contact, !location_driver) {
            toast.error("Please Enter All Input Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else if (driver_contact.length !== 10) {
            toast.error("Please check the driver number", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else {
            dispatch(addDriverDetails(getSingleOrder?.order_no, allDriverDetails, getSingleOrder?.dc_doors?.dc_number, getSingleOrder?.date_of_dispatch, getSingleOrder?.door_no))
            setAddDriver(false)
            document.getElementById(getUpdatedOrderNum)?.click();
        }
    }

    const driversLength = getSingleOrder?.driverInfo?.length - 1;

    return (
        <TableContainer className='table-scroll'>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell style={{ color: 'orange' }}>Driver Name</StyledTableCell>
                        <StyledTableCell style={{ color: 'orange' }}>Mobile Number</StyledTableCell>
                        <StyledTableCell style={{ color: 'orange' }}>Date & Time</StyledTableCell>
                        <StyledTableCell style={{ color: 'orange' }}>Location</StyledTableCell>
                        <StyledTableCell ></StyledTableCell>
                        <StyledTableCell ></StyledTableCell>
                        <StyledTableCell onClick={addAnotherDriver}>{getSingleOrder?.driverInfo?.length === 0 ? <img src={addDriverIcon} alt="addDriverIcon" className="addDriverIcon" /> : ''}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={getSingleOrder?.driverInfo?.map(driver => driver.length > 4 ? 'scrolling' : '')}>
                    {getSingleOrder?.driverInfo && getSingleOrder.driverInfo.map((driver, index) => (
                        <StyledTableRow key={driver?.name}><StyledTableCell component="th" scope="driver">{driver?.driver_name}</StyledTableCell>
                            <StyledTableCell align="middle">{driver?.driver_contact}</StyledTableCell>
                            <StyledTableCell align="middle">{driver?.createdAt}</StyledTableCell>
                            <StyledTableCell align="middle">{driver?.location_driver === null ? '-' : driver?.location_driver}</StyledTableCell>
                            <StyledTableCell align="middle"><img src={editIcon} alt="editIcon" className='edit-icon-img' onClick={() => showEditDriverModal(driver?.id)} /></StyledTableCell>
                            <StyledTableCell align="middle"><img src={deleteIcon} alt="delete" onClick={() => deleteDriverHandler(driver?.order_no, driver?.id)} /></StyledTableCell>
                            <StyledTableCell align="middle" onClick={addAnotherDriver}>{index === driversLength ? <img src={addDriverIcon} alt="addDriverIcon" className="addDriverIcon" /> : ''}</StyledTableCell>
                        </StyledTableRow>
                    ))}

                    {/* <StyledTableRow><StyledTableCell component="th" scope="driver"></StyledTableCell>
                        <StyledTableCell align="middle"></StyledTableCell>
                        <StyledTableCell align="middle"></StyledTableCell>
                        <StyledTableCell align="middle"></StyledTableCell>
                        <StyledTableCell align="middle"><button>Add</button></StyledTableCell>
                        <StyledTableCell align="middle" onClick={subtractAnotherDriver}>-</StyledTableCell>
                        <StyledTableCell align="middle"></StyledTableCell>
                    </StyledTableRow> */}
                </TableBody>
            </Table>
            {addDriver ?
                <div className='driver-add-container'>
                    <div>
                        <TextField id="standard-basic" label="Driver Name" type="text" variant="standard" name="driver_name" value={driver_name} className="driver_contact" onChange={driverNameHandler} InputLabelProps={{
                            sx: {
                                color: "#FCB713",
                                [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#FCB713"
                                }
                            }
                        }} InputLabelProps={{ shrink: shrinkEdit }} />
                    </div>
                    <div>
                        <TextField id="standard-basic" label="Mobile Number" variant="standard" name="driver_contact" value={driver_contact} type="number" onChange={driverContactHandler} className="driver_contact"
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }}
                            InputLabelProps={{
                                sx: {
                                    color: "#FCB713",
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "#FCB713"
                                    }
                                }
                            }} InputLabelProps={{ shrink: shrinkEdit }}
                        />
                    </div>
                    <div>
                        <TextField id="standard-basic" label="Location" variant="standard" type="text" name="location_driver" value={location_driver} onChange={driverLocationHandler} className="driver_contact" InputLabelProps={{
                            sx: {
                                color: "#FCB713",
                                [`&.${inputLabelClasses.shrink}`]: {
                                    color: "#FCB713"
                                }
                            }
                        }} InputLabelProps={{ shrink: shrinkEdit }} />
                    </div>
                    <div className="add-driver-container">
                        <button onClick={addDriverHandler}>Add</button>
                    </div>
                    <div className="add-driver-container">
                        <button onClick={subtractAnotherDriver}>-</button>
                    </div>
                </div>
                : ''}

            {
                editDriver &&
                <div className='modal'>
                    <Modal
                        visible={editDriver}
                        onCancel={handleCancel}
                        width={800}
                        footer={[
                            <Button key="submit" type="primary" className='vahan-edit-update' onClick={saveHandler}>
                                Save
                            </Button>,
                        ]}
                    >
                        <div>
                            <div className='edit-heading-div'>
                                <h2>Edit Driver Details</h2>
                            </div>
                            <div className='driver-edit-container'>
                                <div>
                                    <TextField id="standard-basic" label="Driver Name" variant="standard" name="driver_name" value={driver_name} type="text" className="driver_name" onChange={driverNameHandler} InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} InputLabelProps={{ shrink: shrinkEdit }} />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Mobile Number" variant="standard" name="driver_contact" value={driver_contact} onChange={driverContactHandler} type="number" className="driver_name" InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} InputLabelProps={{ shrink: shrinkEdit }} />
                                </div>
                                <div>
                                    <TextField id="standard-basic" label="Location" variant="standard" name="location_driver" value={location_driver} onChange={driverLocationHandler} className="driver_name" InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} InputLabelProps={{ shrink: shrinkEdit }} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            }
            {deleted ?
                <Modal visible={deleted} width={350} onCancel={handleCancelDriver} onOk={() => handleOkDriver()}>
                    <div>
                        <div className='edit-heading-div'>
                            <p>Are you sure you want to delete?</p>
                        </div>
                    </div>
                </Modal>
                : ''}

        </TableContainer>
    );
}