import React, { useState, useEffect } from 'react'
import "./Dashboard.css"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import transport from '../../images/Truck.png'
import booking from '../../images/Home.png'
import todo from '../../images/PurchaseOrder.png'
import order from '../../images/OrderHistory.png'
import { useSelector, useDispatch } from 'react-redux'
import { loadDashboardCards, loadDashboardVendorOrders, loadDashboardDcOrders } from '../../redux/Actions';
import { useHistory } from 'react-router';
import { getCookie } from '../utils/Cookie';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function add3Dots(string, limit) {
    var dots = "...";
    var limit = 10
    if (string ? string.length > limit : "") {
        // you can also use substr instead of substring
        string = string.substring(0, limit) + dots;
    }

    return string;
}

const Dashboard = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    // let user = JSON.parse(getCookie("user-info"))
    let user = JSON.parse(getCookie("user-info"))

    console.log("testubfgnjdfg",user);

    const cards = useSelector(state => state.dcUsers.cards)
    const vo = useSelector(state => state.vendorUsers.dvo.order_by_vendor)
    const ddco = useSelector(state => state.dcUsers.ddco.dc_orders)

    useEffect(() => {
        dispatch(loadDashboardCards())
        dispatch(loadDashboardVendorOrders())
        dispatch(loadDashboardDcOrders())
    }, [])

    return (
        <div>
            <div className="dashboard-top">
                <div className="each-block">
                    <h6><img src={booking} /></h6>
                    <div className="nested-block" >
                        <p>Total Orders</p>
                        <p onClick={() => history.push("/orderlisting")}><span>{cards?.order_booked}</span></p>
                    </div>
                </div>
                {user.role === "admin" ?
                    <div className="each-block origin" >
                        <h6><img src={transport} /></h6>
                        <div className="nested-block-one">
                            <p>Working DC</p>
                            <p onClick={() => history.push("/workingdc")}><span>{cards?.working_dc}</span></p>
                        </div>
                    </div> : ""}


                <div className="each-block origin">
                    <h6><img src={todo} /></h6>
                    <div className="nested-block-two">
                        <p>Today Orders</p>
                        <p onClick={() => history.push("/todayorders")}><span>{cards?.todays_order}</span></p>

                    </div>
                </div>
                <div className="each-block origin">
                    <h6><img src={order} /></h6>
                    <div className="nested-block-three">
                        <p>Tomorrow Orders</p>
                        <p onClick={() => history.push("/weeklyorders")}><span>{cards?.tommorows_order}</span></p>

                    </div>
                </div>
            </div>
            <div className="table-containers">
                <div className="table-left">
                    <div className="vendor-orders">
                        <h6>Total Orders By Vendors</h6>
                    </div>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 550 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard">No.</StyledTableCell>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard" align="centre">Vendors</StyledTableCell>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard" align="centre">Orders</StyledTableCell>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard" align="centre">Availabilty</StyledTableCell>
                                    {/* <StyledTableCell align="centre">Protein&nbsp;(g)</StyledTableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {vo && vo.length ? vo.map((item, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell id="table-head-font" align="centre">{add3Dots(item?.vendor_name)}</StyledTableCell>
                                        <StyledTableCell id="table-head-font" align="centre">{item?.orders}</StyledTableCell>
                                        <StyledTableCell id="table-head-font" align="centre">{item?.available_orders}</StyledTableCell>
                                    </StyledTableRow>
                                )) : ""}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="table-right">
                    <div className="vendor-orders">
                        <h6>Total Orders By DC</h6>
                    </div>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 550 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard" >No.</StyledTableCell>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard" align="centre">DC</StyledTableCell>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard" align="centre">Total Booked</StyledTableCell>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard" align="centre">Today</StyledTableCell>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard" align="centre">Tomorrow</StyledTableCell>
                                    <StyledTableCell id="table-head-font" className="table-head-dashboard" align="centre">Weekly</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ddco && ddco.length ? ddco.map((dc, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell id="table-head-font" align="centre">{add3Dots(dc?.dc_name)}</StyledTableCell>
                                        <StyledTableCell id="table-head-font" align="centre">{dc?.orders}</StyledTableCell>
                                        <StyledTableCell id="table-head-font" align="centre">{dc?.today_count}</StyledTableCell>
                                        <StyledTableCell id="table-head-font" align="centre">{dc?.tommorow_counter}</StyledTableCell>
                                        <StyledTableCell id="table-head-font" align="centre">{dc?.weeks_counter}</StyledTableCell>

                                    </StyledTableRow>
                                )) : ""}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            {user.role === "admin" ? <div className="dashboard-track-my-fleet">
                <iframe src="https://apollo.trackmyfleet.co.in/" className={"truck-tracking-map"}></iframe>
            </div> : ''}
        </div>
    )
}

export default Dashboard
