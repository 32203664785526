import React, { useState, useEffect } from 'react'
import './Orders.css';
import booked from '../../images/booked.png'
import newSlot from '../../images/newSlot.png'
import warehouse from '../../images/warehouse.png'
import drawkitTransport from '../../images/drawkitTransport.png'
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { loadDcManagement, loadOrderDetails } from '../../redux/Actions';
import GetOrder from './GetOrder';
import { loadSingleDc } from '../../redux/Actions';
import { loadDcDate } from '../../redux/Actions';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "react-datepicker";
import NewOrder from './NewOrder'
import { useHistory } from 'react-router-dom'
import { loadDoorSlots } from '../../redux/Actions';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { loadUnmountSingleDc } from '../../redux/Actions';
import { loadUnmountDcDate } from '../../redux/Actions'
import { loadUnmountOrderDetails, loadProfileDetails } from '../../redux/Actions';
import { loadVendorStatus } from '../../redux/Actions';
import { loadLogisticsAdding, change_dc_inputs } from '../../redux/Actions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadGetDcHoliday } from '../../redux/Actions';
import { updateOrderNum } from '../../redux/Actions';
import { getSingleOrderDetails, getNewOrderDetails } from '../../redux/Actions'
import { getCookie } from '../utils/Cookie';
import getDay from 'react-datepicker'
// import getDay from "date-fns/getDay"





const Orders = () => {
    const { Option } = Select;
    let history = useHistory()
    let dispatch = useDispatch()
    let user = JSON.parse(getCookie("user-info"))
    let { id } = useParams()
    const search = useLocation().search;
    const dcnumber = new URLSearchParams(search).get('dcnumber');
    const door_no = new URLSearchParams(search).get('door_no');
    const ordernum = new URLSearchParams(search).get('ordernum');
    const delivery_date = new URLSearchParams(search).get('delivery_date');

    const dc_number = new URLSearchParams(search).get('dc_number');
    const on_date = new URLSearchParams(search).get('on_date')
    const door_number = new URLSearchParams(search).get('door_number')

    const dcData = useSelector(state => state.dcUsers.dc)
    const singleDc = useSelector(state => state.data.singleDc.DC)
    const slots = useSelector(state => state.data.slots.data)
    const availableSlots = useSelector(state => state.data.availableSlots)
    const { errorMessage } = useSelector(state => state.data)
    const { updated } = useSelector(state => state.data)
    const { orderDetails } = useSelector(state => state.data)
    const { updateStatus } = useSelector(state => state.data)
    const { vendorStatus } = useSelector(state => state.data)
    console.log("vendorStatus", vendorStatus)
    const { vahanUpdate } = useSelector(state => state.data)
    const { dc_inputs } = useSelector(state => state.data)
    const { comment } = useSelector(state => state.data)
    const getDCHoliday = useSelector(state => state.dcUsers.getDCHoliday)
    const getUpdatedOrderNum = useSelector(state => state.data.getUpdatedOrderNum)
    const driverAdd = useSelector(state => state.data.driverAdd)
    const driverEdit = useSelector(state => state.data.driverEdit)
    const driverDelete = useSelector(state => state.data.driverDelete)
    const { newLogs } = useSelector(state => state.data)



    const [image, setImage] = useState('')
    const [toggle, setToggle] = useState(false)
    const [selected, setSelected] = useState('')
    const [date, setDate] = useState('');
    const [selectDate, setSelectDate] = useState('')
    const [toggleState, setToggleState] = useState('');
    const [order, setOrder] = useState({})
    const [tabsToggle, setTabsToggle] = useState(false)
    const [disable, setDisable] = useState(true)
    const [selectedOrder, setSelectedOrder] = useState(false)
    const [newOrderBooked, setNewOrderBooked] = useState(true)
    const [disabledBookedName, setDisabledBookedName] = useState(true)
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()
    const [days, setDays] = useState()


    function handleChangeDropdown(value) {
        setSelected(value)
        dispatch(loadSingleDc(value))
        dispatch(loadGetDcHoliday(value))
        setDisable(false)
        setImage(false)
    }

    const handleChangeImage = (door) => {
        setImage(door)
        setDisable(false)
        setDisabledBookedName(true)
        if (setImage) {
            setTabsToggle(false)
            setToggle(false)
        }
        setNewOrderBooked(true)
    }

    const handleChangeImageBooked = (door) => {
        setImage(door)
        setNewOrderBooked(false)
        dispatch(loadOrderDetails(selected, selectDate, door))
        setDisable(false)
        setDisabledBookedName(false)
        setToggle(false)
    }

    function onDateChange(dateString) {
        const formatDate = moment(dateString).format("YYYY-MM-DD")
        setSelectDate(formatDate)
        setImage(false)
        setDisable(false)
    }

    const toggleTab = (getItem, order_no) => {
        dispatch(getSingleOrderDetails(getItem))
        setOrder(getItem)
        setToggleState(order_no);
        dispatch(updateOrderNum(order_no))
        setTabsToggle(false)
        setDisable(true)
        if (selected, selectDate, image, order_no) {
            dispatch(loadDoorSlots(selected, selectDate, image, order_no))
        }

    };

    const newOrderHandleChange = () => {
        setToggle(true)
        setToggleState(false)
        dispatch(loadDoorSlots(selected, selectDate, image))
        setNewOrderBooked(true)
    }

    const emptySlotToggle = () => {
        setTabsToggle(true)
        setToggleState(false)
        dispatch(loadDoorSlots(selected, selectDate, image))
        setDisable(true)
    }

    const openBerths = (slots) => {
        let slot = 0;
        {
            slots && slots?.map((item) => {
                if (item.slotAvailable) {
                    slot = slot + 1;
                }
            })
        }
        return slot;
    }

    const renderDoors = (slots) => {
        const imageElements = [];
        for (var i = 0; i < doors; i++) {
            {
                slots && slots?.map((item, index) => {
                    if (item?.slotAvailable) {
                        imageElements.push(<div className='warehouse-doors'>
                            <p key={index}>{item?.dc_door_no}</p>
                            <img src={warehouse} alt={i} className={image === item?.dc_door_no ? 'background' : ''} onClick={() => handleChangeImage(item?.dc_door_no, item?.slotArr)} />
                        </div>);
                    }
                    else {
                        imageElements.push(<div className='warehouse-doors'>
                            <p key={index}>{item?.dc_door_no}</p>
                            <img src={booked} alt={i} className={image === item?.dc_door_no ? 'background' : ''} onClick={() => handleChangeImageBooked(item?.dc_door_no, item?.slotArr)} />
                            {image === item?.dc_door_no ? '' : <p style={{ color: '#d04337' }} >booked</p>}
                        </div>);
                    }
                })
            }
            return imageElements
        }
    }

    const getTime = (str) => {
        let a = str?.split(":")
        let r = ""
        const c = (k) => parseInt(k) < 10 ? "0" + k : k
        if (parseInt(a[0]) == 24 || parseInt(a[0]) == 0) {
            r = "12:" + a[1] + " AM"
        } else if (parseInt(a[0]) == 12) {
            r = "12:" + a[1] + " PM"
        } else if (parseInt(a[0]) > 12) {
            let b = parseInt(a[0]) - 12
            r = c(parseInt(a[0]) - 12) + ":" + a[1] + " PM"
        } else {
            r = a[0] + ":" + a[1] + " AM"
        }
        return r
    }



    const renderOrders = (slots) => {
        const selectedOrders = slots ? slots?.find(item => item.dc_door_no === image) : null
        if (selectedOrders) {
            if (orderDetails?.data?.length > 0) {
                orderDetails?.data?.sort(function (left, right) {
                    return moment.utc(left?.slot_from, "h:mm:ss").diff(moment.utc(right?.slot_from, "h:mm:ss"))
                });
                return (orderDetails?.data?.map((order) => (
                    <>
                        <button
                            className={toggleState === order?.order_no ? "tabs active-tabs " : "tabs"}
                            onClick={() => toggleTab(order, order?.order_no)}
                            id={order?.order_no}
                        >
                            <p className='wheeler'>{order?.vehicle?.wheeler}</p>
                            <p className='wheeler-slot'>{getTime(order?.dc_doors?.slot_from)}-{getTime(order?.dc_doors?.slot_to)}</p>
                            <p className='wheeler-vendor'>{order?.vendor?.name}</p>
                        </button>
                    </>
                )))
            }
            else {
                return (
                    <button
                        className="tabs active-tabs"
                    >
                        <br></br>
                        <p className='no-order-found'>{orderDetails?.message}</p>
                        <br></br>
                    </button>
                )
            }
        }
        return null;
    }


    const renderSlots = (slots) => {
        const selectedSlots = slots ? slots?.find(item => item.dc_door_no === image) : null
        if (selectedSlots) {
            return <button
                className={tabsToggle ? "tabs active-tabs " : "tabs"}
                onClick={() => emptySlotToggle()}
            >
                <br />
                <p className='openElement'>open</p>
                <br />
            </button>
        }
        return null;
    }


    useEffect(() => {
        dispatch(loadDcManagement())
        dispatch(loadVendorStatus())
    }, [])

    const format = {
        dc_number: selected,
        on_date: selectDate
    }
    useEffect(() => () => {
        dispatch(loadUnmountDcDate(format))
    }, []);

    const selectedOrders = slots ? slots?.find(item => item.dc_door_no === image) : ''
    const doors = singleDc && singleDc?.total_berths

    const gettingOrderNumber = orderDetails?.data?.find(order => order?.order_no)
    console.log("gettingOrderNumber", gettingOrderNumber)

    console.log("checking the dates", dateFrom?.map((index) => new Date(index)))

    useEffect(() => {
        if (getDCHoliday) {
            setDateFrom(getDCHoliday?.data?.map(data =>
                data?.dates.map(data1 => moment(data1).format('YYYY-MM-DD'))))
        }
    }, [getDCHoliday])

    useEffect(() => {
        if (dateFrom) {
            setDays(dateFrom?.flat())
        }
    }, [dateFrom])

    useEffect(() => {
        if (selected && selectDate && image) {
            dispatch(loadOrderDetails(selected, selectDate, image))
            dispatch(getNewOrderDetails({ image, selectDate }))
        }
    }, [selected, selectDate, image])

    // useEffect(() => () => {
    //     dispatch(loadUnmountSingleDc(selected))
    // }, [selected]);


    useEffect(() => {
        if (selected && selectDate) {
            dispatch(loadDcDate(format))
            const searchParams = new URLSearchParams(format);
            history.push(`/orders/?${searchParams}`);
        }
    }, [selected, selectDate])

    useEffect(() => {
        if (dc_number && on_date) {
            setSelected(dc_number)
            setSelectDate(on_date)
        }
    }, [dc_number, on_date])

    useEffect(() => {
        if (dc_number) {
            dispatch(loadSingleDc(dc_number))
        }
    }, [dc_number])

    useEffect(() => {
        if (errorMessage.success === false) {
            setToggle(true)
            setTabsToggle(true)
            setToggleState(false)
        }
        else {
            setToggle(false)
            setTabsToggle(false)
            setToggleState(false)
        }
    }, [errorMessage])

    useEffect(() => {
        if (selected && selectDate && image) {
            if (!newOrderBooked) {
                setNewOrderBooked(false)
            }
            if (newOrderBooked) {
                setNewOrderBooked(true)
            }
        }
    }, [errorMessage, selected, selectDate, image])

    useEffect(() => {
        if (updated.status === "success") {
            dispatch(loadDcDate(format))
            setDisable(false)
        }
    }, [updated])

    useEffect(() => {
        toast.configure()
        if (user?.status === "In Active" && user?.role === "vendor") {
            toast.error('Your Status Is Inactive and Please Contact Admin To Make As Active', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
            });
        }
    }, [user?.status])

    useEffect(() => {
        if (driverAdd.success === true) {
            dispatch(loadOrderDetails(selected, selectDate, image))
        }
    }, [driverAdd])

    useEffect(() => {
        if (driverEdit.success === true) {
            dispatch(loadOrderDetails(selected, selectDate, image))
        }
    }, [driverEdit])

    useEffect(() => {
        if (driverDelete.success === true) {
            dispatch(loadOrderDetails(selected, selectDate, image))
        }
    }, [driverDelete])


    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0;
    };

    return (
        <div className='orders-page'>
            <div className='main-page'>
                <div className='dc-dropdown'>
                    <Select value={selected || undefined} onChange={handleChangeDropdown} className='dropdown' placeholder="Select DC" >

                        {dcData?.data && dcData?.data.length && dcData?.data?.map((item, i) => (
                            <Option value={item?.dc_number}>{item?.dc_number} - {item?.dc_name}</Option>
                        ))}
                    </Select>
                </div>
                <div className='calendar-dropdown'>
                    <DatePicker
                        placeholderText="Date of Delivery"
                        value={selectDate}
                        minDate={moment().toDate()}
                        onChange={dates => onDateChange(dates)}
                        className='datePicker'
                        // excludeDates={Array.isArray() ?  dateFrom : []}
                        excludeDates={selected ? days?.map((index) => new Date([index])) : ''}
                        disabled={selected ? false : true}
                        filterDate={isWeekday}
                    />
                </div>
            </div>
            {selected && selectDate ?
                <section className='booking-berth'>
                    <div className='berth-container'>
                        <div className='row berth-details'>
                            <div className='col-md-12 Dc-details-container'>
                                <h5 className='city-color'>{singleDc?.dc_number} - {singleDc && singleDc?.dc_name}</h5>
                                <p className='data-color time'>{selectDate ? moment(selectDate).format("Do MMM YYYY") : ''}</p>
                            </div>
                            <div className='Dc-details-berth-slots'>
                                <div className='shipFrom'>
                                    <div className='spacing-margin working-hours-div'>
                                        <p className='data-color working-hours'>Working Hours</p>
                                        {singleDc ?
                                            <p className='data-color slot_from'>{getTime(singleDc?.working_hours_from)} - {getTime(singleDc?.working_hours_to)}</p>
                                            : null}
                                    </div>
                                    <div className='spacing-margin'>
                                        <p className='data-color total-berths'>Total Berths <span className='total-berths-colon'>:</span> <span className='berths-span'>{singleDc && singleDc?.total_berths}</span></p>
                                        <p className='data-color'>Open Berths :  <span className='berths-span'>{openBerths(slots) ? openBerths(slots) : 0}</span></p>
                                    </div>
                                </div>
                                <div className='spacing-margin'>
                                    <p className='data-color total-berths'>Contact Name <span className='contact-name-right'>:  {singleDc && singleDc?.dc_contact}</span></p>
                                    <p className='data-color'>Contact Details  <span className='contact-details-right'>:  {singleDc && singleDc?.contact_number}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='booking-doors'>
                        <div className='warehouse element'>
                            {renderDoors(slots)}
                        </div>
                    </div>
                </section> :
                <div className='drawkitTransport'>
                    <img src={drawkitTransport} alt="drawkitTransport" />
                    <p className='transport-p'>Please Select DC & Date !</p>
                </div>
            }
            <section className='all-order-details-margin-bottom'>
                <div className="container">
                    <div className="col-md-12">
                        <div className="row bg_f6f8fb">
                            <div className=' col-md-10 pad_0'>
                                {renderOrders(slots)}
                                {toggle ? renderSlots(slots) : ''}
                            </div>
                            {image && (newOrderBooked && selectedOrders?.slotAvailable) ? <div className={toggle ? 'image-none' : 'newOrderImage image-display col-md-2'} onClick={newOrderHandleChange} >
                                {vendorStatus?.user_status === 'Active' && user?.role === "vendor" ? <img src={newSlot} alt="new order" newOrderBooked /> : null}
                                {vendorStatus?.user_status === 'Active' && user?.role === "vendor" ? <span className='newSlot' newOrderBooked>New Slot</span> : null}
                            </div> : ''}
                        </div>
                    </div>
                </div>
                <div className="content-tabs">
                    <div
                        className={toggleState && disable ? "container container-pg-bottom" : "content"}
                    >
                        {(toggleState && disable) || selectedOrder ? <GetOrder /> : ''}
                    </div>
                    <div
                        className={tabsToggle ? "container" : "content"}
                    >
                        {toggle && disable ? <NewOrder /> : ''}
                    </div>
                </div>
            </section>
            <div className='slots'>
                <div className='slotButtons'>
                </div>
            </div>
            <ToastContainer />

        </div>
    )
}

export default Orders



