import React, { useState, useEffect } from 'react'
import './Orders.css';
import booked from '../../images/booked.png'
import warehouse from '../../images/warehouse.png'
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { loadOrderDetails } from '../../redux/Actions';
import GetOrder from './GetOrder';
import { loadSingleDc } from '../../redux/Actions';
import { loadDcDate } from '../../redux/Actions';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom'
import { loadDoorSlots } from '../../redux/Actions';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getSingleOrderDetails } from '../../redux/Actions'



const OrderView = () => {
    const { Option } = Select;
    let history = useHistory()
    let dispatch = useDispatch()
    let { id } = useParams()
    const search = useLocation().search;
    const dcnumber = new URLSearchParams(search).get('dcnumber');
    const delivery_date = new URLSearchParams(search).get('delivery_date');
    const door_no = new URLSearchParams(search).get('door_no');
    const ordernum = new URLSearchParams(search).get('ordernum');

    // const search = useLocation().search;
    const dc_number = new URLSearchParams(search).get('dc_number');
    const on_date = new URLSearchParams(search).get('on_date')
    const door_number = new URLSearchParams(search).get('door_number')


    const dcData = useSelector(state => state.dcUsers.dc)
    const singleDc = useSelector(state => state.data.singleDc.DC)
    const slots = useSelector(state => state.data.slots.data)
    const availableSlots = useSelector(state => state.data.availableSlots)
    const { errorMessage } = useSelector(state => state.data)
    const { updated } = useSelector(state => state.data)
    const { orderDetails } = useSelector(state => state.data)
    const { updateStatus } = useSelector(state => state.data)
    const { comment } = useSelector(state => state.data)
    const driverAdd = useSelector(state => state.data.driverAdd)
    const driverEdit = useSelector(state => state.data.driverEdit)
    const driverDelete = useSelector(state => state.data.driverDelete)



    const [image, setImage] = useState()
    const [toggle, setToggle] = useState(false)
    const [selected, setSelected] = useState('')
    const [selectDate, setSelectDate] = useState('')
    const [toggleState, setToggleState] = useState('');
    const [order, setOrder] = useState({})
    const [tabsToggle, setTabsToggle] = useState(false)
    const [disable, setDisable] = useState(true)
    const [selectedOrder, setSelectedOrder] = useState(false)
    const [selectAria, setSelectAria] = useState(false);


    useEffect(() => {
        setImage(door_no)
    }, [dcnumber, delivery_date, door_no])


    useEffect(() => {
        if (dcnumber) {
            setSelected(dcnumber)
            dispatch(loadSingleDc(dcnumber))
        }
    }, [dcnumber])


    const format = {
        dc_number: dcnumber,
        on_date: delivery_date
    }
    useEffect(() => {
        if (dcnumber && delivery_date) {
            dispatch(loadDcDate(format))
        }
    }, [dcnumber, delivery_date])


    useEffect(() => {
        if (door_no) {
            setImage(parseInt(door_no))
            dispatch(loadOrderDetails(dcnumber, delivery_date, parseInt(door_no)));
        }
    }, [dcnumber, delivery_date, door_no])

    useEffect(() => {
        if (orderDetails && orderDetails?.data && orderDetails?.data?.length > 0) {
            orderDetails.data.map(order => {
                if (order.order_no == ordernum) {
                    dispatch(getSingleOrderDetails(order))
                    setToggleState(order.order_no);
                    setSelectedOrder(false)
                } else {
                    setSelectedOrder(false)
                }
            })
        }
    }, [orderDetails])

    useEffect(() => {
        if (updated) {
            setToggleState(false)
        }
    }, [updated])

    useEffect(() => {
        if (driverAdd.success === true) {
            dispatch(loadOrderDetails(dcnumber, delivery_date, door_no))
            { toggleTab() }
        }
    }, [driverAdd])

    useEffect(() => {
        if (driverEdit.success === true) {
            dispatch(loadOrderDetails(dcnumber, delivery_date, door_no))
            { toggleTab() }
        }
    }, [driverEdit])

    useEffect(() => {
        if (driverDelete.success === true) {
            dispatch(loadOrderDetails(dcnumber, delivery_date, door_no))
            { toggleTab() }
        }
    }, [driverDelete])



    const toggleTab = (getItem, order_no) => {
        setTabsToggle(false)
        dispatch(loadDoorSlots(dcnumber, delivery_date, door_no, ordernum))
        setDisable(true)
    };

    const openBerths = (slots) => {
        let slot = 0;
        {
            slots && slots?.map((item) => {
                if (item.slotAvailable) {
                    slot = slot + 1;
                }
            })
        }
        return slot;
    }

    const doors = singleDc && singleDc?.total_berths
    const renderDoors = (slots) => {
        const imageElements = [];
        for (var i = 0; i < doors; i++) {
            {
                slots && slots?.map((item, index) => {
                    if (item.slotAvailable) {
                        imageElements.push(<div className='warehouse-doors'>
                            <p key={index}>{item.dc_door_no}</p>
                            <img src={warehouse} alt={i} className={image === item?.dc_door_no ? ' background' : ''}
                            />
                        </div>);
                    }
                    else {
                        imageElements.push(<div className='warehouse-doors'>
                            <p key={index}>{item.dc_door_no}</p>
                            <img src={booked} alt={i} className={image === item?.dc_door_no ? 'background' : ''}
                            />
                            {image === item?.dc_door_no ? "" : <p style={{ color: '#d04337' }}>booked</p>}
                        </div>);
                    }
                })
            }
            return imageElements
        }
    }

    const getTime = (str) => {
        let a = str?.split(":")
        let r = ""
        const c = (k) => parseInt(k) < 10 ? "0" + k : k
        if (parseInt(a[0]) == 24 || parseInt(a[0]) == 0) {
            r = "12:" + a[1] + " AM"
        } else if (parseInt(a[0]) == 12) {
            r = "12:" + a[1] + " PM"
        } else if (parseInt(a[0]) > 12) {
            let b = parseInt(a[0]) - 12
            r = c(parseInt(a[0]) - 12) + ":" + a[1] + " PM"
        } else {
            r = a[0] + ":" + a[1] + " AM"
        }
        return r
    }



    const renderOrders = (slots) => {
        const selectedOrders = slots ? slots?.find(item => item.dc_door_no === image) : null
        if (selectedOrders) {
            if (orderDetails?.data?.length > 0) {
                orderDetails?.data?.sort(function (left, right) {
                    return moment.utc(left?.slot_from, "h:mm:ss").diff(moment.utc(right?.slot_from, "h:mm:ss"))
                });
                return (orderDetails?.data?.map((order) => (
                    <>
                        <button
                            className={toggleState === order?.order_no ? "tabs active-tabs " : "tabs"}
                            onClick={() => toggleTab(order, order?.order_no)}
                        >
                            <p className='wheeler'>{order?.vehicle?.wheeler}</p>
                            <p className='wheeler-slot'>{getTime(order?.dc_doors?.slot_from)}-{getTime(order?.dc_doors?.slot_to)}</p>
                            <p className='wheeler-vendor'>{order?.vendor?.name}</p>
                        </button>
                    </>
                )))
            }
            else {
                return (
                    <button
                        className="tabs active-tabs"
                    >
                        <br></br>
                        <p className='no-order-found'>{orderDetails?.message}</p>
                        <br></br>
                    </button>
                )
            }
        }
        return null;
    }


    const renderSlots = (slots) => {
        const selectedSlots = slots ? slots?.find(item => item.dc_door_no === image) : null
        if (selectedSlots) {
            return selectedSlots.slotArr.map((item, index) => (
                <>
                    <button
                        className={tabsToggle ? "tabs active-tabs " : "tabs"}
                    >
                        <br />
                        <p className='openElement'>open</p>
                        <br />
                    </button>
                </>
            ))
        }
        return null;
    }

    return (
        <div className='orders-page'>
            <div className='main-page'>
                <div>
                    <Select value={dcnumber} className='dropdown' placeholder="Select DC" dropdownClassName='select_dropdown'>

                        {dcData && dcData.length && dcData.data.map((item, i) => (
                            <Option value={item?.dc_number} >{item?.dc_number} - {item?.dc_name}</Option>
                        ))}
                    </Select>
                </div>
                <div>
                    <DatePicker
                        placeholderText="Select Date"
                        value={delivery_date}
                        className='datePicker'
                        disabled
                    />
                </div>
            </div>
            <section className='booking-berth'>
                <div className='berth-container'>
                    <div className='berth-details'>
                        <div className='Dc-details-container'>
                            <h5 className='city-color'>{singleDc?.dc_number} - {singleDc && singleDc?.dc_name}</h5>
                            <p className='data-color time'>{delivery_date ? moment(delivery_date).format("Do MMM YYYY") : ''}</p>
                        </div>
                        <div className='Dc-details-berth-slots'>
                            <div className='shipFrom'>
                                <div className='spacing-margin'>
                                    <p className='data-color working-hours'>Working Hours</p>
                                    {singleDc ?
                                        <p className='data-color slot_from'>{getTime(singleDc?.working_hours_from)} - {getTime(singleDc?.working_hours_to)}</p>
                                        : null}
                                </div>
                                <div className='spacing-margin'>
                                    <p className='data-color total-berths'>Total Berths : <span className='berths-span'>{singleDc && singleDc?.total_berths}</span></p>
                                    <p className='data-color'>Open Berths :  <span className='berths-span'>{openBerths(slots) ? openBerths(slots) : null}</span></p>
                                </div>
                            </div>
                            <div className='spacing-margin'>
                                <p className='data-color total-berths'>Contact Name <span className='contact-name-right'>:  {singleDc && singleDc?.dc_contact}</span></p>
                                <p className='data-color'>Contact Details  <span className='contact-details-right'>:  {singleDc && singleDc?.contact_number}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='booking-doors'>
                    <div className='warehouse element'>
                        {renderDoors(slots)}
                    </div>
                </div>
            </section>
            <section >
                <div className="container">
                    <div className="col-md-12">
                        <div className="row bg_f6f8fb">
                            <div className=' col-md-10 pad_0'>
                                {renderOrders(slots)}
                                {toggle ? renderSlots(slots) : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-tabs">
                    <div
                        className={toggleState ? "container container-pg-bottom" : "content"}
                    >
                        {(toggleState && disable) || selectedOrder ? <GetOrder /> : ''}
                    </div>
                </div>
            </section>
            <div className='slots'>
                <div className='slotButtons'>
                </div>
            </div>
            <ToastContainer />

        </div>
    )
}

export default OrderView



