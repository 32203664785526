import './App.css';
import { Switch, Route } from 'react-router';
import { Spin } from 'antd';
import Dashboard from './components/Dashboard/Dashboard';
import Header from './components/Header/Header';
import DcManagement from './components/DC/DcManagement';
import Vendor from './components/Vendor/Vendor';
import Orders from './components/Orders/Orders';
import OrderListing from './components/OrderListing/OrderListing';
import Login from './components/Login/Login';
import { Fragment } from 'react';
import AddDc from './components/DC/AddDc';
import AddVendor from './components/Vendor/AddVendor';
import EditDc from './components/DC/EditDc';
import EditVendor from './components/Vendor/EditVendor';
import ViewVendor from './components/Vendor/ViewVendor';
import ViewDc from './components/DC/ViewDc';
import OrderView from './components/Orders/OrderView';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TodayOrders from './components/OrderListing/TodayOrders';
import WeeklyOrders from './components/OrderListing/WeeklyOrders';
import WorkingDc from './components/DC/WorkingDc';
import { useParams, useLocation } from 'react-router-dom'
import NewPassword from './components/Password/NewPassword';
import { useSelector } from 'react-redux';



function App() {
  toast.configure()
  const search = useLocation().search;
  const dcLoader = useSelector(state => state.dcUsers.loader)
  const vendorUsersLoader = useSelector(state => state.vendorUsers.loader)
  const dataLoader = useSelector(state => state.data.loader)
  const email = new URLSearchParams(search).get('/api/auth');
  return (
    <div className="App">
      {/* <Spin spinning={dcLoader || vendorUsersLoader || dataLoader}> */}
      <Switch>
        <Route path="/" exact component={Login} />

        <Route path='/dashboard' render={props =>
          <Fragment>
            < Header />
            <Dashboard />
          </Fragment>
        } />
        <Route path='/orders' render={props =>
          <Fragment>
            <Header />
            <Orders />
          </Fragment>
        } />
        <Route path='/orderview' render={props =>
          <Fragment>
            <Header />
            <OrderView />
          </Fragment>
        } />
        <Route path='/orderlisting' render={props =>
          <Fragment>
            < Header />
            <OrderListing />
          </Fragment>
        } />
        <Route path='/vendor' render={props =>
          <Fragment>
            < Header />
            <Vendor />
          </Fragment>
        } />
        <Route path='/dc' render={props =>
          <Fragment>
            < Header />
            <DcManagement />
          </Fragment>
        } />

        <Route path='/addDc' render={props =>
          <Fragment>
            < Header />
            <AddDc />
          </Fragment>
        } />

        <Route path='/addVendor' render={props =>
          <Fragment>
            < Header />
            <AddVendor />
          </Fragment>
        } />

        <Route path='/editDc/:id' render={props =>
          <Fragment>
            < Header />
            <EditDc />
          </Fragment>
        } />

        <Route path='/editVendor/:id' render={props =>
          <Fragment>
            < Header />
            <EditVendor />
          </Fragment>
        } />

        <Route path='/viewVendor/:id' render={props =>
          <Fragment>
            < Header />
            <ViewVendor />
          </Fragment>
        } />
        <Route path='/viewDc/:id' render={props =>
          <Fragment>
            < Header />
            <ViewDc />
          </Fragment>
        } />
        <Route path='/todayorders' render={props =>
          <Fragment>
            < Header />
            <TodayOrders />
          </Fragment>
        } />
        <Route path='/weeklyorders' render={props =>
          <Fragment>
            < Header />
            <WeeklyOrders />
          </Fragment>
        } />
        <Route path='/workingdc' render={props =>
          <Fragment>
            < Header />
            <WorkingDc />
          </Fragment>
        } />
        <Route path='/api/auth/newpassword/:email/:token' render={props =>
          <Fragment>
            <NewPassword />
          </Fragment>
        } />



      </Switch>
      {/* </Spin> */}
    </div>
  );
}

export default App;
