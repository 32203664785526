import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './AddDc.css'
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { addDc } from '../../redux/Actions';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { loadAllCities, loadAllStates } from "../../redux/Actions"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { blockInvalidChar } from '../../blockInvalidChar';
import mapIcon from '../../images/address.png'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 190
        }
    }
};

const AddDc = () => {
    const history = useHistory()
    const dispatch = useDispatch()


    const allStates = useSelector(state => state.dcUsers.allStates.states)
    const allCities = useSelector(state => state.dcUsers.allCities.cities)
    const msg = useSelector(state => state.dcUsers.error)
    const loading = useSelector(state => state.dcUsers.loading)


    const [dc_number, setDc_number] = useState("")
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [dc_name, setDc_name] = useState("")
    const [total_berths, setTotal_berths] = useState("")
    const [dc_contact, setDc_contact] = useState("")
    const [email, setEmail] = useState('')
    const [contact_number, setContact_number] = useState("")
    const [working_hours_from, setWorking_hours_from] = useState("")
    const [working_hours_to, setWorking_hours_to] = useState("")
    const [status, setStatus] = useState("")
    const [buttonLoading, setButtonLoading] = useState(false)
    const [pincode, setPincode] = useState("")
    const [numberError, setNumberError] = useState(false)
    const [pinError, setPinError] = useState(false)
    const [map_link, setMapLink] = useState("")
    const [address, setAddress] = useState("")
    const [shrink, setShrink] = useState(true)



    useEffect(() => {
        if (state) {
            dispatch(loadAllCities(state))
        }
    }, [state])

    useEffect(() => {
        if (!loading && buttonLoading && msg.success) {
            history.push("/dc")
        }
    }, [loading, buttonLoading])


    const handleDcNumber = (e) => {
        { removeText(e.target.value) }
        setDc_number(e.target.value)
    }
    const handleState = (e) => {
        setState(e.target.value)
    }
    const handleCity = (e) => {
        setCity(e.target.value)
    }
    const handlePincode = (e) => {
        setPincode(e.target.value)
        if (e.target.value.length != 6) {
            setPinError(true)
        }
        else {
            setPinError(false)
        }
    }
    const handleDcName = (e) => {
        setDc_name(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    }
    const handleTotalBirths = (e) => {
        setTotal_berths(e.target.value)
    }
    const handleDcContact = (e) => {
        setDc_contact(e.target.value.replace(/[^A-Za-z ]/gi, ""))
    }
    const handleDcEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleContactNumber = (e) => {
        setContact_number(e.target.value)
        if (e.target.value.length !== 10) {
            setNumberError(true)
        }
        else {
            setNumberError(false)
        }
    }
    const handleWorkingFrom = (e) => {
        setWorking_hours_from(e.target.value)
    }
    const handleWorkingTO = (e) => {
        setWorking_hours_to(e.target.value)
    }
    const handleStatus = (e) => {
        setStatus(e.target.value)
    }

    const handleDcAddress = (e) => {
        setAddress(e.target.value)
    }
    const handleDcMap = (e) => {
        setMapLink(e.target.value)
    }


    // useEffect(() => {
    //     dispatch(loadAllStates())
    // }, [])

    const finalData = {
        dc_number,
        // state,
        // city,
        dc_name,
        email,
        total_berths,
        dc_contact,
        contact_number,
        working_hours_from,
        working_hours_to,
        // pincode,
        status,
        address,
        map_link
    }


    const handleSubmit = (e) => {
        toast.configure();
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        e.preventDefault()
        if (!dc_number || !dc_name || !email || !total_berths || !dc_contact || !contact_number || !map_link) {
            toast.error("Please enter all input fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else if (!email || regex.test(email) === false) {
            toast.warning('Please add a valid Email address', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else if (contact_number.length != 10) {
            toast.warning('Please add a valid contact number', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        // else if (pincode.length != 6) {
        //     toast.warning('Please add a valid pincode number', {
        //         position: "top-right",
        //         autoClose: 2000,
        //         hideProgressBar: true,
        //     });
        // }
        else {
            dispatch(addDc(finalData))
            setTimeout(() => {
                setButtonLoading(true)
            }, 1000)
        }
    }

    const removeText = (value) => {
        const nannumber = isNaN(dc_number)
    }

    return (
        <div>
            <div>
                <form className="add-input" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-4 dc-margin" >
                            <TextField id="standard-basic" label="DC name" id="add-label-font" variant="standard" name="dc_name" value={dc_name} type="text" onChange={handleDcName} className='dc-textfield-width' />
                        </div>
                        <div className="col-md-4 dc-margin">
                            <TextField id="standard-basic" label="DC number" id="add-label-font" type="number" variant="standard" name="dc_number" value={dc_number} onChange={handleDcNumber} className='dc-textfield-width'
                                // inputProps={{
                                //     min: 0,
                                //     max: 100,
                                //     // step: 2,
                                //     onKeyDown: (event) => {
                                //         event.preventDefault();
                                //     }
                                // }}
                                onKeyDown={blockInvalidChar}
                            />

                        </div>
                        <div className="col-md-4 dc-margin">
                            <TextField id="standard-basic" label="Email" id="add-label-font" variant="standard" name="email" value={email} type="email" onChange={handleDcEmail} className='dc-textfield-width' />
                        </div>
                        <div className="col-md-4 dc-margin dc-address-map-icon-div">
                            <TextField id="standard-basic" label="DC address" id="add-label-font" variant="standard" name="address" value={address} type="text" onChange={handleDcAddress} className='dc-textfield-width' />
                            <a href={map_link} target="_blank"><img src={mapIcon} alt="mapIcon" /></a>
                        </div>
                        <div className="col-md-4 dc-margin dc-address-map-icon-div">
                            <TextField id="standard-basic" label="DC map link" id="add-label-font" variant="standard" name="map_link" value={map_link} type="text" onChange={handleDcMap} className='dc-textfield-width' />
                        </div>
                        <div className="col-md-4 dc-margin">
                            <TextField id="standard-basic" label="DC contact name" id="add-label-font" variant="standard" name="dc_contact" value={dc_contact} type="text" onChange={handleDcContact} className='dc-textfield-width' />
                        </div>
                        <div className="col-md-4 dc-margin">
                            <TextField id="standard-basic" label="DC contact number" id="add-label-font" variant="standard" error={numberError} name="contact_number" value={contact_number} type="number" onChange={handleContactNumber} onKeyDown={blockInvalidChar} onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }} className='dc-textfield-width' />
                        </div>
                        <div className="col-md-4 dc-margin">
                            <TextField id="standard-basic" label="Total berths" id="add-label-font" variant="standard" name="total_berths" value={total_berths} type="number" onChange={handleTotalBirths} onKeyDown={blockInvalidChar} className='dc-textfield-width' />

                        </div>
                        {/* <div className="col-md-4 dc-margin dc-margin-top">
                            <FormControl variant="standard"   >
                                <InputLabel id="demo-simple-select-standard-label" id="add-label-font" className='dc-textfield-width'>Select Working hours from</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={working_hours_from}
                                    MenuProps={MenuProps}

                                    onChange={handleWorkingFrom}
                                    label="Working hours from"
                                    className='dc-textfield-width'
                                >
                                    <MenuItem value="6:00">06:00am</MenuItem>
                                    <MenuItem value="7:00">07:00am</MenuItem>
                                    <MenuItem value="8:00">08:00am</MenuItem>
                                    <MenuItem value="9:00">09:00am</MenuItem>
                                    <MenuItem value="10:00">10:00am</MenuItem>
                                    <MenuItem value="11:00">11:00am</MenuItem>
                                    <MenuItem value="12:00">12:00pm</MenuItem>
                                    <MenuItem value="13:00">01:00pm</MenuItem>
                                    <MenuItem value="14:00">02:00pm</MenuItem>

                                </Select>
                            </FormControl>
                        </div>


                        <div className="col-md-4 dc-margin dc-margin-top ">
                            <FormControl variant="standard">
                                <InputLabel id="demo-simple-select-standard-label" id="add-label-font" className='dc-textfield-width'>Select Working hours To</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={working_hours_to}
                                    MenuProps={MenuProps}

                                    onChange={handleWorkingTO}
                                    label="Working hours to"
                                    className='dc-textfield-width'
                                >
                                    <MenuItem value="09:00">09:00am</MenuItem>
                                    <MenuItem value="10:00">10:00am</MenuItem>
                                    <MenuItem value="11:00">11:00am</MenuItem>
                                    <MenuItem value="12:00">12:00pm</MenuItem>
                                    <MenuItem value="13:00">01:00pm</MenuItem>
                                    <MenuItem value="14:00">02:00pm</MenuItem>
                                    <MenuItem value="15:00">03:00pm</MenuItem>
                                    <MenuItem value="16:00">04:00pm</MenuItem>
                                    <MenuItem value="17:00">05:00pm</MenuItem>
                                    <MenuItem value="18:00">06:00pm</MenuItem>
                                    <MenuItem value="19:00">07:00pm</MenuItem>
                                    <MenuItem value="20:00">08:00pm</MenuItem>
                                    <MenuItem value="21:00">09:00pm</MenuItem>
                                    <MenuItem value="22:00">10:00pm</MenuItem>
                                    <MenuItem value="23:00">11:00pm</MenuItem>
                                </Select>
                            </FormControl>
                        </div> */}


                        {/* <div className="col-md-4 dc-margin dc-margin-top">
                            <FormControl variant="standard">
                                <InputLabel id="demo-simple-select-standard-label" id="add-label-font" className='dc-textfield-width'>Select State</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={state}
                                    MenuProps={MenuProps}
                                    className='dc-textfield-width'
                                    onChange={handleState}
                                    label="state"
                                >
                                    {allStates && allStates ? allStates.map((item) => (
                                        <MenuItem key={item} value={item}>{item}</MenuItem>


                                    )) : ""}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-4 dc-margin dc-margin-top">
                            <FormControl variant="standard" >
                                <InputLabel className='dc-textfield-width' id="demo-simple-select-standard-label" id="add-label-font" >Select City</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={city}
                                    MenuProps={MenuProps}
                                    onChange={handleCity}
                                    label="city"
                                    className='dc-textfield-width'
                                >
                                    {allCities && allCities ? allCities.map((item, index) => (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>


                                    )) : ""}
                                </Select>
                            </FormControl>

                        </div>
                        <div className="col-md-4 dc-margin ">
                            <TextField id="standard-basic" label="Pincode" id="add-label-font" variant="standard" name="pincode" value={pincode} error={pinError} type="number" onChange={handlePincode} onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                            }} className='dc-textfield-width' />
                        </div> */}
                        <div className="col-md-4 dc-margin dc-margin-top">
                            <FormControl variant="standard" >
                                <InputLabel id="demo-simple-select-standard-label" id="add-label-font" >Select Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={status}
                                    className='dc-textfield-width'
                                    name="status"
                                    onChange={handleStatus}
                                    label="status"
                                >
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="In Active">In Active </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='dc-flex'>
                        <Button variant="contained" color="primary" type="submit" className='button-dc-submit'>Submit</Button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    )
}

export default AddDc
