import React, { useState, useEffect, useRef } from 'react'
import './NewOrder.css';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import deliveryDateTime from '../../images/deliveryDateTime.png'
import deliveryTruck from '../../images/deliveryTruck.png'
import driver from '../../images/driver.png'
import Logistics from '../../images/logistics.png'
import { addVahanDetails, loadLogistics, loadOrderDetails, loadVehicelDetails } from '../../redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { addComments } from '../../redux/Actions';
import { loadVehicles } from '../../redux/Actions';
import { loadVendor } from '../../redux/Actions';
import { inputLabelClasses } from "@mui/material/InputLabel";
import DeliveryComments from '../../images/DeliveryComments.png'
import { loadOrderVendorId } from '../../redux/Actions';
import NewTracking from './NewTracking'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import TimeSlotDropdown from './TimeSlotDropdown';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadLogisticsAdding } from '../../redux/Actions'
import { Modal, Input, Button } from 'antd';
import { loadInvoiceUpload } from '../../redux/Actions'
import { addVahanUpdate } from '../../redux/Actions';
import { loadUnmountLogistics } from '../../redux/Actions'
import addDriverOutline from '../../images/add-driver-outline.svg'
import { loadGetDcHoliday } from '../../redux/Actions';
import { blockInvalidChar } from '../../blockInvalidChar';
import vehicleNumber from '../../images/number-plate.png';
import factory from '../../images/factory.png'
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { loadDcSlotsAvailable } from '../../redux/Actions';
import getDay from 'react-datepicker'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 197
        }
    }
};


let slotAvailable = [
    {
        sf: "8:00:00",
        st: "9:00:00"
    },
    {
        sf: "17:00:00",
        st: "18:00:00"
    },
    {
        sf: "18:00:00",
        st: "19:00:00"
    },
    {
        sf: "19:00:00",
        st: "20:00:00"
    },
    {
        sf: "20:00:00",
        st: "21:00:00"
    },
    {
        sf: "21:00:00",
        st: "22:00:00"
    },
    {
        sf: "22:00:00",
        st: "23:00:00"
    }
];

function NewOrder(props) {
    const fileInputRef = useRef();
    const history = useHistory()
    let dispatch = useDispatch()

    const { vehicles } = useSelector(state => state.data)
    const { logisticsDetails } = useSelector(state => state.data)
    const { vendor } = useSelector(state => state.data)
    const singleDc = useSelector(state => state.data.singleDc.DC)
    console.log("singleDc", singleDc)
    const orderVendor = useSelector(state => state.data.orderVendor)
    const slots = useSelector(state => state.data.slots.data)
    const availableSlots = useSelector(state => state.data.availableSlots)
    const availability = availableSlots?.slot_available?.slot_splits
    const newLogs = useSelector(state => state.data.newLogs)
    const getDCHoliday = useSelector(state => state.dcUsers.getDCHoliday)
    const getVehicleDetails = useSelector(state => state.data.getVehicleDetails.api_response)
    const getNewOrder = useSelector(state => state.data.getNewOrderDetails)
    console.log("getNewOrder", getNewOrder, props)
    const deliveryCenterSlots = useSelector(state => state.data.deliveryCenterSlots.data)


    const [newLogistics, setNewLogistics] = useState({
        name: "",
        log_email: "",
        log_phone: ""
    })
    const [comments, setComments] = useState('')
    const [date_given, setDate_given] = useState("")
    const [dateGivenFormat, setDateGivenFormat] = useState("")
    const [date_of_dispatch, setDate_of_dispatch] = useState("")
    const [slot_to_arr, setSlot_to_arr] = useState([])
    const [shrink1, setShrink1] = useState(true)
    const [slot_from, setSlot_from] = useState("")
    const [slot_to, setSlot_to] = useState("")
    const [total_hours_req, setTotal_Hours_Req] = useState()
    const [door_no, setDoor_No] = useState()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [selectedFile, setSelectedFile] = useState()
    const [isFilePicked, setIsFilePicked] = useState(false)
    const [fileValue, setFileValue] = useState({})
    const [logistics, setLogistics] = useState(newLogistics?.name)
    const [hours, setHours] = useState()
    const [buttonLoading, setButtonLoading] = useState(false)
    const [driverError1, setDriverError1] = useState(false)
    const [driverError2, setDriverError2] = useState(false)
    const [driverError3, setDriverError3] = useState(false)
    const [addNewDriver, setAddNewDriver] = useState(false)
    const [newDays, setNewDays] = useState()
    const [newDisableDays, setNewDisableDays] = useState()
    const [dateGiven1, setDateGiven1] = useState()
    const [vehicleDriversInfo, setVehicleDriversInfo] = useState()
    const [dispatchToggle, setDispatchToggle] = useState();
    const [time, setTime] = useState(dayjs())
    const [fromTo, setFromTo] = useState('')
    const [selectTimeSlot, setSelectTimeSlot] = useState('')
    const [driversInfo, setDriversInfo] = useState([
        {
            driver_name: "",
            driver_contact: "",
            location_driver: ""
        },
        {
            driver_name: "",
            driver_contact: "",
            location_driver: ""
        },
        {
            driver_name: "",
            driver_contact: "",
            location_driver: ""
        }
    ])
    const [receiversInfo, setReceiversInfo] = useState([
        {
            name: "",
            date_of_receiving: ""
        }
    ])
    const { name, log_email, log_phone } = newLogistics;
    const [state, setState] = useState({
        order_no: "",
        po_number: "",
        number_of_items: "",
        number_of_boxes: "",
        vendorName: "",
        dc_selected: singleDc?.dc_number,
        vehicle_type: "",
        vehicle_no: "",
        factory_address: "",
        truckload: "",
        source_location: "",
        order_tracking_link: "",
        driverInfo: [
            {
                driver_name: "",
                driver_contact: "",
                location_driver: ""
            },
            {
                driver_name: "",
                driver_contact: "",
                location_driver: ""
            },
            {
                driver_name: "",
                driver_contact: "",
                location_driver: ""
            }
        ],
        receiverInfo: [
            {
                name: "",
                date_of_receiving: ""
            }
        ]

    })
    const { order_no, po_number, dc_selected, number_of_items, vehicle_type, number_of_boxes, vehicle_no, factory_address, order_tracking_link, truckload, source_location, vendorName, receiver_name, receiver_name2 } = state;


    useEffect(() => {
        dispatch(loadVehicles())
        dispatch(loadLogistics())
        dispatch(loadVendor())
        dispatch(loadOrderVendorId())
    }, [])

    useEffect(() => {
        if (date_given || time) {
            const dateTime = moment(date_given).format("YYYY-MM-DD")
            const timer = moment(time.$d).format('HH:mm:ss')
            const date_time = `${dateTime} ${timer}`
            setDateGivenFormat(date_time)
        }
    }, [time, date_given])

    useEffect(() => {
        if (getNewOrder.selectDate) {
            setDate_of_dispatch(getNewOrder?.selectDate)
            dispatch(loadDcSlotsAvailable(getNewOrder.selectDate, singleDc?.dc_number))
        }
    }, [getNewOrder.selectDate])

    useEffect(() => {
        setState({
            ...state, order_no: orderVendor.orderno
            // , po_number : orderVendor.invoiceno
        })
    }, [orderVendor])

    useEffect(() => {
        if (logistics) {
            let logisticsId = logisticsDetails?.logistics?.filter(item => item.name === logistics)
            dispatch(loadVehicelDetails(logisticsId[0]?.id))
            state.vehicle_no = ""
        }
    }, [logistics])

    useEffect(() => {
        if (logistics && vehicle_no) {
            let vehicleArray = getVehicleDetails?.vehicles?.filter(item => item.vehicleNumber === vehicle_no)
            setVehicleDriversInfo(vehicleArray)
            if (vehicleArray != null) {
                const orderLink = vehicleArray && `https://www.google.com/maps/@${vehicleArray[0].latitude},${vehicleArray[0].longitude},15z`
                state.order_tracking_link = orderLink
            }
            else {
                state.order_tracking_link = ""
            }
        }
    }, [logistics, vehicle_no])


    const remaining = slot_to.split(':')[0] - slot_from.split(':')[0]
    console.log("Remaining", remaining ? remaining : '')
    useEffect(() => {
        if (remaining) {
            setHours(remaining)
        }
    }, [remaining])

    useEffect(() => {
        if (hours) {
            setTotal_Hours_Req(hours)
        }
    }, [hours])

    useEffect(() => {
        if (getNewOrder.image) {
            setDoor_No(getNewOrder?.image)
        }
    }, [getNewOrder?.image])

    useEffect(() => {
        toast.configure()
        if (buttonLoading && newLogs.success) {
            setIsModalVisible(false)
            setLogistics(newLogistics.name)
            setNewLogistics("")
        }
    }, [buttonLoading, newLogs])

    useEffect(() => {
        if (singleDc) {
            dispatch(loadGetDcHoliday(singleDc.dc_number))
        }
    }, [singleDc])

    useEffect(() => {
        if (getDCHoliday) {
            setNewDays(getDCHoliday?.data?.map(data =>
                data?.dates.map(data1 => moment(data1).format('YYYY-MM-DD'))))
        }
    }, [getDCHoliday])

    useEffect(() => {
        if (newDays) {
            setNewDisableDays(newDays?.flat())
        }
    }, [newDays])


    function handledateOfDispatch(dateString) {
        setDate_given(dateString)
        if (moment(dateString).format('YYYY-MM-DD') > getNewOrder?.selectDate) {
            toast.warning('Date Of dispatch should not be post date of delivery', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        setDispatchToggle(true)
        setTime('')
    }

    const handleChangeComment = (e) => {
        setComments(e.target.value)
    }

    // const handleSlotFrom = (e) => {
    //     let arr = []
    //     let temp = 1
    //     const index = availableSlots?.slot_available?.slot_splits.map(i => i.sf).indexOf(e);
    //     const arr_length = availableSlots?.slot_available?.slot_splits.length
    //     let initial = parseInt(e.split(':')[0])
    //     arr.push(temp)
    //     for (let i = index + 1; i < arr_length; i++) {
    //         let sf = parseInt(availableSlots?.slot_available?.slot_splits[i].sf.split(':')[0])
    //         if (sf == initial + 1) {
    //             temp++
    //             arr.push(temp)
    //             initial++
    //         } else {
    //             break;
    //         }
    //     }
    //     setSlot_to_arr(arr)
    // }

    const handleSlotFrom = (e) => {
        setSlot_from(e.target.value)
        { getDateTimeDifference(date_of_dispatch, e.target.value, dateGivenFormat) }
        let arr = []
        const index = availableSlots?.slot_available?.slot_splits.map(i => i.sf).indexOf(e.target.value);
        const arr_length = availableSlots?.slot_available?.slot_splits.length
        let initial = parseInt(e.target.value.split(':')[0])
        arr.push(availableSlots?.slot_available?.slot_splits[index])
        for (let i = index + 1; i < arr_length; i++) {
            let sf = parseInt(availableSlots?.slot_available?.slot_splits[i].sf.split(':')[0])
            if (sf == initial + 1) {
                arr.push(availableSlots?.slot_available?.slot_splits[i])
                initial++
            } else {
                break;
            }
        }
        setSlot_to_arr(arr)
    }

    const handleSlotTo = (e) => {
        setSlot_to(e.target.value)
    }

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value })
        // if (name == "slot_from") {
        //     handleSlotFrom(e.target.value)
        // }
        setState({ ...state, [name]: value })
    }

    const handleLogisticsChange = (e, id) => {
        setLogistics(e.target.value)
    }

    const handleOrderInfoChange = (e) => {
        let { name, value } = e.target;
        if (name == "vendorName" || name == "receiver_name" || name == "receiver_name2") {
            setState({ ...state, [name]: value.replace(/[^A-Za-z ]/gi, "") })
        } else {
            setState({ ...state, [name]: value })
        }
    }

    const handleDriverInfoChange = (index, e) => {
        let { name, value } = e.target;
        let arr = [...driversInfo]
        arr[index] = {
            ...arr[index], [name]: value.replace(/[^A-Za-z ]/gi, "")
        }
        setDriversInfo(arr)
    }

    const handleDriverInfoChangeNumber = (index, e) => {
        let { name, value } = e.target;
        let arr = [...driversInfo]
        arr[index] = {
            ...arr[index], [name]: value
        }
        setDriversInfo(arr)
        if (arr[0].driver_contact.length !== 10) {
            setDriverError1(true)
        }
        else if (arr[0].driver_contact.length === 10) {
            setDriverError1(false)
        }
    }

    const handleDriverInfoChangeNumber1 = (index, e) => {
        let { name, value } = e.target;
        let arr = [...driversInfo]
        arr[index] = {
            ...arr[index], [name]: value
        }
        setDriversInfo(arr)
        if (arr[1].driver_contact.length !== 10) {
            setDriverError2(true)
        }
        else if (arr[1].driver_contact.length === 10) {
            setDriverError2(false)
        }
    }

    const handleDriverInfoChangeNumber2 = (index, e) => {
        let { name, value } = e.target;
        let arr = [...driversInfo]
        arr[index] = {
            ...arr[index], [name]: value
        }
        setDriversInfo(arr)
        if (addNewDriver) {
            if (arr[2].driver_contact.length !== 10) {
                setDriverError3(true)
            }
            else if (arr[2].driver_contact.length === 10) {
                setDriverError3(false)
            }
        }
    }

    const handleReceiverChange = (index, e) => {
        let { name, value } = e.target;
        let arr = [...receiversInfo]
        arr[index] = {
            ...arr[index], [name]: value
        }
        setReceiversInfo(arr)
    }

    const handleTotalHoursChange = (e) => {
        setTotal_Hours_Req(e.target.value)
    }

    const orderComment = {
        order_no: orderVendor?.orderno,
        user_message: comments,
    }
    const submitApiCall = (e) => {
        let arr = receiversInfo.map((item) => {
            return {
                name: singleDc.dc_contact,
                date_of_receiving: date_of_dispatch
            }
        })
        console.log("inside api call", driversInfo);
        dispatch(addVahanDetails({ ...state, date_given: dateGivenFormat, slot_from, door_no, logistics, total_hours_req: remaining, date_of_dispatch, driverInfo: driversInfo.filter((item, i) => item.driver_contact), receiverInfo: arr, selectedFile, orderComment }))
        const formData = new FormData();
        formData.append('invoice_file', selectedFile, selectedFile?.name)
        dispatch(loadInvoiceUpload(orderVendor?.orderno, formData, { dc_selected: state?.dc_selected, date_of_dispatch, door_no: door_no }))
        props.toggling()
        setState("")
        setLogistics("")
        setDate_given("")
        setSlot_from("")
        setDoor_No("")
        setTotal_Hours_Req("")
        setDate_of_dispatch("")
        setDriversInfo("")
        setSelectedFile("")
        setDateGivenFormat("")
    }

    const handleSubmit = (e) => {
        console.log("inside submit");
        toast.configure()
        e.preventDefault()
        if (!po_number) {
            toast.error("Please enter po number", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!number_of_items) {
            toast.error("Please enter number of items", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!number_of_boxes) {
            toast.error("Please enter number of boxes", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!vehicle_type) {
            toast.error("Please select vehicle type", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!vehicle_no) {
            toast.error("Please enter vehicle number", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!logistics) {
            toast.error("Please select logistics", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!slot_from) {
            toast.error("Please select slot", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!date_given) {
            toast.error("Please enter date of dispatch", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!driversInfo) {
            toast.error("Please enter driver details", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!date_of_dispatch) {
            toast.error("Please enter planned date", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!receiversInfo) {
            toast.error("Please enter receiver details", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!truckload) {
            toast.error("Please enter truckload status", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!order_tracking_link) {
            toast.error("Please enter tracking link", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        if (!factory_address) {
            toast.error("Please enter factory address", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else if (driversInfo[0].driver_name === '' || driversInfo[0].driver_contact.length !== 10 || driversInfo[0].location_driver === '') {
            if (driversInfo[0].driver_name === '') {
                toast.warning('Drivers Name Is Required!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
            }
            if (driversInfo[0].driver_contact.length !== 10) {
                toast.warning('Please Add A Valid Contact Number For Driver', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
            }
            if (driversInfo[0].location_driver === '') {
                toast.warning('Driver location is required', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
            }
            if (addNewDriver) {
                console.log("inside validation");
                if (driversInfo[1].driver_name === '' || driversInfo[1].driver_contact.length !== 10 || driversInfo[1].location_driver === '') {
                    if (driversInfo[1].driver_name === '') {
                        toast.warning('Drivers Name Is Required!', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                        });
                    }
                    if (driversInfo[1].driver_contact.length !== 10) {
                        toast.warning('Please Add A Valid Contact Number For Driver', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                        });
                    }
                    if (driversInfo[1].location_driver === '') {
                        toast.warning('Driver location is required', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                        });
                    }
                }
            }
        }
        else {
            if (driversInfo[1].driver_contact || driversInfo[1].driver_name || driversInfo[1].location_driver) {
                if (driversInfo[1].driver_name === '') {
                    toast.warning('Drivers Name Is Required!', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                else if (driversInfo[1].driver_contact.length !== 10) {
                    toast.warning('Please Add A Valid Contact Number For Driver', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                else if (driversInfo[1].location_driver === '') {
                    toast.warning('Driver location is required', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                }
                else {
                    submitApiCall(e)
                }
                console.log("inside submit");
            } else {
                submitApiCall(e)
            }

        }
    }

    const TimeSlot = (fromTo) => {
        if (fromTo.length > 1) {
            const sortedValue = `${fromTo[0].split("-")[0]} - ${fromTo[fromTo.length - 1].split("-")[1]}`
            const sorted = `${fromTo[0].split("-")[0]}`
            const sortedSlotFrom = sorted.trim()
            setSlot_from(sortedSlotFrom)
            const start = moment(`${fromTo[0].split("-")[0]}`, "HH:mm")
            const end = moment(`${fromTo[fromTo.length - 1].split("-")[1]}`, 'HH:mm')
            var duration = moment.duration(end.diff(start));
            var hours = parseInt(duration.asHours())
            setTotal_Hours_Req(hours)
        }
    }

    const getTime = (str) => {
        let a = str?.split(":")
        let r = ""
        const c = (k) => parseInt(k) < 10 ? "0" + k : k
        if (parseInt(a[0]) == 24 || parseInt(a[0]) == 0) {
            r = "12:" + a[1] + " AM"
        } else if (parseInt(a[0]) == 12) {
            r = "12:" + a[1] + " PM"
        } else if (parseInt(a[0]) > 12) {
            let b = parseInt(a[0]) - 12
            r = c(parseInt(a[0]) - 12) + ":" + a[1] + " PM"
        } else {
            r = a[0] + ":" + a[1] + " AM"
        }
        return r
    }

    const addLogisticsHandler = () => {
        setIsModalVisible(true)
    }

    const handleInputLogistics = (e) => {
        let { name, value } = e.target;
        setNewLogistics({ ...newLogistics, [name]: value })
    }

    const submitHandlerLogitics = (e) => {
        toast.configure()
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        e.preventDefault()
        if (!name || !log_email || !log_phone) {
            toast.error("Please Enter All Input Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
            setIsModalVisible(true)
        }
        else if (!log_email || regex.test(log_email) === false) {
            toast.warning('Please Add A Valid Email Address', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
            setIsModalVisible(true)
        }
        else if (log_phone.length != 10) {
            toast.warning('Please Add A Valid Contact Number', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
            setIsModalVisible(true)
        }
        else {
            dispatch(loadLogisticsAdding(newLogistics))
            setButtonLoading(true)
        }
    }


    const handleCancel = () => {
        setIsModalVisible(false);
        setNewLogistics("")
    };


    const changeFileHandler = (event) => {
        toast.configure()
        console.log("targettedFiles", event.target.files[0])
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
        console.log("Checking event", event.target.files)
        const fileExtension = event.target.files[0].name.split('.').pop();
        console.log("fileExtension", fileExtension)
        if (fileExtension === 'pdf') {
            const formData = new FormData();
            console.log("formData", formData)
            formData.append('invoice_file', event.target.files[0], event.target.files[0].name)
            toast.success('File uploaded Successfully', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
            })
        }
        else {
            toast.error('Please upload pdf file only', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
            })
        }
        event.currentTarget.value = null
        // dispatch(loadInvoiceUpload(formData))
    }

    const msToTime = (msDuration) => {
        const h = Math.floor(msDuration / 1000 / 60 / 60);
        const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
        const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);
        // To get time format 00:00:00
        const seconds = s < 10 ? `0${s}` : `${s}`;
        const minutes = m < 10 ? `0${m}` : `${m}`;
        const hours = h < 10 ? `0${h}` : `${h}`;

        if (hours == "00" && minutes == "00" && seconds == "00") {
            return "On Time"
        }

        const hr = parseInt(hours) % 24
        const day = Math.floor(parseInt(hours) / 24)
        let daycal = day > 1 ? day + " days " : day > 0 ? day + " day " : ""
        let hh = daycal + "" + (hr > 1 ? hr + " hours " : hr > 0 ? hr + " hour " : "")
        let mm = parseInt(minutes) > 1 ? minutes + " minutes " : parseInt(minutes) > 0 ? minutes + " minute " : ""
        let ss = parseInt(seconds) > 1 ? seconds + " seconds" : parseInt(seconds) > 0 ? seconds + " second" : ""
        return hh + '' + mm;
    }

    const getDateTimeDifference = (delivery_date, slot_from, dispatch_date_time) => {
        const delivery_date_time = new Date(delivery_date + ' ' + slot_from);
        dispatch_date_time = new Date(dispatch_date_time);
        let timer = delivery_date_time - dispatch_date_time
        if (dispatch_date_time > delivery_date_time) {
            setDateGiven1('00:00:00')
            toast.warning('Date Of dispatch should not be post date of delivery', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else {
            return setDateGiven1(msToTime(timer).trim())
        }
        // return msToTime(timer).trim();
    };

    const handleTimeSlot = (e) => {
        console.log("e.target.value", e.target.value.split('-')[0].trim())
        setSelectTimeSlot(e.target.value)
        setSlot_from(e.target.value.split('-')[0].trim())
        { getDateTimeDifference(date_of_dispatch, e.target.value.split('-')[0].trim(), dateGivenFormat) }
    }

    const dateTime = `${date_of_dispatch} ${slot_from}`

    let datesssss = new Date(dateTime) - date_given


    // const distributed = getSingleOrder?.dc?.dc_number ? getSingleOrder?.dc?.dc_number : ''
    // useEffect(() => {
    //     if (delivery_date) {
    //         dispatch(loadDcSlotsAvailable(delivery_date, distributed))
    //     }
    // }, [delivery_date])

    const selectedDoors = deliveryCenterSlots ? deliveryCenterSlots?.find(item => item.dc_door_no === getNewOrder?.image) : null
    useEffect(() => {
        if (selectedDoors) {
            let arr = []
            const sravan = selectedDoors.slotAvail.map((item, i) => {
                arr.push(`${item.slot_from} - ${item.slot_to}`)
            })
            setFromTo(arr)
        }
    }, [selectedDoors])
    console.log("slot_from", slot_from, fromTo)
    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0;
    };

    return (
        <div>
            <section className='container container-border'>
                <div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            {/* <div className="col-md-12"> */}
                            <div className="row">
                                <div className="col-md-12 orderNo-fields">
                                    <TextField id="standard-basic" label="Order Number" name="order_no" value={orderVendor?.orderno} variant="standard" type="text" readOnly className="order-details-width"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#FCB713",

                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    color: "#FCB713"
                                                }
                                            }
                                        }} InputLabelProps={{ shrink: shrink1 }} />
                                    <TextField id="standard-basic" label="PO Number" name="po_number" value={po_number} variant="standard" type="text" className="order-details-width text-field" onChange={handleInputChange} InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} />
                                    <TextField id="standard-basic" label="Dc Selected" name="dc_selected" value={singleDc?.dc_name} readOnly variant="standard" type="text" className="order-details-width text-field" InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} />
                                    <TextField id="standard-basic" label="Number of Items" name="number_of_items" value={number_of_items} variant="standard" type="number" className="order-details-width text-field" onChange={handleInputChange} onKeyDown={blockInvalidChar} InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} />
                                    <TextField id="standard-basic" label="Number of boxes" name="number_of_boxes" value={number_of_boxes} variant="standard" type="number" className="order-details-width text-field" onChange={handleInputChange} onKeyDown={blockInvalidChar} InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} />

                                </div>
                            </div>
                            <div className='loaded-truck-radio-btns-div orderNo-fields col-md-8 row'>
                                <div className='col-md-4'>
                                    <input type="radio" id="html" name="truckload" value="Less than truckload" onChange={handleInputChange} />
                                    <label for="html">Less than truckload</label>
                                </div>
                                <div className='col-md-4'>
                                    <input type="radio" id="css" name="truckload" value="Full truck load" onChange={handleInputChange} />
                                    <label for="css">Full truck load</label><br></br>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>
                        <div className='vendor-details'>
                            <div className='view-vendor'>
                                <h6 className='fonts'>Vendor Details</h6>
                            </div>
                            <div className='vendor-inputs'>
                                <TextField label="Name" name="vendorName" value={vendor?.vendor?.name} variant="standard" className="order-details-width" id="text-color" onChange={handleInputChange} InputLabelProps={{
                                    sx: {
                                        color: "#FCB713",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "#FCB713"
                                        }
                                    }
                                }} InputLabelProps={{ shrink: shrink1 }} />
                                <TextField label="Email ID" value={vendor?.vendor?.email} variant="standard" className="order-details-width text-field" onChange={handleInputChange} InputLabelProps={{
                                    sx: {
                                        color: "#FCB713",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "#FCB713"
                                        }
                                    }
                                }} InputLabelProps={{ shrink: shrink1 }} />
                                <div className="vendor-date-given">
                                    <label className="new-label-dispatch dispatch-label">Date of Dispatch</label>
                                    <DatePicker
                                        placeholderText="Date of Dispatch"
                                        selected={date_given}
                                        minDate={moment().toDate()}
                                        // maxDate={moment().toDate()}
                                        // showTimeSelect
                                        // timeFormat="HH:mm"
                                        // timeIntervals={5}
                                        // timeCaption="Time"
                                        // dateFormat="yyyy-MM-dd h:mm aa"
                                        dateFormat="yyyy-MM-dd"
                                        onChange={dispatch => handledateOfDispatch(dispatch)}
                                        className="dateVendors given_date"
                                        // excludeDates={newDisableDays?.map((index) => new Date([index]))}
                                        filterDate={isWeekday}
                                    />
                                </div>
                                <div className='order-details-width text-field timepicker-div'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopTimePicker
                                            label="Dispatch time"
                                            value={time ? time : ''}
                                            inputFormat="HH:mm:ss"
                                            onChange={(newValue) => {
                                                setTime(newValue);
                                            }}
                                            className="timepicker"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {/* <FormControl variant="standard" className="slotFrom-dropdown order-details-width text-field">
                                    <InputLabel id="demo-simple-select-standard-label" className="label">Slot From</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={slot_from}
                                        name="slot_from"
                                        // name="order_status"
                                        onChange={handleSlotFrom}
                                        MenuProps={MenuProps}
                                    >
                                        {availableSlots?.slot_available?.slot_splits.map((slots) => (
                                            <MenuItem value={slots.sf} className='wheeler-item-font'>{getTime(slots.sf)}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard" className="slotFrom-dropdown order-details-width text-field slot-to-mt">
                                    <InputLabel id="demo-simple-select-standard-label" className="label">Slot To</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={slot_to}
                                        // name="order_status"
                                        onChange={handleSlotTo}
                                        MenuProps={MenuProps}
                                    >
                                        {slot_to_arr.map((slots) => (
                                            <MenuItem value={slots.st} className='wheeler-item-font'>{getTime(slots.st)}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl> */}


                                {/* <TimeSlotDropdown slotAvailable={availability} childFunction={TimeSlot} /> */}
                                {/* <TextField id="standard-basic" label="Total Hours Required" variant="standard" name="total_hours_req" value={remaining} className="order-details-width text-field spacing-mt" InputLabelProps={{
                                    sx: {
                                        color: "#FCB713",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "#FCB713"
                                        }
                                    }
                                }} InputLabelProps={{ shrink: shrink1 }} /> */}
                                {/* <FormControl variant="standard" sx={{ ml: 5, width: 190 }}>
                                    <InputLabel id="demo-simple-select-standard-label">slot from and to</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={slot_from}
                                        name="slot_from"
                                        onChange={handleSlotChange}
                                        // label="Age"
                                        multiple
                                    >
                                        {availableSlots && availableSlots?.slot_available && availableSlots?.slot_available?.slot_splits && availableSlots?.slot_available?.slot_splits.map((slots) => (
                                            <MenuItem value={slots.sf +","+slots.st}>{slots.sf}-{slots.st}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl> */}
                                <TextField id="standard-basic" label="Planned date" variant="standard" name="date_of_dispatch" value={getNewOrder?.selectDate}
                                    // onChange={(e) => handledateOfDelivery(e)} 
                                    className="order-details-width text-field" InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} InputLabelProps={{ shrink: shrink1 }} />
                                <div>
                                    <Button onClick={() => fileInputRef.current.click()} className='vahan-header-upload'>
                                        UPLOAD INVOICE
                                    </Button>
                                    <input onChange={changeFileHandler} multiple={false} ref={fileInputRef} type='file' hidden />
                                </div>
                            </div>
                        </div>
                        <div className="vehicle-details col-sm-12">
                            <div className='track-vehicle row'>
                                <div className='track-vehicle col-sm-3'>
                                    <div>
                                        <img src={Logistics} alt="Logistics" className='deliveryTruck1' />
                                    </div>
                                    <div className="vehicle-input">
                                        <FormControl variant="standard" sx={{ width: 197 }} >
                                            <InputLabel id="demo-simple-select-standard-label">Select Logistics</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={logistics}
                                                name="logistics"
                                                label="Select Logistics or Enter Details"
                                                onChange={handleLogisticsChange}
                                                MenuProps={MenuProps}
                                            >
                                                {
                                                    logisticsDetails?.logistics && logisticsDetails?.logistics.length && logisticsDetails?.logistics?.map((item) => (
                                                        <MenuItem value={item.name} key={item.id} className="wheeler-item-font">{item.name}</MenuItem>
                                                    ))
                                                }
                                                <MenuItem onClick={addLogisticsHandler}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='track-vehicle col-sm-3'>
                                    <div>
                                        <img src={vehicleNumber} alt="vehicleNumber" className='deliveryTruck1' />
                                    </div>
                                    {getVehicleDetails ?
                                        <div className="vehicle-input">
                                            <div>
                                                <FormControl variant="standard" sx={{ width: 197 }} >
                                                    <InputLabel id="demo-simple-select-standard-label">Select vehicle Number</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={vehicle_no}
                                                        name="vehicle_no"
                                                        onChange={handleInputChange}
                                                        // label="Select Vehicle Type"
                                                        className='labeling'
                                                        MenuProps={MenuProps}
                                                    >
                                                        {
                                                            getVehicleDetails?.vehicles?.map((item) => (
                                                                <MenuItem value={item?.vehicleNumber} className="wheeler-item-font">{item?.vehicleNumber}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div> :
                                        <div className="vehicle-input">
                                            <div>
                                                <TextField sx={{ width: 197 }} id="standard-basic" label="Enter vehicle Number" name="vehicle_no" value={vehicle_no} type="text" onChange={handleInputChange} variant="standard" className="order-details-width enter-vehicle" />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='track-vehicle col-sm-3'>
                                    <div>
                                        <img src={deliveryTruck} alt="deliveryTruck" className='deliveryTruck' />
                                    </div>
                                    <div>
                                        <div className="vehicle-input">
                                            <div>
                                                <FormControl variant="standard" sx={{ width: 197 }} >
                                                    <InputLabel id="demo-simple-select-standard-label">Select Vehicle Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={vehicle_type}
                                                        name="vehicle_type"
                                                        onChange={handleInputChange}
                                                        label="Select Vehicle Type"
                                                        className='labeling'
                                                        MenuProps={MenuProps}

                                                    >
                                                        {
                                                            vehicles?.vehicles && vehicles?.vehicles.length && vehicles?.vehicles.map((item) => (
                                                                <MenuItem value={item.id} className="wheeler-item-font">{item?.wheeler}-{item?.type}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                        </div>
                                    </div>
                                </div>
                                <div className='track-vehicle col-md-3'>
                                    <div>
                                        <img src={deliveryDateTime} alt="deliveryDateTime" className='deliveryTruck2' />
                                    </div>
                                    <div className="vehicle-input">
                                        <TextField label="Transit Time" name="date_given1" value={dateGiven1 ? dateGiven1 : ''} variant="standard" className="order-details-width enter-vehicle" InputLabelProps={{
                                            sx: {
                                                color: "#FCB713",
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    color: "#FCB713"
                                                }
                                            }
                                        }} InputLabelProps={{ shrink: shrink1 }} />
                                    </div>
                                </div>
                            </div>
                            <div className='track-vehicle factory-main-div'>
                                <div>
                                    <img src={factory} alt="factory" className='deliveryTruck1' />
                                </div>
                                <div className="factory-address-text-field-div">
                                    <TextField id="standard-basic" label="Factory Location" name="factory_address" value={factory_address} type="text" onChange={handleInputChange} variant="standard" className="factory-address-text-field" />
                                </div>
                            </div>
                            <div className='all-driver-details-container row '>
                                <div className='driver'>
                                    <div>
                                        <img src={driver} alt="driver" className='deliveryTruck3 driver-image' />
                                    </div>
                                    <div>
                                        <div className='driverInput col-md-12'>
                                            <div className='col-md-4'>
                                                <TextField id="standard-basic" label="Driver Name" name="driver_name" value={driversInfo[0].driver_name} type="text" variant="standard" onChange={(event) => handleDriverInfoChange(0, event)}
                                                    className="order-details-width drivers" />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField sx={{ mt: -1 }} id="standard-basic" label="Mobile Number" name="driver_contact" value={driversInfo[0].driver_contact} type="number" onChange={(event) => handleDriverInfoChangeNumber(0, event)}
                                                    variant="standard" className="order-details-width drivers"
                                                    onInput={(e) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    }} error={driverError1} />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField id="standard-basic" label="Location" name="location_driver" value={driversInfo[0].location_driver} type="text" variant="standard" onChange={(event) => handleDriverInfoChange(0, event)} className="order-details-width drivers" />
                                            </div>
                                        </div>
                                        <div className='driverInputMargin col-md-12'>
                                            <div className='col-md-4'>
                                                <TextField id="standard-basic" label="Driver Name" name="driver_name" value={driversInfo[1].driver_name} type="text" variant="standard" onChange={(event) => handleDriverInfoChange(1, event)} className="order-details-width drivers" />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField sx={{ mt: -1 }} id="standard-basic" label="Mobile Number" name="driver_contact" value={driversInfo[1].driver_contact} type="number" onKeyDown={blockInvalidChar} onChange={(event) => handleDriverInfoChangeNumber1(1, event)} variant="standard" className="order-details-width drivers"
                                                    onInput={(e) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    }} error={driverError2} />
                                            </div>
                                            <div className='col-md-4'>
                                                <TextField id="standard-basic" label="Location" name="location_driver" value={driversInfo[1].location_driver} type="text" variant="standard" onChange={(event) => handleDriverInfoChange(1, event)} className="order-details-width drivers" />
                                            </div>
                                        </div>
                                        {addNewDriver ?
                                            <div className='driverInputMargin col-md-12'>
                                                <div className='col-md-4'>
                                                    <TextField id="standard-basic" label="Driver Name" name="driver_name" value={driversInfo[2].driver_name} type="text" variant="standard" onChange={(event) => handleDriverInfoChange(2, event)} className="order-details-width drivers" />
                                                </div>
                                                <div className='col-md-4'>
                                                    <TextField sx={{ mt: -1 }} id="standard-basic" label="Mobile Number" name="driver_contact" value={driversInfo[2].driver_contact} type="number" onKeyDown={blockInvalidChar} onChange={(event) => handleDriverInfoChangeNumber2(2, event)} variant="standard" className="order-details-width drivers"
                                                        onInput={(e) => {
                                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                        }} error={driverError3} />
                                                </div>
                                                <div className='col-md-4'>
                                                    <TextField id="standard-basic" label="Location" name="location_driver" value={driversInfo[2].location_driver} type="text" variant="standard" onChange={(event) => handleDriverInfoChange(2, event)} className="order-details-width drivers" />
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section>
                            <div className='receiver-new-order-comp'>
                                <h5 className='fonts'>Receiver</h5>
                                <TextField id="standard-basic" label="Receiver Name" name="name" value={singleDc?.dc_contact}
                                    // onChange={(event) => handleReceiverChange(0, event)} 
                                    variant="standard" className="order-details-width" InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} />
                                <TextField id="standard-basic" label="Dc Name" value={singleDc?.dc_name} variant="standard" className="order-details-width text-field" InputLabelProps={{
                                    sx: {
                                        color: "#FCB713",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "#FCB713"
                                        }
                                    }
                                }} InputLabelProps={{ shrink: shrink1 }} />
                                <TextField id="standard-basic" label="Door Number" name="door_no" value={getNewOrder?.image} type="number" onKeyDown={blockInvalidChar}
                                    variant="standard" className="order-details-width text-field" InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} />
                                <TextField id="standard-basic" label="Date of receiving" name="date_of_receiving" value={date_of_dispatch}
                                    // onChange={(event) => handleReceiverChange(0, event)} 
                                    variant="standard" className="order-details-width text-field slot-to-mt" InputLabelProps={{
                                        sx: {
                                            color: "#FCB713",
                                            [`&.${inputLabelClasses.shrink}`]: {
                                                color: "#FCB713"
                                            }
                                        }
                                    }} InputLabelProps={{ shrink: shrink1 }} />
                                <TextField id="standard-basic" label="Contact Number" name="city" value={singleDc?.contact_number} variant="standard" className="order-details-width text-field" InputLabelProps={{
                                    sx: {
                                        color: "#FCB713",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "#FCB713"
                                        }
                                    }
                                }} InputLabelProps={{ shrink: shrink1 }} />
                                <div style={{ margin: '10px' }}>
                                    <FormControl variant="standard" sx={{ width: 197 }} >
                                        <InputLabel id="demo-simple-select-standard-label">Select slot</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={selectTimeSlot}
                                            onChange={handleTimeSlot}
                                            MenuProps={MenuProps}
                                        >
                                            {fromTo && fromTo?.map((doors) => (
                                                <MenuItem value={doors}>{doors}</MenuItem>
                                            ))}
                                            {/* <MenuItem value='9:00:00' key='9:00 - 13:00:00' className="wheeler-item-font">9:00 - 1:00</MenuItem>
                                            <MenuItem value='14:00:00' key='14:00 - 18:00:00' className="wheeler-item-font">2:00 - 6:00</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                </div>
                                <TextField id="standard-basic" label="Address" name="city" value={singleDc?.address} variant="standard" className="address-text-field spacing-mt" InputLabelProps={{
                                    sx: {
                                        color: "#FCB713",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            color: "#FCB713"
                                        }
                                    }
                                }} InputLabelProps={{ shrink: shrink1 }} />
                            </div>
                        </section>
                        <div className='enter-tracking-link-div'>
                            <p>Tracking</p>
                            <div>
                                <input type="text" placeholder='Enter track link' name="order_tracking_link" value={order_tracking_link} onChange={handleInputChange} />
                                {/* <button onClick={addTrackingOnClick}>></button> */}
                            </div>
                        </div>
                        <div className='order-details-submit-btn'>
                            <button className='button'>Submit</button>
                        </div>
                    </form>
                </div>
            </section >
            {
                isModalVisible &&
                <div className='modal111'>
                    <Modal
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        width={900}
                        footer={[
                            <Button key="submit" type="primary" onClick={(e) => submitHandlerLogitics(e)} className='vahan-edit-update'>
                                Add
                            </Button>,
                        ]}
                        className="logisiticsModal"
                    >
                        <div className='add-logistics'>
                            <h4>ADD LOGISTIC DETAILS</h4>
                        </div>
                        <div>
                            <TextField id="standard-basic" label="Logistics Name" variant="standard" name="name" value={name} onChange={handleInputLogistics} className="order-details-width text-field spacing-mt" InputLabelProps={{
                                sx: {
                                    color: "#FCB713",
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "#FCB713"
                                    }
                                }
                            }} />
                            <TextField id="standard-basic" label="Logistics Email" variant="standard" name="log_email" value={log_email} onChange={handleInputLogistics} type="email" className="order-details-width text-field spacing-mt" InputLabelProps={{
                                sx: {
                                    color: "#FCB713",
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "#FCB713"
                                    }
                                }
                            }} />
                            <TextField id="standard-basic" label="Logistics Phone Number" variant="standard" name="log_phone" value={log_phone} onChange={handleInputLogistics} type="number" onKeyDown={blockInvalidChar} className="order-details-width text-field spacing-mt" InputLabelProps={{
                                sx: {
                                    color: "#FCB713",
                                    [`&.${inputLabelClasses.shrink}`]: {
                                        color: "#FCB713"
                                    }
                                }
                            }}
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                }}
                            />
                        </div>
                    </Modal>
                </div>
            }
            <ToastContainer />
        </div >
    )
}

export default NewOrder
