import Cookies from 'js-cookie'

export const setCookie = (name,value) => {
    console.log("name,value",name,value)
    Cookies.set(name,value)
}


export const getCookie = (name) => {
    console.log("dfbgjksdhfj",Cookies.get(name))
    return Cookies.get(name)
}