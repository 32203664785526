// import { Popover, Steps } from 'antd';
// import React from 'react';
// const { Step } = Steps;



// const custom = {
//     "orders": {
//         "order_no": "O5682644",
//         "po_number": "erty4345",
//         "date_of_dispatch": "2022-08-25",
//         "date_given": "2022-08-25 15:30:00",
//         "truckload": "Less than truckload",
//         "factory_address": "No 427, next to CMR Law College, Pilla Reddy Nagar, OMBR Layout, Banaswadi, Bengaluru, Karnataka 560043",
//         "logistics": "Apollo Fleet",
//         "vehicle_no": "KA 00 AA 9876",
//         "total_hours_req": "3",
//         "door_no": 3,
//         "slot_from": "08:00:00",
//         "number_of_items": 30,
//         "number_of_boxes": 20,
//         "invoice_path": null,
//         "order_tracking_link": "https://www.google.com/maps/place/The+Retail+Insights+%7C+Omnichannel+Technology+Partner/@13.0055333,77.652608,15z/data=!4m5!3m4!1s0x0:0x358e97448215ec3d!8m2!3d13.0055333!4d77.652608",
//         "vahan_order_status": "Order Departed",
//         "delay_status": null,
//         "vendor": {
//             "city": "Vijayawada",
//             "name": "Lashmienterprises",
//             "email": "Lashmilashmi@Gmail.com",
//             "phone": "6666666666",
//             "state": "Andhra Pradesh",
//             "vendor_id": "VEND/000000107"
//         },
//         "dc": {
//             "city": null,
//             "state": null,
//             "dc_name": "Pondicherry",
//             "latitude": null,
//             "dc_number": "123456",
//             "longitude": null
//         },
//         "orderStatus": {
//             "placed_at": "2022-08-25 15:25:18.000000",
//             "placed_by": "Lashmienterprises",
//             "loading_at": "2022-08-25 15:25:18.000000",
//             "loading_by": "Lashmienterprises",
//             "reached_at": null,
//             "reached_by": null,
//             "delivered_at": null,
//             "delivered_by": null,
//             "unloading_at": null,
//             "unloading_by": null,
//             "order_departed_at": "2022-08-25 15:25:18.000000",
//             "order_departed_by": "Lashmienterprises"
//         },
//         "vehicle": {
//             "type": "Dump truck",
//             "wheeler": "14 Wheeler"
//         },
//         "logistics_detail": {
//             "log_email": "Apollofleet@gmail.com",
//             "log_phone": "9858773994",
//             "logistics_name": "Apollo Fleet"
//         },
//         "dc_doors": {
//             "slot_to": "11:00:00.000000",
//             "order_no": "O5682644",
//             "dc_number": "123456",
//             "slot_from": "08:00:00.000000",
//             "dc_door_no": 3
//         },
//         "eta": "30 min",
//         "eta_remaining": "",
//         "live_track_info": {
//             "delivered": false,
//             "delay_status": null,
//             "eta": "-1 days 4 hrs 30 min",
//             "live_eta_update": false
//         },
//         "hisorder": true,
//         "receiverInfo": [
//             {
//                 "name": "Lokesh",
//                 "date_of_receiving": "2022-08-25",
//                 "order_no": "O5682644"
//             }
//         ],
//         "driverInfo": [
//             {
//                 "id": 1623,
//                 "driver_name": "venu",
//                 "driver_contact": "9835358353",
//                 "location_driver": "gudur",
//                 "order_no": "O5682644",
//                 "createdAt": "2022-08-25 15:25:18"
//             },
//             {
//                 "id": 1624,
//                 "driver_name": "abc",
//                 "driver_contact": "9876567897",
//                 "location_driver": "dfghj",
//                 "order_no": "O5682644",
//                 "createdAt": "2022-08-25 17:57:31"
//             }
//         ],
//         "deliveryComment": []
//     }
// }

// // const customDot = (dot, { status, index }) => (
// //   <Popover
// //     content={
// //       <span>
// //         step {index} status: {status}
// //       </span>
// //     }
// //   >
// //     {dot}
// //   </Popover>
// // );

// const Steps11 = (props) => (
//     < Steps current={0} labelPlacement="vertical" >
//         <Step title={props.tracking.order_no === 'Order Departed' ? custom.orders.vahan_order_status : ''} description={<div><span>gsdfhdgfhsdf</span><p>gfdghfdsgh</p></div>} />
//         <Step title={custom.orders.vahan_order_status === 'Order Departed' ? custom.orders.vahan_order_status : ''} description={<div><span>gsdfhdgfhsdf</span><p>gfdghfdsgh</p></div>} />
//         <Step title={custom.orders.vahan_order_status === 'Order Departed' ? custom.orders.vahan_order_status : ''} description={<div><span>gsdfhdgfhsdf</span><p>gfdghfdsgh</p></div>} />
//         {/* <Step title="In Progress" description="You can hover on the dot." />
//     <Step title="Waiting" description="You can hover on the dot." />
//     <Step title="Waiting" description="You can hover on the dot." /> */}
//     </Steps >
// );

// <Steps current={1} labelPlacement="vertical">
//     <Step title="Finished" description="This is a description." />
//     <Step title="In Progress" description="This is a description." />
//     <Step title="Waiting" description="This is a description." />
// </Steps>

// export default Steps11;


import { Popover, Steps } from 'antd';
import React from 'react';
import moment from 'moment';
import Success from '../../images/success.png'
import { useSelector } from 'react-redux';

const { Step } = Steps;



const Steps11 = (props) => {
    const getSingleOrder = useSelector(state => state.data.getSingleOrderDetails)
    console.log("getSingleOrder in steps",getSingleOrder)
    console.log("inside steps",props)
    return (
        <div>
            <Steps current={getSingleOrder?.vahan_order_status === "Order Departed" ? 0 : getSingleOrder?.vahan_order_status === "Unloading" ? 1 : getSingleOrder?.vahan_order_status === "Delivered" ? 2 : 0} labelPlacement="vertical" >
                <Step description={getSingleOrder?.vahan_order_status === "Order Departed" || getSingleOrder?.vahan_order_status === "Unloading" || getSingleOrder?.vahan_order_status === "Delivered" ? <div className="progress-text"><h6 className="progress-title">Order Placed</h6><p>{getSingleOrder?.orderStatus?.placed_at ? moment(getSingleOrder?.orderStatus?.placed_at).format('YYYY-MM-DD hh:mm:ss') : ''}</p>
                    <p>{getSingleOrder?.orderStatus?.placed_by}</p>
                </div> : ''} className="steps-icon-1" />
                <Step description={<div className="progress-text"><h6 className="progress-title">Reached</h6><p>{getSingleOrder?.orderStatus?.reached_at ? moment(getSingleOrder?.orderStatus?.reached_at).format('YYYY-MM-DD hh:mm:ss') : ''} </p>
                    <p> {getSingleOrder?.orderStatus?.reached_by}</p>
                </div>} className="steps-icon-1" />
                <Step description={<div className="progress-text"><h6 className="progress-title">Delivered</h6><p>{getSingleOrder?.orderStatus?.delivered_at ? moment(getSingleOrder?.orderStatus?.delivered_at).format('YYYY-MM-DD hh:mm:ss') : ''}</p>
                    <p> {getSingleOrder?.orderStatus?.delivered_by}</p>
                </div>} className="steps-icon-1" />
            </Steps>
        </div>
    )
}

export default Steps11