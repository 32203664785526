import React, { useEffect, useState, useRef } from 'react'
import './Header.css'
import { Link, useHistory } from 'react-router-dom'
import logo from "../../.././src/images/Group.png"
import userlogo from "../../.././src/images/User.svg"
import headerlogo from "../../.././src/images/Header.png"
import { NavLink } from 'react-router-dom';
import { Modal, Button } from 'antd';
import profilePhoto from '../../../src/images/profile.png'
import add from '../../../src/images/add.svg'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { profilePhotoAdded } from '../../redux/Actions'
import { useDispatch, useSelector } from 'react-redux'
import { loadProfileDetails } from '../../redux/Actions'
import { getCookie } from '../utils/Cookie'
import { resetOrderNumbersForPdf } from '../../redux/Actions'
import Cookies from 'js-cookie'


const Header = (props) => {
    let user = JSON.parse(getCookie("user-info"))
    const fileInputRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch()

    const profile = useSelector(state => state.data.profile.user_profile)

    const [clicked, setClicked] = useState(true)
    const [isActive, setIsActive] = useState(user?.role === "vendor" ? true : false)
    const [isActiveOne, setIsActiveOne] = useState(false)
    const [isActiveTwo, setIsActiveTwo] = useState(false)
    const [isActiveThree, setIsActiveThree] = useState(false)
    const [isprofileModal, setIsProfileModal] = useState(false)
    const [selectedFile, setSelectedFile] = useState()
    const [isFilePicked, setIsFilePicked] = useState(false)

    useEffect(() => {
        dispatch(loadProfileDetails())
    }, [])

    const logOut = () => {
        Cookies.remove("user-info")
        history.push("/")
        dispatch(resetOrderNumbersForPdf())
    }
    const changeColor = () => {
        setIsActiveTwo(false)
        setIsActiveThree(false)
        setIsActiveOne(false)
        setIsActive(false)
        setClicked(true)

    }
    const changeColorOne = () => {
        setClicked(false)
        setIsActiveTwo(false)
        setIsActiveThree(false)
        setIsActiveOne(false)
        setIsActive(true)
    }
    const changeColorTwo = () => {
        setClicked(false)
        setIsActive(false)
        setIsActiveTwo(false)
        setIsActiveThree(false)
        setIsActiveOne(true)
    }
    const changeColorThree = () => {
        setClicked(false)
        setIsActive(false)
        setIsActiveThree(false)
        setIsActiveOne(false)
        setIsActiveTwo(true)

    }
    const changeColorFour = () => {
        setClicked(false)
        setIsActive(false)
        setIsActiveOne(false)
        setIsActiveTwo(false)
        setIsActiveThree(true)
    }

    const profileClick = () => {
        setIsProfileModal(true)
        // dispatch(loadProfileDetails())
    }

    const handleCancel = () => {
        setIsProfileModal(false);
        // setSlot_from("")
        // setSlot_to("")
        // setOrder_status("")
        // setNewdc("")
    };


    const photo = {
        profile_pic_file: ''
    }

    const addProfilePic = () => {
    }

    const changeFileHandler = (event) => {
        toast.configure()
        console.log("targettedFiles", event.target.files[0])
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
        console.log("Checking event", event.target.files)
        const fileExtension = event.target.files[0].name.split('.').pop();
        if (fileExtension === 'png' || fileExtension === 'jpg') {
            const formData = new FormData();
            formData.append('profile_pic_file', event.target.files[0], event.target.files[0].name)
            console.log("formData", formData)
            toast.success('Image uploaded Successfully', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
            })
            dispatch(profilePhotoAdded(formData))
            setIsProfileModal(false)
        }
        else {
            toast.error('Please upload png and jpg image only', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
            })
        }
        event.currentTarget.value = null
        // dispatch(loadInvoiceUpload(formData))
    }

    const submitProfileHandler = () => {

    }


    return (
        <div>
            <div className="head">
                <img src={logo} alt="brand logo" />
                <h3>V<span>A</span>H<span>A</span>N</h3>
                <div className="avatar" >
                    {/* <img src={userlogo} className="user-icon" alt="user" /> */}
                    {profile?.avatar === '' ? <img src={userlogo} alt="userlogo" className="user-icon" /> : <img src={profile?.avatar} alt="userlogo" className="user-icon" />}

                    <div className="logout" >
                        <p className="username">{user?.name}</p>
                        <p className="myprofile"><span onClick={profileClick}>My Profile</span> | <span onClick={logOut}>Logout</span></p>
                    </div>
                </div>
            </div>
            <nav className="nav">
                <li>{user?.role === "vendor" ?
                    <>
                        <NavLink to="/orders" style={(isActive) =>
                            isActive
                                ? {
                                    background: " #FCB713",
                                    borderRadius: "15px",
                                    margin: "5px 0px",

                                }
                                : {}
                        }>  <ul><span><img src={headerlogo} alt="headerlogo" /></span>Create Order</ul></NavLink>
                        <NavLink to="/orderlisting" style={(isActive) =>
                            isActive
                                ?
                                {
                                    background: " #FCB713",
                                    borderRadius: "15px",
                                    margin: "5px 0px",

                                }
                                :
                                {

                                }
                        }> <ul > <span><img src={headerlogo} alt="headerlogo" /></span>Order Listing</ul></NavLink>
                        {/* <Link to="/vendor"> <ul>Vendors</ul></Link> */}

                    </>
                    : ""}
                    {user?.role === "admin" ?
                        <>
                            <NavLink to="/dashboard" style={(isActive) =>
                                isActive
                                    ? {
                                        background: " #FCB713",
                                        borderRadius: "15px",
                                        margin: "5px 0px",

                                    }
                                    : {}
                            }> <ul><span><img src={headerlogo} alt="headerlogo" /></span>Dashboard</ul></NavLink>
                            <NavLink to="/orderlisting" style={(isActive) =>
                                isActive
                                    ? {
                                        background: " #FCB713",
                                        borderRadius: "15px",
                                        margin: "5px 0px",

                                    }
                                    : {}
                            }> <ul><span><img src={headerlogo} alt="headerlogo" /></span>Order Listing</ul></NavLink>
                            {/* <NavLink to="/orders" style={(isActive) =>
                                isActive
                                    ? {
                                        background: " #FCB713",
                                        borderRadius: "15px",
                                        margin: "5px 0px",

                                    }
                                    : {}
                            } >  <ul ><span><img src={headerlogo} alt="headerlogo" /></span>Create Order</ul></NavLink> */}
                            <NavLink to="/vendor" style={(isActive) =>
                                isActive
                                    ? {
                                        background: " #FCB713",
                                        borderRadius: "15px",
                                        margin: "5px 0px",

                                    }
                                    : {}
                            }> <ul ><span><img src={headerlogo} alt="headerlogo" /></span>Vendors</ul></NavLink>
                            <NavLink to="/dc" style={(isActive) =>
                                isActive
                                    ? {
                                        background: " #FCB713",
                                        borderRadius: "15px",
                                        margin: "5px 0px",

                                    }
                                    : {}
                            }> <ul><span><img src={headerlogo} alt="headerlogo" /></span>DC Management</ul></NavLink>
                        </> : ""}
                    {user?.role === "dc" ?
                        <>
                            <NavLink to="/dashboard" style={(isActive) =>
                                isActive
                                    ? {
                                        background: " #FCB713",
                                        borderRadius: "15px",
                                        margin: "5px 0px",

                                    }
                                    : {}
                            }  > <ul><span><img src={headerlogo} alt="headerlogo" /></span>Dashboard</ul></NavLink>
                            <NavLink to="/orderlisting" style={(isActive) =>
                                isActive
                                    ? {
                                        background: " #FCB713",
                                        borderRadius: "15px",
                                        margin: "5px 0px",

                                    }
                                    : {}
                            }> <ul><span><img src={headerlogo} alt="headerlogo" /></span>Order Listing</ul></NavLink>
                            {/* <Link to="/orders">  <ul className="backgroundHeader">Orders</ul></Link> */}
                            <NavLink to="/editDc/:id" style={(isActive) =>
                                isActive
                                    ? {
                                        background: " #FCB713",
                                        borderRadius: "15px",
                                        margin: "5px 0px",

                                    }
                                    : {}
                            }> <ul ><span><img src={headerlogo} alt="headerlogo" /></span>DC Management</ul></NavLink>
                        </> : ""}

                </li>
            </nav>


            {/* { profile && profile?.map((profileData) =>
            profileData.map((profile) => 
                <> */}
            {
                isprofileModal &&
                <div className='modal'>
                    <Modal
                        visible={isprofileModal}
                        onCancel={handleCancel}
                        width={700}
                        footer={[
                            // <Button key="submit" type="primary" className='vahan-edit-update' onClick={submitProfileHandler}>
                            //     Save
                            // </Button>,
                        ]}
                    >
                        <div className='adding-vendor-photo'>
                            <h3>My Profile</h3>
                            <div className='profile-photo'>
                                {profile?.avatar === '' ? <img src={userlogo} alt="14913@apollo.com" className="profile-photo-first-img" /> : <img src={profile?.avatar} alt="hggg" className="profile-photo-first-img" />}
                                <img src={add} alt="add" className="profile-photo-second-img" onClick={() => fileInputRef.current.click()} />
                                <input onChange={changeFileHandler} multiple={false} ref={fileInputRef} type='file' hidden />
                            </div>
                        </div>
                        <div className='details-user-div'>
                            <div className="name-number-email-div">
                                <div className='name-div'>
                                    <h6>Name:</h6>
                                    <p>{profile?.name}</p>
                                </div>
                                <div className='name-div'>
                                    <h6>Mobile Number:</h6>
                                    <p>{profile?.phone}</p>
                                    {/* <p>{props.orders.invoice_no}</p> */}
                                </div>
                                <div className='name-div'>
                                    <h6>Email:</h6>
                                    <p>{profile?.email}</p>
                                    {/* <p>{props?.orders?.dc?.dc_name}</p> */}
                                </div>
                            </div>
                            {user?.role === "admin" || user?.role === "vendor" ?
                                <div className="name-number-email-div">
                                    {/* <div className='name-div'>
                                    <h6>Address:</h6>
                                    <p>{profile?.address}</p>
                                </div> */}
                                    <div className='name-div'>
                                        <h6>State:</h6>
                                        <p>{profile?.state}</p>
                                        {/* <p>{props.orders.invoice_no}</p> */}
                                    </div>
                                    <div className='name-div'>
                                        <h6>City</h6>
                                        <p>{profile?.city}</p>
                                        {/* <p>{props?.orders?.dc?.dc_name}</p> */}
                                    </div>
                                </div>
                                :
                                <div className="name-number-email-div">
                                    <div>
                                        <h6 className='address-h6'>Address:</h6>
                                        <p className='address-p'>{profile?.address}</p>
                                        {/* <p>{props.orders.invoice_no}</p> */}
                                    </div>
                                </div>
                            }
                        </div>
                    </Modal>
                </div>
            }

            {/* </>) )} */}

            <ToastContainer />
        </div>
    )
}

export default Header
