import React, { Component } from 'react'
import LoaderGif from '../../images/loader.gif'
import {connect} from 'react-redux'

export class FullPageLoader extends Component {
  render() {
    return (
      <div className='loader-container'>
          <div className='loader'>
              <img src={LoaderGif} alt="loader" />
          </div>

      </div>
    )
  }
}

export default connect()(FullPageLoader);