export const GET_DC="GET_DC";
export const ADD_DC="ADD_DC";
export const GET_VENDOR="GET_VENDOR";
export const ADD_VENDOR="ADD_VENDOR";
export const ADD_VENDOR_LOADING="ADD_VENDOR_LOADING";
export const ADD_DC_LOADING="ADD_DC_LOADING";

export const PDF_VENDOR="PDF_VENDOR";
export const UPDATE_DC="UPDATE_DC";
export const GET_SINGLE_VENDOR='GET_SINGLE_VENDOR'
export const UPDATE_VENDOR="UPDATE_VENDOR";
export const GET_ALL_STATES="GET_ALL_STATES"
export const GET_ALL_CITIES="GET_ALL_CITIES"

export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS"
export const GET_ORDER_DETAILS_LOADING = "GET_ORDER_DETAILS_lOADING"

export const CREATE_ORDER = "CREATE_ORDER";

export const ADD_COMMENTS = "ADD_COMMENTS";

export const GET_ALL_VEHICLES = "GET_ALL_VEHICLES";

export const GET_ALL_LOGISTICS = "GET_ALL_LOGISTICS";

export const GET_SINGLE_DC = "GET_SINGLE_DC";

export const ADD_DC_DATE = "ADD_DC_DATE";
export const ADD_DC_DATE_LOADING = "ADD_DC_DATE_LOADING"

export const GET_VENDOR_BY_LOGIN = "GET_VENDOR_BY_LOGIN";

export const GET_ORDER_VENDOR_ID = "GET_ORDER_VENDOR_ID";
export const GET_ALL_ORDERS="GET_ALL_ORDERS"
export const GET_DASHBOARD_CARDS="GET_DASHBOARD_CARDS";
export const GET_DASHBOARD_VENDOR_ORDER=" GET_DASHBOARD_VENDOR_ORDER"
export const GET_DASHBOARD_DC_ORDER=" GET_DASHBOARD_DC_ORDER"

export const UPDATE_ORDER="UPDATE_ORDER";

export const UPLOAD_VAHAN_UPDATE = "UPLOAD_VAHAN_UPDATE";

export const GET_VAHAN_UPDATE = "GET_VAHAN_UPDATE"

export const GET_DOOR_SLOTS_AVAILABLE = "GET_DOOR_SLOTS_AVAILABLE"

export const GET_DC_SLOTS_AVAILABLE = "GET_DC_SLOTS_AVAILABLE"

export const RESET_SINGLE_DC = "RESET_SINGLE_DC"

export const RESET_DC_DATE = "RESET_DC_DATE"

export const RESET_GET_ORDER_DETAILS = "RESET_GET_ORDER_DETAILS"

export const RESET_ORDER_LISTING = "RESET_ORDER_LISTING"


export const GET_ORDER_STATUS = "GET_ORDER_STATUS"

export const VAHAN_UPLOAD_UPDATE = "VAHAN_UPLOAD_UPDATE"

export const ADD_LOGISTICS = "ADD_LOGISTICS" 

export const GET_VENDOR_STATUS = "GET_VENDOR_STATUS"

export const ADD_INVOICE_UPLOAD = "ADD_INVOICE_UPLOAD"

export const VIEW_INVOICE = "VIEW_INVOICE"


export const CHANGE_DC_INPUTS = "CHANGE_DC_INPUTS"
export const GET_TODAY_ORDERS = "GET_TODAY_ORDERS"

export const ADD_PROFILE_PHOTO = "ADD_PROFILE_PHOTO"

export const GET_PROFILE_DETAILS = "GET_PROFILE_DETAILS"

export const RECOVERY_EMAIL = "RECOVERY_EMAIL"

export const GET_RESET_PASSWORD_LINK = "GET_RESET_PASSWORD_LINK"

export const ADD_NEW_PASSWORD = "ADD_NEW_PASSWORD"



export const EDIT_DRIVER_DETAILS = "EDIT_DRIVER_DETAILS"
export const DELETE_DRIVER = "DELETE_DRIVER"
export const ADD_DRIVER = "ADD_DRIVER"


export const GET_HOLIDAY = "GET_HOLIDAY"
export const ADD_HOLIDAY = "ADD_HOLIDAY"
export const EDIT_HOLIDAY = "EDIT_HOLIDAY"
export const DELETE_HOLIDAY = "DELETE_HOLIDAY"

export const TRACKING_LINK = "TRACKING_LINK"

export const UPDATE_ORDER_NUM = "UPDATE_ORDER_NUM"

export const GET_ORDER_NUMBERS_FOR_PDF = "GET_ORDER_NUMBERS_FOR_PDF"

export const GET_VEHICLE_DETAILS = "GET_VEHICLE_DETAILS"

export const GET_SINGLE_ORDER_DETAILS = "GET_SINGLE_ORDER_DETAILS"

export const GET_NEW_ORDER_DETAILS = "GET_NEW_ORDER_DETAILS"

export const RESET_ORDER_NUMBERS_FOR_PDF = "RESET_ORDER_NUMBERS_FOR_PDF"