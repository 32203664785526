import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button, Modal } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mapIcon from '../../images/address.png';
import delayTruck from '../../images/delay-truck.png';
import Delivered from '../../images/Delivered.svg';
import deliveryDateTime from '../../images/deliveryDateTime.png';
import deliveryTruck from '../../images/deliveryTruck.png';
import Edit from '../../images/Edit.png';
import factory from '../../images/factory.png';
import Logistics from '../../images/logistics.png';
import trackerImage from '../../images/track.svg';
import Vector from '../../images/Vector.png';
import Vector1 from '../../images/Vector1.png';
import { addComments, addTrackingLink, addVahanUpdate, loadDcSlotsAvailable, loadGetDcHoliday, loadVahanUpdate, updateOrder } from '../../redux/Actions';
import Drivers from '../Orders/Drivers';
import './GetOrder.css';
import Steps from './Steps';
import { loadOrderStatus } from '../../redux/Actions';
import { Tooltip } from "antd";
import { getCookie } from '../utils/Cookie';
import getDay from 'react-datepicker';

const editSlotTimeDropdowns = [
    '09:00:00 - 13:00:00',
    '14:00:00 - 18:00:00'
]

function GetOrder(props) {
    const history = useHistory()
    const fileInputRef = useRef();
    let dispatch = useDispatch()
    let user = JSON.parse(getCookie("user-info"))

    const getSingleOrder = useSelector(state => state.data.getSingleOrderDetails)
    console.log("getSingleOrder", getSingleOrder, props)
    const dcm = useSelector(state => state.dcUsers.dc.data)
    const { updated } = useSelector(state => state.data)
    const { upload } = useSelector(state => state.data)
    const { vahanUpdate } = useSelector(state => state.data)
    const slots = useSelector(state => state.data.slots.data)
    const availableSlots = useSelector(state => state.data.availableSlots)
    const singleDc = useSelector(state => state.data.singleDc.DC)
    const deliveryCenterSlots = useSelector(state => state.data.deliveryCenterSlots.data)
    const orderStatus = useSelector(state => state.data.orderStatus)
    console.log("orderStatus", orderStatus)
    const { orderDetails } = useSelector(state => state.data)
    const { vendorStatus } = useSelector(state => state.data)
    const driverAdd = useSelector(state => state.data.driverAdd)
    const driverEdit = useSelector(state => state.data.driverEdit)
    const driverDelete = useSelector(state => state.data.driverDelete)
    const getDCHoliday = useSelector(state => state.dcUsers.getDCHoliday)


    const [days, setDays] = useState()
    const [dateFrom, setDateFrom] = useState()
    const [selectedFile, setSelectedFile] = useState()
    const [isFilePicked, setIsFilePicked] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [comments, setComments] = useState('')
    const [delivery_date, setDelivery_date] = useState(getSingleOrder?.date_of_dispatch)
    const [slot_from, setSlot_from] = useState(moment(getSingleOrder?.dc_doors?.slot_from, ["HH:mm:ss"]).format('HH:mm:ss'))
    const [slot_to, setSlot_to] = useState(moment(getSingleOrder?.dc_doors?.slot_to, ["HH:mm:ss"]).format('HH:mm:ss'))
    const [order_status, setOrder_status] = useState("")
    const [newdc, setNewdc] = useState('')
    const [door_no, setDoor_No] = useState(getSingleOrder?.door_no)
    const [slot_to_arr, setSlot_to_arr] = useState([])
    const [shrink1, setShrink1] = useState(true)
    const [deliveryDisable, setDeliveryDisable] = useState(false)
    const [link, setLink] = useState()
    const [track, setTrack] = useState(false)
    const [countDateTime, setCountDateTime] = useState([])
    const [delay, setDelay] = useState()
    const [delayStatus, setDelayStatus] = useState()
    const [liveToggle, setLiveToggle] = useState(false)
    const [fromTo, setFromTo] = useState('')
    const [fromDropdown, setFromDropDown] = useState('')
    const [domainName, setDomainName] = useState('')
    const [changeDateToggle, setChangeDateToggle] = useState(false)
    const [changeDoorToggle, setChangeDoorToggle] = useState(false)


    useEffect(() => {
        setNewdc(getSingleOrder?.dc?.dc_number)
    }, [newdc])

    const distributed = getSingleOrder?.dc?.dc_number ? getSingleOrder?.dc?.dc_number : ''
    useEffect(() => {
        if (delivery_date) {
            dispatch(loadDcSlotsAvailable(delivery_date, distributed))
        }
    }, [delivery_date])

    useEffect(() => {
        if (updated.length > 0) {
            if (updated.success) {
                return setIsModalVisible(true)
            }
        }
    }, [updated.success])

    useEffect(() => {
        if (updated.status) {
            setIsModalVisible(false)
        }
    }, [updated])

    const selectedDoors = deliveryCenterSlots ? deliveryCenterSlots?.find(item => item.dc_door_no === door_no) : null
    useEffect(() => {
        if (selectedDoors) {
            let arr = []
            const sravan = selectedDoors.slotAvail.map((item, i) => {
                arr.push(`${item.slot_from} - ${item.slot_to}`)
            })
            setFromTo(arr)
        }
    }, [selectedDoors])

    useEffect(() => {
        if (getSingleOrder?.orderStatus?.delivered_at === null) {
            setDeliveryDisable(true)
        }
    }, [getSingleOrder?.orderStatus?.delivered_at])

    useEffect(() => {
        dispatch(loadOrderStatus(getSingleOrder?.order_no))
        dispatch(loadGetDcHoliday(getSingleOrder?.dc?.dc_number))
    }, [getSingleOrder?.order_no])

    useEffect(() => {
        const arr = getSingleOrder?.live_track_info?.eta?.split('')
        const arrObj = arr?.map(item => { return { str: item, isNumber: isNaN(item) } })
        setCountDateTime(arr)
        // { arrObj?.map(item => { item.isNumber ? console.log(item.str) : console.log(item.str) }) }
    }, [getSingleOrder?.live_track_info.eta])

    useEffect(() => {
        const array = getSingleOrder?.live_track_info?.delay_status?.split('')
        const arrayObj = array?.map(item => { return { str: item, isNumber: isNaN(item) } })
        setDelay(arrayObj)
        const arraySplit = getSingleOrder?.live_track_info?.delay_status?.split(' ')
        if (arraySplit) {
            const arraySplitObj = arraySplit[arraySplit.length - 1];
            setDelayStatus(arraySplitObj)
        }
        // { arrObj?.map(item => { item.isNumber ? console.log(item.str) : console.log(item.str) }) }
    }, [getSingleOrder?.live_track_info?.delay_status])

    useEffect(() => {
        if (getDCHoliday) {
            setDateFrom(getDCHoliday?.data?.map(data =>
                data?.dates.map(data1 => moment(data1).format('YYYY-MM-DD'))))
            // setDateFrom(getDCHoliday?.data?.map(data => new Date(data.from_date)))
        }
    }, [getDCHoliday])

    useEffect(() => {
        if (dateFrom) {
            setDays(dateFrom?.flat())
        }
    }, [dateFrom])

    useEffect(() => {
        if (slot_from, slot_to) {
            const from = moment(getSingleOrder?.dc_doors?.slot_from, ['HH:mm:ss']).format('HH:mm:ss')
            const to = moment(getSingleOrder?.dc_doors?.slot_to, ['HH:mm:ss']).format('HH:mm:ss')
            const fromTo12 = `${slot_from} - ${slot_to}`
            setFromDropDown(fromTo12)
        }
    }, [slot_from, slot_to])

    useEffect(() => {
        if (getSingleOrder?.order_tracking_link) {
            const url = getSingleOrder?.order_tracking_link;
            let domain = (new URL(url));
            domain = domain?.hostname;
            setDomainName(domain)
        }
    }, [getSingleOrder?.order_tracking_link])



    const getTime = (str) => {
        let a = str?.split(":")
        let r = ""
        const c = (k) => parseInt(k) < 10 ? "0" + k : k
        if (parseInt(a[0]) == 24 || parseInt(a[0]) == 0) {
            r = "12:" + a[1] + " AM"
        } else if (parseInt(a[0]) == 12) {
            r = "12:" + a[1] + " PM"
        } else if (parseInt(a[0]) > 12) {
            let b = parseInt(a[0]) - 12
            r = c(parseInt(a[0]) - 12) + ":" + a[1] + " PM"
        } else {
            r = a[0] + ":" + a[1] + " AM"
        }
        return r
    }

    const showModal = () => {
        setIsModalVisible(true)
        setChangeDateToggle(false)
        setChangeDoorToggle(false)
        setDelivery_date(getSingleOrder?.date_of_dispatch)
        setDoor_No(getSingleOrder?.dc_doors?.dc_door_no)
        setSlot_from(moment(getSingleOrder?.dc_doors?.slot_from, ["HH:mm:ss"]).format('HH:mm:ss'))
        setSlot_to(moment(getSingleOrder?.dc_doors?.slot_to, ["HH:mm:ss"]).format('HH:mm:ss'))
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSlot_from("")
        setSlot_to("")
        setOrder_status("")
        setNewdc("")
    };

    const handleChangeComment = (e) => {
        setComments(e.target.value)
    }

    const handleDispatch = (dateString) => {
        const formatDate = moment(dateString).format("YYYY-MM-DD")
        setDelivery_date(formatDate)
        setChangeDateToggle(true)
        setDoor_No("")
        setSlot_from("")
        setSlot_to("")
        setFromDropDown("")
    }

    const handleSlotFrom = (e) => {
        setSlot_from(e.target.value)
        let arr = []
        const index = selectedDoors?.slot_splits?.map(i => i.sf).indexOf(e.target.value);
        const arr_length = selectedDoors?.slot_splits.length
        let initial = parseInt(e.target.value.split(':')[0])
        arr.push(selectedDoors?.slot_splits[index])
        for (let i = index + 1; i < arr_length; i++) {
            let sf = parseInt(selectedDoors?.slot_splits[i]?.sf.split(':')[0])
            if (sf == initial + 1) {
                arr.push(selectedDoors?.slot_splits[i])
                initial++
            } else {
                break;
            }
        }
        setSlot_to_arr(arr)
    }

    const handleSlotTo = (e) => {
        setSlot_to(e.target.value)
    }
    const handleOrder = (e) => {
        setOrder_status(e.target.value)
    }
    const handleDoor = (e) => {
        setDoor_No(e.target.value)
        setChangeDoorToggle(true)
        setSlot_from("")
        setSlot_to("")
        setFromDropDown("")
    }

    const data = {
        delivery_date,
        order_status,
        newdc,
        door_no,
        slot_from,
        slot_to,
    }
    const updateHandler = (id) => {
        toast.configure()
        if (!door_no) {
            toast.error("Please Enter All Input Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        dispatch(updateOrder(data, id, getSingleOrder?.dc?.dc_number, getSingleOrder?.date_of_dispatch, getSingleOrder?.door_no))
        setOrder_status("")
        setNewdc("")
    }

    const changeFileHandler = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
        const formData = new FormData();
        formData.append('file', event.target.files[0], event.target.files[0].name)
        dispatch(addVahanUpdate(formData))
    }

    const handleupload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile, selectedFile.name)
        dispatch(addVahanUpdate(formData))
        if (upload?.data?.length > 0) {
            upload?.data?.map((data) => (
                <>
                    {getSingleOrder.order_no === data[0] ?
                        dispatch(loadVahanUpdate(data[0]))
                        : null}
                </>
            ))
        }
    }

    let vahaanStops = vahanUpdate?.data?.map((vahan, index) => {
        if (vahan.actual_arrival && vahan.stop_no === index + 1) {
            return {
                ...vahan,
                isArrived: true,
                nextStop: true
            }
        }
        else {
            return {
                ...vahan,
                isArrived: false,
                nextStop: false
            }
        }
    })

    const ExampleCustomTimeInput = ({ date, value, onChange }) => (
        <input
            value={value}
            onChange={(e) => onChange(e.target.value)}
            style={{ border: "solid 1px pink" }}
        />
    )

    const clearFilter = () => {
        setOrder_status('')
    }

    const orderComment = {
        order_no: getSingleOrder?.order_no,
        user_message: comments,
    }

    const handleCommentSubmit = () => {
        toast.configure()
        if (!comments) {
            toast.warning('Please add comments', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else {
            dispatch(addComments(orderComment, getSingleOrder?.dc?.dc_number, getSingleOrder?.date_of_dispatch, getSingleOrder?.door_no))
            setComments("")
        }
    }

    const tracking = {
        link: link
    }

    const handleTrackingLinkChange = (e) => {
        setLink(e.target.value)
    }

    const addTrackingOnClick = (e) => {
        toast.configure()
        if (!link) {
            toast.error("Please enter tracking link.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
        else {
            dispatch(addTrackingLink(getSingleOrder?.order_no, tracking, getSingleOrder?.dc?.dc_number, getSingleOrder?.date_of_dispatch, getSingleOrder?.door_no))
            setLink("")
            toast.success("Link added successfully.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
            });
        }
    }

    const trackLiveLocation = () => {
        setTrack(true)
    }

    const [couterser, setCouterser] = useState("")
    const c = new Date(getSingleOrder?.live_track_info.eta).getTime()
    var countDownDate = new Date(`${getSingleOrder?.date_of_dispatch} ${getSingleOrder?.slot_from}`).getTime();


    const renderCountDown = () => {
        var now = new Date().getTime();
        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // Output the result in an element with id="demo"
        setCouterser(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
    }


    // useEffect(() => {
    //     {renderCountDown(getSingleOrder.date_of_dispatch, getSingleOrder.slot_from)}
    // },[])



    // useEffect(
    //     () => {

    //         // console.log("setCouterser111", couterser); 
    //         let id = setInterval(renderCountDown, 1000)
    //         if (couterser == "6d 20h 18m 1s") {
    //             // console.log("setCouterser111222", "comes"); 
    //             return () => clearInterval(id);
    //         }

    //     },
    //     [couterser]);

    // }, [])



    // const msToTime = (msDuration) => {
    //     const h = Math.floor(msDuration / 1000 / 60 / 60);
    //     const m = Math.floor((msDuration / 1000 / 60 / 60 - h) * 60);
    //     const s = Math.floor(((msDuration / 1000 / 60 / 60 - h) * 60 - m) * 60);

    //     // To get time format 00:00:00
    //     const seconds = s < 10 ? `0${s}` : `${s}`;
    //     const minutes = m < 10 ? `0${m}` : `${m}`;
    //     const hours = h < 10 ? `0${h}` : `${h}`;

    //     if (hours == "00" && minutes == "00" && seconds == "00") {
    //         return "On Time"
    //     }

    //     const hr = parseInt(hours) % 24
    //     const day = Math.floor(parseInt(hours) / 24)
    //     let daycal = day > 1 ? day + " days " : day > 0 ? day + " day " : ""
    //     let hh = daycal + "" + (hr > 1 ? hr + " hours " : hr > 0 ? hr + " hour " : "")
    //     let mm = parseInt(minutes) > 1 ? minutes + " minutes " : parseInt(minutes) > 0 ? minutes + " minute " : ""
    //     let ss = parseInt(seconds) > 1 ? seconds + " seconds" : parseInt(seconds) > 0 ? seconds + " second" : ""
    //     return hh + '' + mm;

    // }

    // const getDateTimeDifference = (delivery_date, slot_from, dispatch_date_time) => {
    //     console.log("jhsdbfvjhbsjfhbsdl", delivery_date, slot_from, dispatch_date_time)
    //     const delivery_date_time = new Date(delivery_date + ' ' + slot_from);
    //     console.log("checkingjjjj", moment(delivery_date_time).format(""))
    //     dispatch_date_time = new Date(dispatch_date_time);
    //     console.log("checkingssssss", dispatch_date_time)
    //     let timer = delivery_date_time - dispatch_date_time
    //     console.log("jhsfkhsd", msToTime(timer).trim())
    //     if (dispatch_date_time > delivery_date_time) {
    //         setDateGiven1('00:00:00')
    //         toast.warning('Date Of dispatch should not be post date of delivery', {
    //             position: "top-right",
    //             autoClose: 2000,
    //             hideProgressBar: true,
    //         });
    //     }
    //     else {
    //         return setDateGiven1(msToTime(timer).trim())
    //     }
    //     // return msToTime(timer).trim();
    // };


    const trackLiveHandler = () => {
        setLiveToggle(true)
    }

    function add3Dots(string, limit) {
        var dots = "...";
        var limit = 18
        if (string ? string.length > limit : "") {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }
        return string;
    }

    const handleSlotFromTo = (e) => {
        setFromDropDown(e.target.value)
        setSlot_from(e.target.value.split(' -')[0])
        setSlot_to(e.target.value.split('- ')[1])
    }

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0;
    };

    const vend_email = <span>{getSingleOrder?.vendor?.email}</span>
    var val = getSingleOrder?.order_no;
    const invoiceView = `${process.env.REACT_APP_BASEURL}/api/vahanOrder/downloadInvoice/${getSingleOrder?.order_no}`

    const remaining = getSingleOrder?.dc_doors?.slot_to?.split(':')[0] - getSingleOrder?.dc_doors?.slot_from?.split(':')[0]
    return (
        <div>
            <section className='container container-border'>
                <div>
                    <div className='order-details'>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-sm-10 order-detail-mg-top">
                                    <div className='order-no col-sm-2'>
                                        <h6>Order Number</h6>
                                        <p>{getSingleOrder?.order_no}</p>
                                    </div>
                                    <div className='order-no col-sm-2'>
                                        <h6>PO Number</h6>
                                        <p>{getSingleOrder?.po_number}</p>
                                    </div>
                                    <div className='order-no col-sm-2'>
                                        <h6>DC Selected</h6>
                                        <p>{getSingleOrder?.dc?.dc_name}</p>
                                    </div>
                                    <div className='order-no col-sm-2'>
                                        <h6>Number of Items</h6>
                                        <p>{getSingleOrder?.number_of_items}</p>
                                    </div>
                                    <div className='order-no col-sm-3'>
                                        <h6>Number of boxes</h6>
                                        <p>{getSingleOrder?.number_of_boxes}</p>
                                    </div>
                                </div>
                                <div className="col-md-2 edit">
                                    {deliveryDisable === true ?
                                        <div className='editOrder' >
                                            <img src={Edit} alt="edit image" onClick={showModal} />
                                            <p onClick={showModal}>Edit Slot</p>
                                        </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                        <div className='order-details'>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8 orderNo-fields">
                                        <div className='order-no col-sm-2'>
                                            <h6>Less than truckload</h6>
                                            <p>{getSingleOrder?.truckload === "Less than truckload" ? 'yes' : 'no'}</p>
                                        </div>
                                        <div className='order-no col-sm-2'>
                                            <h6>Full truckload</h6>
                                            <p>{getSingleOrder?.truckload === "Full truck load" ? 'yes' : 'no'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='vendor-details-main-div'>
                        <div >
                            <div className="row">
                                <div className='vendor-details-in-get-order'>
                                    <h6>Vendor Details:</h6>
                                </div>
                                <div className="col-md-12 orderNo-fields vendor-detail-mg-top">
                                    <div className='order-no col-sm-2'>
                                        <h6>Name</h6>
                                        <p>{getSingleOrder?.vendor?.name}</p>
                                    </div>
                                    <div className='order-no col-sm-2'>
                                        <h6>Email ID</h6>
                                        <Tooltip placement="top" title={vend_email}> <p>{add3Dots(getSingleOrder?.vendor?.email)}</p></Tooltip>
                                    </div>
                                    <div className='order-no col-sm-2'>
                                        <h6>Phone Number</h6>
                                        <p>{getSingleOrder?.vendor?.phone}</p>
                                    </div>
                                    <div className='order-no  col-sm-2'>
                                        <h6>Place of Dispatch</h6>
                                        <p>{getSingleOrder?.dc?.dc_name}</p>
                                    </div>
                                    <div className='order-no col-sm-2'>
                                        <h6>Date of Dispatch</h6>
                                        <p>{moment(getSingleOrder?.date_given).format('YYYY-MM-DD')}</p>
                                    </div>
                                    <div className='order-no view-invoice-div  col-sm-2'>
                                        {getSingleOrder?.invoice_path === null ? null :
                                            <a href={invoiceView} target="_blank"><button className="vahan-download-invoice">VIEW INVOICE</button></a>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="vehicle-details">
                        <div className='truck-details-div'>
                            <div className='col-md-12 row'>
                                <div className='track-vehicle col-md-4'>
                                    <div className='truck-alignment'>
                                        <img src={deliveryTruck} alt="deliveryTruck" className='deliveryTruck' />
                                    </div>
                                    <div className="vehicle-input truck-type">
                                        <p className='vehicle-details-p'>Vehicle Details:</p>
                                        <p className='vehicle-type'>{getSingleOrder?.vehicle.type}</p>
                                        <p className='bold'>{getSingleOrder?.vehicle_no}</p>
                                    </div>
                                </div>
                                <div className='track-vehicle col-md-4'>
                                    <div className='truck-alignment truck-marginBottom '>
                                        <img src={Logistics} alt="logistics" className='deliveryTruck1' />
                                    </div>
                                    <div className="vehicle-input truck-type">
                                        <p className='vehicle-details-p'>Logistic Details:</p>
                                        <p className='vehicle-type'>{getSingleOrder?.logistics_detail?.logistics_name}</p>
                                        <p className='vehicle-type'>{getSingleOrder?.logistics_detail?.log_email}</p>
                                        <p className="bold">{getSingleOrder?.logistics_detail?.log_phone}</p>
                                    </div>
                                </div>
                                <div className='track-vehicle col-md-4'>
                                    <div className='truck-alignment truck-marginBottom '>
                                        <img src={factory} alt="logistics" className='deliveryTruck1' />
                                    </div>
                                    <div className="vehicle-input truck-type">
                                        <p className='vehicle-details-p'>Factory Address:</p>
                                        <p className='vehicle-type factory-address-p'>{getSingleOrder?.factory_address}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12' style={{ display: 'flex' }}>
                                <div className='track-vehicle col-md-4'>
                                    <div className='truck-alignment truck-marginBottom '>
                                        <img src={deliveryDateTime} alt="deliveryDateTime" className='deliveryTruck2' />
                                    </div>
                                    <div className="vehicle-input truck-type">
                                        <p className='vehicle-details-p'>Dispatch Details:</p>
                                        <span className='date-orders'>{moment(getSingleOrder?.date_given).format("Do").slice(0, -2)}</span><span>{moment(getSingleOrder?.date_given).format("Do").slice(-2)}</span> <span className='month'>{moment(getSingleOrder?.date_given).format("MMM YYYY")}</span>
                                        <p className="date_given_time">{moment(getSingleOrder?.date_given).format("hh:mm A")}</p>
                                    </div>
                                </div>
                                <div className='track-vehicle col-md-4'>
                                    <div className='truck-alignment truck-marginBottom '>
                                        {delayStatus === 'delay' || countDateTime.includes('-') ?
                                            <img src={delayTruck} alt="Delivered" className='deliveryTruck2' />
                                            :
                                            <img src={Delivered} alt="Delivered" className='deliveryTruck2' />}
                                    </div>
                                    <div className="vehicle-input truck-type">
                                        <p className='vehicle-details-p'>Transit Time:</p>
                                        {/* {getSingleOrder.live_track_info.live_eta_update ? couterser : <p><span className='date-orders'>{getSingleOrder?.live_track_info.eta?.slice(0, 2)}</span><span>{getSingleOrder?.live_track_info.eta?.slice(2, 6)}</span><span className='date-orders'>{getSingleOrder?.eta?.slice(6, 9)}</span><span>{getSingleOrder?.live_track_info.eta?.slice(9, 13)}</span><span className='date-orders'>{getSingleOrder?.live_track_info.eta?.slice(13, 15)}</span><span>{getSingleOrder?.live_track_info.eta?.slice(16, 19)}</span></p>} */}
                                        {/* <p>{couterser}</p> */}
                                        {/* <span className='date-orders'>{props?.dateTimer?.slice(0, 2)}</span><span>{props?.dateTimer?.slice(2, 6)}</span><span className='date-orders'>{props?.dateTimer?.slice(6, 8)}</span><span>{props?.dateTimer?.slice(9, 12)}</span><span className='date-orders'>{props?.dateTimer?.slice(13, 15)}</span><span>{props?.dateTimer?.slice(16, 19)}</span> */}
                                        {getSingleOrder?.live_track_info.delivered ? <div><p className='order-delivered-eta'>Order Delivered</p><p className={delayStatus === 'delay' ? 'red-color' : delayStatus === 'early' ? 'orange-color' : delayStatus === 'On Time' ? 'green-color' : ''}>{delay?.map(item => (
                                            item?.isNumber ? <span>{item?.str}</span> : <span>{item?.str}</span>)
                                        )}</p></div> :

                                            <>
                                                {/* {(countDateTime?.map(item => (
                                            item.isNumber ? <span>{item.str === '-' ? <span className='minus-colors'>{item.str}</span> : item.str}</span> : <span className={item.str === '-' ? 'minus-colors':'date-orders'}>{item.str}</span>)
                                        ))} */}
                                                {countDateTime.includes('-') ? <span className='minus-colors'>{getSingleOrder?.live_track_info?.eta}</span> : <span className='date-orders'>{getSingleOrder?.live_track_info?.eta}</span>}
                                            </>}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='drivers-container'>
                            <Drivers driver={getSingleOrder?.driverInfo} orderNo={getSingleOrder?.order_no} door={getSingleOrder?.door_no} date={getSingleOrder?.date_of_dispatch} dcNumber={getSingleOrder?.dc_doors.dc_number} />
                        </div>
                    </div>
                    <section>
                        <div className='receiver'>
                            <h5 className='fonts'>Receiver</h5>
                            <div className='vendor-details-main-div'>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="orderNo-fields row">
                                            <div className='order-no col-md-2'>
                                                <h6>Receiver Name</h6>
                                                <p>{getSingleOrder?.receiverInfo[0].name}</p>
                                            </div>
                                            <div className='order-no col-md-2'>
                                                <h6>Dc Name</h6>
                                                <p>{getSingleOrder?.dc.dc_name}</p>
                                            </div>
                                            <div className='order-no col-md-2'>
                                                <h6>Door Number</h6>
                                                <p>{getSingleOrder?.door_no}</p>
                                            </div>
                                            <div className='order-no col-md-2'>
                                                <h6>Actual date of receiving</h6>
                                                <p>{getSingleOrder?.date_of_dispatch_actual}</p>
                                            </div>
                                            {getSingleOrder?.date_of_dispatch_actual != getSingleOrder?.date_of_dispatch ?
                                                <div className='order-no col-md-2'>
                                                    <h6>Update date of receiving</h6>
                                                    <p>{getSingleOrder?.date_of_dispatch}</p>
                                                </div>
                                                : ''}
                                            <div className='order-no col-md-2'>
                                                <h6>Contact Number</h6>
                                                <p>{singleDc?.contact_number}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-2 address-get-order-div'>
                                            <div>
                                                <h6>Address</h6>
                                                <p>{singleDc?.address}</p>
                                            </div>
                                            <a href={singleDc?.map_link} target="_blank"><img src={mapIcon} alt="mapIcon" /></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className='container'>
                <div className='shipment-details-main-container'>
                    <p className="ship-dts-head">Shipment Details</p>
                    <div className='shipment'>
                        <div className="">
                            <div className='shipFrom'>
                                <img src={Vector} alt="vector1" />
                                <p className='ships'>Ship From</p>
                            </div>
                            <div>
                                <p className='vendor-address-shipment mb-0'>{getSingleOrder?.factory_address}</p>
                            </div>
                        </div>
                        <div className="">
                            <div className='shipFrom'>
                                <img src={Vector1} alt="vector1" />
                                <p className='ships'>Ship To</p>
                            </div>
                            <div>
                                <p className='vendor-address-shipment mb-0'>{singleDc?.address}</p>
                            </div>
                        </div>
                        <div className="">
                            <p className='ships'>Pick Up and Delivery</p>
                            <div className='shipFrom mb-2'>
                                <img src={Vector} alt="vector1" />
                                <p className="mb-0">{moment(getSingleOrder?.date_given).format("Do MMM YYYY")} {moment(getSingleOrder?.date_given).format("hh:mm A")}</p>
                            </div>
                            <div className='shipFrom mb-2'>
                                <img src={Vector1} alt="vector" />
                                <p className="mb-0"> {moment(getSingleOrder?.date_of_dispatch).format("Do MMM YYYY")} {moment(getSingleOrder?.slot_from, "HH:mm").format("hh:mm A")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='container'>
                <div className='tracking-details-main-container'>
                    <p className="ship-dts-head">Tracking Details</p>
                    <div className='shipping'>
                        <Steps />
                    </div>
                </div>
            </section>

            <div className='container'>
                <div>
                    {/* {orderDetails?.data?.map(item => item.order_no === getSingleOrder?.order_no ?
                            item.order_tracking_link === null ? */}
                    {/* <div>
                            <p className='tracking-heading'>Tracking</p>
                            <div className='input-btn-container'>
                                <input type="text" placeholder='Enter track link' value={link} onChange={handleTrackingLinkChange} />
                                <button onClick={addTrackingOnClick}>></button>
                            </div>
                        </div>
                        : */}
                    <div className='track-live-location-div'>
                        <img src={trackerImage} alt="track" />
                        {domainName === "www.google.com" ?
                            <a href={getSingleOrder?.order_tracking_link} target="_blank">Track Live Location</a> :
                            <p key={getSingleOrder?.order_tracking_link} onClick={trackLiveHandler}>Track Live Location</p>
                        }
                    </div>
                </div>
            </div>

            <div className='track-live-location-iframe'>
                {orderDetails?.data?.map(item => item?.order_no === getSingleOrder?.order_no && liveToggle ?
                    <iframe src={item?.order_tracking_link} target="_blank" className={item?.order_tracking_link ? "truck-tracking-map" : 'iframes'}></iframe>
                    : '')}
            </div>


            {
                isModalVisible &&
                <div className='modal'>
                    <Modal
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        width={1100}
                        footer={[
                            <Button key="submit" type="primary" onClick={() => updateHandler(getSingleOrder?.order_no)} className='vahan-edit-update'>
                                Update
                            </Button>,
                        ]}
                    >
                        <div className='editing'>
                            <div className='mode'>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className='edit-order-no col-md-3'>
                                            <h6>Order Number:</h6>
                                            <p>{getSingleOrder?.order_no}</p>
                                        </div>
                                        <div className='edit-order-no col-md-3'>
                                            <h6>PO Number:</h6>
                                            <p>{getSingleOrder?.po_number}</p>
                                        </div>
                                        <div className='edit-order-no col-md-3'>
                                            <h6>Berth Allocated</h6>
                                            <p>{getSingleOrder?.door_no}</p>
                                        </div>
                                        <div className='edit-order-no col-md-3'>
                                            <h6>Date of Dispatch</h6>
                                            <p>{moment(getSingleOrder?.date_given).format('YYYY-MM-DD')}</p>
                                        </div>
                                        <div className='edit-order-no col-md-3'>
                                            <h6>Delivery Date</h6>
                                            <p>{moment(getSingleOrder?.date_of_dispatch).format('YYYY-MM-DD')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className='mode1'>
                                <div className='mode1-details'>
                                    <h6>Dc Selected</h6>
                                    <p>{getSingleOrder?.dc?.dc_name}</p>
                                </div>
                                <div className='mode1-details'>
                                    <h6>Logistics</h6>
                                    <p>{getSingleOrder?.logistics}</p>
                                </div>
                                <div className='mode1-details'>
                                    <h6>Vendor Details</h6>
                                    <p>{getSingleOrder?.vendor?.name}</p>
                                </div>
                                <div className='mode1-details'>
                                    <h6>Vehicle Type</h6>
                                    <p>{getSingleOrder?.vehicle?.wheeler}</p>
                                </div>
                                <div className='mode1-details'>
                                    <h6>Selected Slots</h6>
                                    <p><span>{getTime(getSingleOrder?.dc_doors?.slot_from)}</span> -
                                        <span>{getTime(getSingleOrder?.dc_doors?.slot_to)}</span></p>
                                </div>
                            </div>
                            <br />
                            <hr />
                            <div className='mode2'>
                                <h5>Modify Order</h5>
                                <div className='modify-order'>
                                    <div className='edit-dropdowns col-md-12'>
                                        <div className='modify-order-datepicker col-md-3'>
                                            <p>Date</p>
                                            <DatePicker
                                                placeholderText="Date of Delivery"
                                                value={delivery_date}
                                                minDate={moment().toDate()}
                                                dateFormat="yyyy-MM-dd"
                                                onChange={dates => handleDispatch(dates)}
                                                excludeDates={delivery_date ? days?.map((index) => new Date([index])) : ''}
                                                className='selectDate dateVendors'
                                                filterDate={isWeekday}
                                            />
                                        </div>
                                        <div className='col-md-3 edit-modify-order-inputs'>
                                            <FormControl variant="standard" sx={{ ml: 5, minWidth: 200 }}>
                                                <InputLabel id="demo-simple-select-standard-label">Change Door</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={door_no}
                                                    // name="order_status"
                                                    onChange={handleDoor}
                                                >
                                                    {deliveryCenterSlots && deliveryCenterSlots.length && deliveryCenterSlots?.map((doors) => (
                                                        <MenuItem value={doors?.dc_door_no}>{doors?.dc_door_no}</MenuItem>
                                                    ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-md-3 edit-modify-order-inputs'>
                                            <FormControl variant="standard" sx={{ ml: 5, minWidth: 200 }}>
                                                <InputLabel id="demo-simple-select-standard-label">Select time slot</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={fromDropdown}
                                                    // name="order_status"
                                                    onChange={handleSlotFromTo}
                                                >
                                                    {changeDateToggle || changeDoorToggle ?
                                                        fromTo?.map((doors) => (
                                                            <MenuItem value={doors}>{doors}</MenuItem>
                                                        )) :
                                                        editSlotTimeDropdowns?.map(item => (
                                                            <MenuItem value={item}>{item}</MenuItem>
                                                        ))
                                                    }

                                                </Select>
                                            </FormControl>
                                        </div>
                                        {/* <FormControl variant="standard" sx={{ width: 200 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Select Slot From</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={slot_from}
                                                // name="order_status"
                                                onChange={handleSlotFrom}
                                            >
                                                {selectedDoors?.slot_splits.map((slots) => (
                                                    <MenuItem value={slots.sf}>{getTime(slots.sf)}</MenuItem>
                                                ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <FormControl variant="standard" sx={{ width: 200 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Select Slot To</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={slot_to}
                                                // name="order_status"
                                                onChange={handleSlotTo}
                                            >
                                                {slot_to_arr.map((slots) => (
                                                    <MenuItem value={slots.st}>{getTime(slots.st)}</MenuItem>
                                                ))
                                                }
                                            </Select>
                                        </FormControl> */}
                                        <div className='col-md-3 edit-modify-order-inputs'>
                                            <FormControl variant="standard" sx={{ minWidth: 200 }}>
                                                <InputLabel id="demo-simple-select-standard-label">Update Order Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={order_status}
                                                    // name="order_status"
                                                    onChange={handleOrder}
                                                    label=""
                                                >
                                                    <MenuItem value={orderStatus?.vahan_status?.status_value}>{orderStatus?.vahan_status?.status_value}</MenuItem>
                                                </Select>
                                                {order_status ? <p onClick={clearFilter} className="filter-status">Clear Status</p> : ''}
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='updatedMessage'>
                                <p>{updated?.message}</p>
                            </div>
                        </div>
                    </Modal>
                </div>
            }
            <ToastContainer />

        </div >

    )
}

export default GetOrder
